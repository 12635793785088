//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/service/auth.service.ts.p.vm
//

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Usuario } from '../entities/usuario/usuario';
import { Funcionalidad } from '../entities/funcionalidad/funcionalidad';
import { Menu } from '../entities/menu/menu';
import { TokenStorageService } from './token-storage.service';
import { Router } from '@angular/router';
import { MsgService } from './message.service';
import { Contrato } from 'app/entities/contrato/contrato';

@Injectable()
export class AuthService {
    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private tokenStorageService:TokenStorageService, private router : Router,private messageService : MsgService) {}

    globalUser:Usuario;
    globalFuncionalidades:Funcionalidad[];
    globalMenuActual: any = null;
    globalIdioma;
    globalColumns : any = null;
    globalContrato : Contrato;

    // async isAuthenticated() {
    //   try {
    //     let tokenFromLogin = localStorage.getItem('token');     
    //     let resp = await this.http.get<Usuario>('/api/authenticated').toPromise();
    //     if(resp != null){
    //       this.globalUser = new Usuario(resp);
    //       //await this.setGlobalColumnsfromDB();
    //       this.setGlobalColumnsfromDB();
    //       return true;  
    //     }       
    //     return false;
    //   } catch (error) {
    //     return false;
    //   }      
    // }
    
    async isAuthenticated() {
      try {
        let tokenFromLogin = localStorage.getItem('token');  
        let body = JSON.stringify({token: tokenFromLogin});   
        let resp = await this.http.post<any>('/api/authenticated',body, this.options).toPromise();        
        if(resp != null){
          this.globalUser = new Usuario(resp.usuario);
          this.globalMenuActual = JSON.parse(resp.menu)
          //await this.setGlobalColumnsfromDB();
          this.setGlobalColumnsfromDB();
          return true;  
        }       
        this.logout();
        return false;
      } catch (error) {
        this.logout();
        return false;
      }      
    }
    

    async loginUser(userLogin){
      let body = JSON.stringify(userLogin);
      try{
        let resp = await this.http.post<any>('/api/login', body, this.options).toPromise();        
        if(resp != null){
          this.tokenStorageService.saveToken(resp.token);        
          this.globalUser = new Usuario(resp.usuario); 
          this.globalMenuActual = JSON.parse(resp.menu)
          //await this.setGlobalColumnsfromDB();
          this.setGlobalColumnsfromDB();

          
          return true;
        }
        this.logout();
        return false;
      }catch(error){
        console.log(error);
        this.logout();
        return false;
      };     
    }

    logout(){      
      this.globalUser = null;
      this.globalFuncionalidades = null;
      this.globalMenuActual = null;      
      this.globalColumns = null;
      this.globalContrato = null;
      //this.tokenStorageService.signOut();
      //this.router.navigate(["/"]);
    }

    getIsAdmin(){
      let indAdmin = this.globalUser.roles.findIndex(r=> r.id == 2 || r.id == 1);
      if(indAdmin != -1){
        return true;
      }
      return false;
    }

    getMenu() {
      return this.http.get<any>("/api/menus/get-menu-by-rol/").toPromise();
    }

    getMenuContratoFinalizado() {
      return this.http.get<any>("/api/menus/get-menu-contrato-finalizado/").toPromise();
    }

    getColumns(idMenu) {
      return this.http.get<[]>("/api/session-column-list/"+idMenu).toPromise();
    }

    getColumnsByRuta(menuRuta) {
      return this.http.get<[]>("/api/session-column-list/column/"+menuRuta).toPromise();
    }

    async setGlobalColumnsfromDB(){
      this.globalColumns = await this.http.get<any>("/api/session-column-list/all/columns").toPromise();
    }

    saveColumns(columns, idMenu){
      let body = JSON.stringify(columns);
      this.http.post("/api/session-column-list/save/"+idMenu, body, this.options).toPromise();
    }

    saveColumnsByRuta(columns,menuRuta){
      let body = JSON.stringify(columns);
      this.http.post("/api/session-column-list/save/column/"+menuRuta, body, this.options).toPromise();
    }

    async getFuncionalidades() {
      this.globalFuncionalidades = Funcionalidad.toArray(await this.http.get<Funcionalidad[]>('/api/funcionalidads/porUsuario/'+this.globalUser.id).toPromise());
      return this.globalFuncionalidades;
    }

    getDiccionario(code){
      return this.http.get<any>("/api/diccionario/"+code).toPromise();
    }

    async getPermisosPorEntidad(keyEntidad: string) {
      let body = JSON.stringify(keyEntidad);
      let permisos =  await this.http.post("/api/rolePermisos/permisosPorEntidad", keyEntidad, this.options).toPromise();
      if(permisos == null){
        this.messageService.warn('Ruta Inexistente','');
        this.router.navigate(["/"]);                
        return;
      }
      return permisos;
    }

    savePermisosPorEntidad(permisos){
      let body = JSON.stringify(permisos);
      return this.http.post<any>("/api/rolePermisos/save/permisosPorEntidad", body, this.options).toPromise();
    }  

    validaLlaveRoleEntidad(permisos){
      let body = JSON.stringify(permisos);
      return this.http.post<any>("/api/rolePermisos/valida/permisosPorEntidad", body, this.options).toPromise();
    }   

    getCodeResetPwd(username, lang){
      let formData = new FormData();
      formData.append('username',new Blob([username],{type: "application/json"}));
      formData.append('lang',new Blob([lang],{type: "application/json"}));
      return this.http.post<any>("/api/getcode", formData).toPromise();
    }
    checkCodePwd(userResetPwd){
      let formData = new FormData();
      formData.append('userResetPwd',new Blob([JSON.stringify(userResetPwd)],{type: "application/json"}));
      return this.http.post<any>("/api/validatecode", formData).toPromise();
    }
    resetPwd(userResetPwd){
      let formData = new FormData();
      formData.append('userResetPwd',new Blob([JSON.stringify(userResetPwd)],{type: "application/json"}));
      return this.http.post<any>("/api/resetpwd", formData).toPromise();
    }

    // sample method from angular doc
    private handleError (error: any) {
        let errMsg = (error.message) ? error.message :
        error.status ? `Status: ${error.status} - Text: ${error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        return null;//Observable.throw(errMsg);
    }
    
}
