//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { Requerimiento } from './requerimiento';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class RequerimientoService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a Requerimiento by id.
     */
    getRequerimiento(id : any) {
        return this.http.get<Requerimiento>('/api/requerimientos/' + id).toPromise();
    }



    /**
     * Update the passed requerimiento.
     */
    update(requerimiento : Requerimiento) {
        let body = JSON.stringify(requerimiento);
        return this.http.post<Requerimiento>('/api/requerimientos/save', body, this.options).toPromise();
    }

    /**
     * Notificacion cambio de etapa.
     */
    enviarCorreoNotificacionEtapa(requerimiento : Requerimiento, accionEtapaId, coordinadorOriginal?) {
      let body = JSON.stringify(requerimiento);
      let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('accionEtapaId',new Blob([JSON.stringify(accionEtapaId)],{type: "application/json"}));
      if(coordinadorOriginal){
        formData.append('coordinadorOriginal',new Blob([JSON.stringify(coordinadorOriginal)],{type: "application/json"}));
      }
      return this.http.post<any>('/api/requerimientos/correo-notificacion-etapa', formData).toPromise();
    }

    /**
     * Load a page (for paginated datatable) of Requerimiento using the passed
     * requerimiento as an example for the search by example facility.
     */
     getPage(requerimiento : Requerimiento, event : LazyLoadEvent, columns, viewId, filterRangeDate?, filterRangeNumber?) {
        let req = new PageRequestByExample(requerimiento, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        formData.append('viewId',new Blob([JSON.stringify(viewId)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        if(filterRangeDate != undefined){          
          formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
        }
        return this.http.post('/api/requerimientos/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by RequerimientoListComponent.
      */
    getCSV(requerimiento:Requerimiento, viewId, filterRangeDate?, filterRangeNumber?) {
      let body = JSON.stringify(requerimiento);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      formData.append('viewId',new Blob([JSON.stringify(viewId)],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      if(filterRangeDate != undefined){        
        formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
      }
      return this.http.post('/api/requerimientos/csv', formData).toPromise();
    }

    getCSVDownloadInfoReq(requerimiento:Requerimiento, filterRangeDate) {
      let body = JSON.stringify(requerimiento);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeDate != undefined){        
        formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
      }
      return this.http.post('/api/requerimientos/csv-download-info-req', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by RequerimientoCompleteComponent.
     */
    complete(requerimiento : Requerimiento) {
        if(requerimiento == null){
          requerimiento = new Requerimiento();
        }
        let body = JSON.stringify(requerimiento);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<Requerimiento[]>('/api/requerimientos/complete', formData).toPromise();
    }
    completeCalendario(requerimiento : Requerimiento, rangeDate) {
        if(requerimiento == null){
          requerimiento = new Requerimiento();
        }
        let body = JSON.stringify(requerimiento);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"})); 
        formData.append('rangeDate',new Blob([JSON.stringify(rangeDate)],{type: "application/json"}));                
        return this.http.post<Requerimiento[]>('/api/requerimientos/complete-calendario', formData).toPromise();
    }

    async getRequerimientosPorVencer(requerimiento){
      let body = JSON.stringify(requerimiento);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
      return this.http.post<any>("/api/requerimientos/req-por-vencer", formData).toPromise();
    }

    copyReqEPOC(requerimiento : Requerimiento, reqIdEP){
      if(requerimiento == null){
        requerimiento = new Requerimiento();
      }
      let body = JSON.stringify(requerimiento);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      formData.append('reqIdEP',new Blob([JSON.stringify(reqIdEP)],{type: "application/json"}));               
      return this.http.post<Requerimiento>('/api/requerimientos/copy_ep_oc', formData).toPromise();      
    }

    /**
     * Delete an Requerimiento by id.
     */
    delete(id : any) {
        return this.http.delete('/api/requerimientos/' + id).toPromise();
    }
}
