//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/custom/contrasena.component.ts.p.vm
//
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from '../../service/auth.service';
import { MsgService } from '../../service/message.service';
import { TranslateService } from '@ngx-translate/core';
import { Usuario } from '../../entities/usuario/usuario';
import { UsuarioService } from '../../entities/usuario/usuario.service';

@Component({
	templateUrl: 'contrasena.component.html',
	selector: 'contrasena'
})
export class ContrasenaComponent implements OnInit {
  usuario : Usuario;
  passwd:any = {"actual": null, "nueva": null, "confirmacion": null};
  esSegura: boolean = false;
  minCaracter: number = 8;
  pwdStrength: number = 66;
  msg: any = null;
  isValidoConfirm:boolean = false;

  @ViewChild('contrasenaForm', {static: false}) contrasenaForm:any;

  constructor(private route: ActivatedRoute, private router: Router, private translate: TranslateService, private messageService: MsgService, private usuarioService: UsuarioService, private authService: AuthService, private confirmationService: ConfirmationService) {
  }

  async ngOnInit() {
    if(this.authService.globalUser != undefined && this.authService.globalUser != null){
      let id = this.authService.globalUser.id;
      try{
        this.usuario = await this.usuarioService.getUsuario(id);
      }catch(error){
        this.messageService.customError(this.translate.instant('tltCambioContrasena')+":",this.translate.instant('lblProblemaObtenerUsuario'), error)
      }
    }else{
      this.router.navigate(['/']);
    }
  }

  cambiarPasswd(event: any) {
    if(this.contrasenaForm.form.valid){

      this.confirmationService.confirm({
        target: event.target,
        message: this.translate.instant("lblPreguntaUpdate"),
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          this.usuario.passwd = this.passwd.nueva;
          try{
            let msj : any = await this.usuarioService.cambiarPasswd(this.usuario, this.passwd.actual);
            if(msj.severity === "success"){
              this.messageService.success('',this.translate.instant(msj.detail));
              //this.router.navigate(['/contrato-list']);
              this.volver();
            }else{
              this.messageService.warn('',this.translate.instant(msj.detail));
              this.clean();
            }
          }catch(error){
            this.messageService.customError(this.translate.instant('lblProblemaCambiarContrasena'),this.translate.instant('lblFavorValideDatosIngresados'), error);
            this.clean();
          }
        },
        reject: () => {
           //reject action
        }
      });
    }
  }

  validaSeguridad(pwd: any){
    if(pwd.meter != null){
      let medida = parseFloat(pwd.meter.width);
      if (medida >= this.pwdStrength) {
        this.esSegura = true;
      }else{
        this.esSegura = false;
      }
      this.validaConfirmacion();
    }
  }

  validaConfirmacion(){
    this.msg= null;
    if(String(this.passwd?.confirmacion).length == 0){
      this.msg= this.translate.instant('lblContrasenaRequerida');
    }
    if(this.passwd.nueva !== this.passwd.confirmacion){
      this.msg= ((this.passwd.confirmacion == '')? (this.msg + ' / ') : '' ) + this.translate.instant('lblContrasenaDiferentes');
    }
    this.isValidoConfirm = (this.msg == null)? true : false; 
  }

  clean(){
    this.passwd = {"actual": null, "nueva": "", "confirmacion": null};  
    this.esSegura = false; 
    this.isValidoConfirm = false;
  }

  volver() {
    window.history.back();
  }
}