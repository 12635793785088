//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/app.component.ts.p.vm
//
import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ToastModule } from 'primeng/toast';
import { AuthService } from './service/auth.service';
import { MsgService } from './service/message.service';
import { MenuService } from './entities/menu/menu.service';
import { ConfigService } from './entities/config/config.service';
import { DownloadProgressComponent } from './support/download-progress.component';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { HomeComponent } from './home.component';
import { KpiComponent } from './custom/kpis/kpis.component';
import { ColumnListComponent } from './custom/columnList/columnList.component';
import { CalendarioEventosComponent } from './custom/calendario/calendario-eventos.component';
import { TokenStorageService } from './service/token-storage.service';
import { environment } from './../environments/environment';
import { Contrato } from './entities/contrato/contrato';
import { number } from '@amcharts/amcharts4/core';
import { isNumeric } from 'rxjs/internal-compatibility';
import { UsuarioService } from './entities/usuario/usuario.service';
import { Usuario } from './entities/usuario/usuario';
import { ContratoService } from './entities/contrato/contrato.service';
import { ContratoTipoService } from './entities/contratoTipo/contratoTipo.service';
import { ContratoTipo } from './entities/contratoTipo/contratoTipo';
import { Requerimiento } from './entities/requerimiento/requerimiento';
import { RequerimientoService } from './entities/requerimiento/requerimiento.service';
import { DatePipe } from '@angular/common';
import { Notificacion } from './entities/notificacion/notificacion';
import { NotificacionService } from './entities/notificacion/notificacion.service';

/**
 * The Root component.
 * Defines the main layout and handles user login in a dialog.
 */
 @Component({     
     selector: 'app-root',
     templateUrl: 'app.component.html',
     styles:[]
 })
export class AppComponent implements OnInit {
    @ViewChild("down", {static: false}) downloadProgress:DownloadProgressComponent;
    public items = [];
    urlLogo = 'url('+environment.apiUrl+'/api/configs/1/download/logoBinary)';
    favIcon: HTMLLinkElement = this.elementRef.nativeElement.ownerDocument.querySelector('#appIcon');
    bodyBg: HTMLLinkElement = this.elementRef.nativeElement.ownerDocument.querySelector('#bodyBg');    
    timeOut: any;
    authenticated : boolean = false;
    esCelular:boolean = false;
    enableSkt:boolean = true;
    enableSktHome = true;
    config:any;
    linkInicio = ['/'];
    fechaFooter:Date = new Date();
    busqueda:string;
    displayMenuCelular;
    suggestions:[];
    showLoginDialog:boolean = true;
    loadingLogin = false;
    usuarioValido:boolean = null;
    haveCode:boolean = false;
    esSegura: boolean = false;
    minCaracter: number = 8;
    pwdStrength: number = 66;
    userResetPwd = {j_id: "", j_username:"", j_code:"", j_password:"", j_password_confirm:""};
    errorMsgLogin = '';
    contratoKey = 'key_contrato_sesion';
    isAdminorPsur: boolean = false;
    esCambioContratoMenu:boolean = false;
    isViewCalendario:boolean = false;
    finishLoadContratoMenu: boolean = false;
    optionsContratoUsuario : [];
    public userLogin = {j_username:null,j_password:null};
    finishLoadIdioma = false;
    public idioma = {name:"Español", code:"es"};
    listadoIdiomas = [
      {name:"Español", code:"es"},
      {name:"English", code:"en"},
    ];
    tmpActiveComponent;
    pageIndex = 0;
    formsLogin =[
      {id:1, titulo:"tltLogin", label:this.translate.instant('tltLogin')},      
      {id:2, titulo:"tltObtenerCodigo", label:this.translate.instant('tltObtenerCodigo')},
      {id:3, titulo:"tltRecuperarPwd", label:this.translate.instant('tltRecuperarPwd')},
      {id:4, titulo:"tltContrato", label:this.translate.instant('tltContrato')}
    ]
    showDialogDownloadInfo = false;
    contratoDownload = new Contrato();
    filtroMesAnio = new Date();
    idDownloadInfo = 0; //1 = req - 2 = not
    filterRangeDate:any={};
    entidad;
    constructor(private notificacionService:NotificacionService, private requerimientoService:RequerimientoService,private datePipe: DatePipe, private route: ActivatedRoute, public tokenStorageService:TokenStorageService, public authService: AuthService, private primeNGConfig: PrimeNGConfig, private translate: TranslateService, private router : Router, private messageService: MsgService, private menuService: MenuService, private configService: ConfigService, private elementRef: ElementRef, private confirmationService: ConfirmationService, private usuarioService: UsuarioService, private contratoService: ContratoService) {
    }
    closeDialogDownload(){
      this.showDialogDownloadInfo=false;
      this.contratoDownload = new Contrato();
    }
    async downloadCSV(){
      if(this.contratoDownload.id == null){
        return;
      }
      try {        
        let csv : any;        
        this.setFilterDate();
        if(this.idDownloadInfo == 1){          
          let example = new Requerimiento();
          example.contrato = this.contratoDownload;        
          csv = await this.requerimientoService.getCSVDownloadInfoReq(example, this.filterRangeDate); //3 = req cerrados
        }else if(this.idDownloadInfo == 2){          
          let example = new Notificacion();
          example.contrato = this.contratoDownload;        
          csv = await this.notificacionService.getCSV(example, this.filterRangeDate);                               
        }         
        var csvData = new Blob(["\ufeff"+csv], {type: 'text/csv;charset=utf-8;'});
        var csvURL = window.URL.createObjectURL(csvData);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'Informacion_'+this.entidad+'_' + this.datePipe.transform(new Date(),'ddMMyyyy_HHmm') + '.csv');
        tempLink.click();
      } catch (error) {
          this.messageService.customError(this.translate.instant("Requerimiento"),this.translate.instant("lblErrorCSV"), error)
      }
    }
    setFilterDate(){
      let primerDia = new Date(this.filtroMesAnio.getFullYear(), this.filtroMesAnio.getMonth(), 1);
      let ultimoDia = new Date(this.filtroMesAnio.getFullYear(), this.filtroMesAnio.getMonth() + 1, 0);
      if(this.idDownloadInfo == 1){
        this.filterRangeDate = {'fecha_ingreso':[primerDia , ultimoDia]};       
      }else if(this.idDownloadInfo == 2){
        this.filterRangeDate = {'notificacion.fecha_registro':[primerDia , ultimoDia]};       
      }
      
    }
    cambiarContrato(){
      this.authService.globalContrato = null;
      localStorage.removeItem(this.contratoKey);
      this.pageIndex = 3;
      this.showLoginDialog = true;
    }
    async setGlobalContrato(){
      let contratos: any;
      //this.authService.globalContrato = await this.contratoService.getContrato(this.authService.globalContrato.id);
      //contratos = await this.contratoService.complete(null);
      //this.authService.globalContrato = await contratos.filter(item => this.authService.globalContrato.id==item.id).shift();
      localStorage.setItem(this.contratoKey, JSON.stringify(this.authService.globalContrato));      
      //await this.setContratoMenu();
      this.setContratoMenu();
      this.showLoginDialog = false;
      //this.router.navigate(["/"]);
      //this.messageService.success(this.translate.instant("lblBienvenido"),this.translate.instant("lblActualizaTusDatos"));
    }
    async setContratoMenu(){
      if(this.authService.globalContrato){        
        if(this.authService.globalContrato.finalizado){
          let menuContrato = await this.authService.getMenuContratoFinalizado();
          this.items = [];          
          await this.setMenu(menuContrato);          
          this.linkInicio = ['/requerimiento-list/requerimientos-cerrados'];
          this.router.navigate(this.linkInicio);
          this.items[0].routerLink = this.linkInicio;          
        }else{
          this.linkInicio = ['/requerimiento-list/mis-pendientes'];
          this.router.navigate(this.linkInicio);
          this.items[0].routerLink = this.linkInicio;
        }        
        this.finishLoadContratoMenu = true;
      }      
      this.desactivaSktYDialog();
    }
    async login(){ 
      this.errorMsgLogin = null;
      this.usuarioValido = null;   
      this.loadingLogin = true;       
      if(this.tokenStorageService.getToken() != null){
        this.usuarioValido = await this.authService.isAuthenticated();
      }else if(this.userLogin.j_username && this.userLogin.j_password){
        this.usuarioValido = await this.authService.loginUser(this.userLogin);
        this.errorMsgLogin = 'lblCredencialesIncorrectas';
      }

      if(!this.usuarioValido){
        this.pageIndex = 0;
        this.showLoginDialog = true;  
        this.tokenStorageService.signOut(); 
        this.loadingLogin = false;    
        return;
      }else{
        this.errorMsgLogin = null;
      }
      this.cleanLoginVar();
      this.isAdminorPsur = this.authService.getIsAdmin();  
      this.authService.getFuncionalidades();        
      await this.cargaComponentesBase();  
      let existeContrato = this.validaExistenciaContratoLogin();                     
      if(!this.isAdminorPsur){        
        if(this.router.url.includes("/direct/requerimiento")){          
          // redirecciona automaticamente al requerimiento  
          this.finishLoadContratoMenu = true;          
          this.desactivaSktYDialog();         
        }else{          
          if(!existeContrato){
            //this.authService.globalContrato.contratoTipo = new ContratoTipo();
            let cantContrato = this.authService.globalUser.contratos.length;                     
            if(cantContrato>1){              
              this.pageIndex = 3;
              this.showLoginDialog = true;                               
            }else if(cantContrato==1){
              this.authService.globalContrato = this.authService.globalUser.contratos[0];
              await this.setGlobalContrato();
            }else{
              this.messageService.warn(this.translate.instant('lblSeleccionarContrato'), this.translate.instant('username')+' '+this.translate.instant('lblSinContratoAsociado'),"");
            }
                        
          }else{       
            await this.setContratoMenu();
          }
          //console.log(this.authService.globalUser.contratos);
        }
        
      }else{
        this.linkInicio = ['/contrato-list'];
        this.router.navigate(this.linkInicio); 
        this.items[0].routerLink = this.linkInicio;
        this.authService.globalContrato=null;   
        this.finishLoadContratoMenu = true;
        this.desactivaSktYDialog();            
      }
      this.loadingLogin = false;           
    }

    desactivaSktYDialog(){
      this.enableSkt = false;  
      this.showLoginDialog = false;
    }
    validaExistenciaContratoLogin(){
      if(!this.authService.globalContrato){
        let tmpContrato = localStorage.getItem(this.contratoKey);
        if(tmpContrato){
          this.authService.globalContrato = new Contrato(JSON.parse(tmpContrato))
          return true;
        }
        return false;
      }else{
        return true;
      }
      
    }
    cleanLoginVar(){
      this.userLogin = {j_username:null,j_password:null};
    }

    logout(msg?){      
      this.authService.logout();
      this.usuarioValido = null; 
      this.isAdminorPsur = false;
      this.pageIndex = 0;     
      if(msg){
        this.errorMsgLogin = msg;
      }else{
        this.errorMsgLogin = '';
      }      
      this.router.navigate(["/"]);
      this.showLoginDialog = true;            
      this.tokenStorageService.signOut();                  
    }

    async cambiarIdioma() {           
      let diccionario = await this.authService.getDiccionario(this.idioma.code); 
      this.translate.setTranslation(this.idioma.code, diccionario); 
      this.translate.use(this.idioma.code);                         
      let res = await this.translate.get('primeng').toPromise();
      this.primeNGConfig.setTranslation(res)
      this.authService.globalIdioma = this.idioma;
      // if(this.usuarioValido){
      // await this.setMenu();
      // if(this.tmpActiveComponent instanceof CalendarioEventosComponent){
      //     this.tmpActiveComponent.ngOnInit();          
      //   }else if(this.tmpActiveComponent instanceof KpiComponent){
      //     this.tmpActiveComponent.setGrafico();
      //   }else if(this.tmpActiveComponent instanceof ColumnListComponent){
      //     this.tmpActiveComponent.ngOnInit();
      //   }
      // }
      this.finishLoadIdioma = true;
    }

    async onActivate(componentRef){
      this.tmpActiveComponent = componentRef; 
      if(this.tokenStorageService.getToken() == null){
        this.logout('lblSesionExpirada');
      }
      if(!this.tmpActiveComponent.sub){
        window.scroll(0,0);          
      }
      if(await this.router.url.includes('calendario-eventos')){
        this.isViewCalendario = true;
      }else{
        this.isViewCalendario = false; 
      }           
    }

    viewCalendar(){
      if(this.isViewCalendario){
        this.router.navigate(['/requerimiento-list/mis-pendientes']);
        this.items[0].routerLink = ['/requerimiento-list/mis-pendientes'];
      }else{
        this.router.navigate(['/calendario-eventos']);
      }
    }

    async ngOnInit() {  
      this.favIcon.href = environment.apiUrl + '/api/configs/1/download/faviconBinary';
      this.showLoginDialog = false;
      this.usuarioValido = null;
      this.finishLoadIdioma = false;
      this.items = [];
      this.esCelular = this.esMovil();
      await this.cambiarIdioma();      
      this.traerConfiguracionCSS(); 
      this.cleanLoginVar();  
      this.login();
      //falta cargar el menu en el direct      
    }

    async cargaComponentesBase(){      
      if(this.usuarioValido){
        this.enableSkt = true;
        if(window.location.pathname === '/'){
          this.enableSktHome = true;
        }else{
          this.enableSktHome = false;
        }
        //this.esCelular = this.esMovil();
        //this.traerConfiguracionCSS();  
        //this.translate.setDefaultLang(this.idioma.code);
        try{          
          await this.setMenu();
          if(this.tmpActiveComponent instanceof CalendarioEventosComponent){
            this.tmpActiveComponent.ngOnInit();
          }else if(this.tmpActiveComponent instanceof KpiComponent){
            this.tmpActiveComponent.setGrafico();
          }
          this.messageService.globalDownProgress = this.downloadProgress;
          //this.enableSkt = false;
          //this.showLoginDialog = false;
          this.messageService.success(this.translate.instant("lblBienvenido"),this.translate.instant("lblActualizaTusDatos"));          
        }catch(error){
          console.log("🚀 ~ AppComponent ~ cargaComponentesBase ~ error:", error)
          this.messageService.customError("Problema al iniciar Sesión:","", error);
        }
      }else{
        this.showLoginDialog = true;
      }
    }


    async setMenu(menuContrato?){            
      let aux = menuContrato ? menuContrato : this.authService.globalMenuActual;//await this.authService.getMenu();  
      if(!this.isAdminorPsur){
        let cantContrato = this.authService.globalUser.contratos.length;
        if(cantContrato==1){
          aux = aux.filter(a => {
            return a.label !== 'tltSeleccionContrato';
          });
        }
      }
      aux.map( m => {
        if(m['url']==null || m['url']==''){
          delete m['url'];
          delete m['target']
        }else{
          delete m['routerLink']
        }
        m.label = this.translate.instant(m.label);
        if(m['items']){
          m.items.map(i=> {
            i.label = this.translate.instant(i.label);
            i.command = (event) => {if(this.esCelular){this.displayMenuCelular=false;}};
            if(i['url']==null || i['url']==''){
              delete i['url'];
              delete i['target']
            }else{
              delete i['routerLink']
            }
            if(i['id']=='key_desc_req_menu'){              
              delete i['url'];
              delete i['target']
              delete i['routerLink']
              i.command = (event) => {
                if(this.esCelular){this.displayMenuCelular=false;} 
                this.entidad = 'Requerimientos Cerrados';
                this.showDialogDownloadInfo = true;
                this.idDownloadInfo = 1;
                }
            }else if(i['id']=='key_desc_not_menu'){                            
              delete i['url'];
              delete i['target']
              delete i['routerLink']
              i.command = (event) => {
                if(this.esCelular){this.displayMenuCelular=false;}                
                this.entidad = 'Notificaciones';
                this.showDialogDownloadInfo = true;
                this.idDownloadInfo = 2;
                }
              }

              if(i['id']=='ing_notif'){
                i.command = (event) => {
                    this.router.navigateByUrl('/ingreso/notificacion/new', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['/notificacion/new']);
                  });
                }
              } 

          })
        }
        if(m['icon']=='fa fa-filter'){
          m.command = (event) => {if(this.esCelular){this.displayMenuCelular=false;}
                                  this.esCambioContratoMenu = true;
                                  this.cambiarContrato();
                                }
        }else{
          m.command = (event) => {if(this.esCelular){this.displayMenuCelular=false;}}
        }
        if(m['id']=='ing_req'){
          m.command = (event) => {
              this.router.navigateByUrl('/ingreso/requerimiento/new', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/requerimiento/new']);
            });
          }
        }   
            
      });
      this.items = aux;
      //console.log("🚀 ~ AppComponent ~ setMenu ~ this.items:", this.items)
    }


//metodos para el restablecimiento de la contraseña
    async getCode(){
      if(this.userResetPwd.j_username){
          let resp = await this.authService.getCodeResetPwd(this.userResetPwd.j_username, this.authService.globalIdioma.code);
          if(resp.result == 'lblCodigoEnviado'){
            this.userResetPwd.j_id = String(resp.id);
            this.haveCode = true;
            this.messageService.success(this.translate.instant('tltRestablecerContrasena'), this.translate.instant(resp.result));
          }else{
            this.messageService.customError(this.translate.instant('lblProblemaCambiarContrasena'),this.translate.instant(resp.result), "");//'lblFavorValideDatosIngresados'),"");
          }
      }else{
        this.messageService.warn(this.translate.instant('tltRestablecerContrasena'), this.translate.instant('username')+' '+this.translate.instant('lblRequerido'),"");
      }
    }

    async validCode(){
      let resp = await this.authService.checkCodePwd(this.userResetPwd);
      if(resp.isOk){
        this.pageIndex = 2;
      }else if(resp.codeExpired){
        this.messageService.warn(this.translate.instant('tltRestablecerContrasena'), this.translate.instant('lblCodigoExpirado'),"");
        this.haveCode = false;
        this.esSegura = false;
        this.userResetPwd = {j_id: "", j_username:"", j_code:"", j_password:"", j_password_confirm:""};
      }else{
        this.messageService.customError(this.translate.instant('lblProblemaCambiarContrasena'),this.translate.instant('lblFavorValideDatosIngresados'),"");
      }

    }

    async resetPwd(event){
      
      if(this.userResetPwd.j_password == this.userResetPwd.j_password_confirm){

        this.confirmationService.confirm({
          target: event.target,
          message: this.translate.instant("lblPreguntaUpdatePwd"),
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {
            try{
              let resp = await this.authService.resetPwd(this.userResetPwd);
              if(resp.isOk){
                this.returnLogin();
                this.messageService.success(this.translate.instant('tltRestablecerContrasena'), this.translate.instant('lblContrasenaActualizada'));
              }else{
                this.messageService.customError(this.translate.instant('lblProblemaCambiarContrasena'),this.translate.instant('lblFavorValideDatosIngresados'),"");
              }
            }catch(error){
              this.messageService.customError(this.translate.instant('lblProblemaCambiarContrasena'),this.translate.instant('lblFavorValideDatosIngresados'), error);

            }
          },
          reject: () => {
             //reject action
          }
        });
      }else{
        this.messageService.warn(this.translate.instant('tltRestablecerContrasena'), this.translate.instant('lblContrasenaDiferentes'),'');
      }
    }

    returnLogin(){
      this.userResetPwd = {j_id: "", j_username:"", j_code:"", j_password:"", j_password_confirm:""};
      this.pageIndex = 0; 
      this.haveCode = false;
      this.esSegura = false; 
    }

    validaSeguridad(pwd: any){
      if(pwd.meter != null){
        let medida = parseFloat(pwd.meter.width);
        if (medida >= this.pwdStrength) {
          this.esSegura = true;
        }else{
          this.esSegura = false;
        }
      }
    }
    // FIN - metodos para el restablecimiento de la contraseña


    async traerConfiguracionCSS() {
      try{
        this.favIcon.href = environment.apiUrl + '/api/configs/1/download/faviconBinary';
        this.config = await this.configService.getConfig(1);
        document.documentElement.style.setProperty('--color-principal', this.config.cssColor1);
        document.documentElement.style.setProperty('--color-segundario', this.config.cssColor2);
        document.documentElement.style.setProperty('--color-terciario', this.config.cssColor3);
        document.documentElement.style.setProperty('--color-cuarto', this.config.cssColor4);
        document.documentElement.style.setProperty('--color-quinto', this.config.cssColor5);
        this.bodyBg.style.background = this.config.cssColor1;
      }catch(error){
        this.messageService.customError("Configuración:","Problema al cargar el CSS", error);
      }
    }

    search(event){
      let result:any = [];
      for(let lbl of this.items){
        if(lbl.label.toLowerCase().includes(event.query.toLowerCase())){
            result.push(lbl);
        }
        if(lbl.items != undefined && lbl.items.length > 0){
          for(let subLbl of lbl.items){
            if(subLbl.label.toLowerCase().includes(event.query.toLowerCase())){
              result.push(subLbl);
            }
          }
        }
      }
      this.suggestions = result;
    }

    // redirect(event){
    //   sessionStorage.removeItem("filterSession");
    //   this.authService.globalMenuActual = event;
    //   this.router.navigate(event.routerLink);
    //   this.busqueda = null;
    // }

    esMovil() {
      if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
      ) {
        return true;
      } else {
        return false;
      }
    }
}


