//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/custom/columnList.component.ts.p.vm
//
//
// Project home: https://github.com/jaxio/celerio-angular-quickstart
// 
// Source code generated by Celerio, an Open Source code generator by Jaxio.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Source code: https://github.com/jaxio/celerio/
// Follow us on twitter: @jaxiosoft
// This header can be customized in Celerio conf...
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { SesionColumnList } from '../../entities/sesionColumnList/sesionColumnList';
import { SesionColumnListService } from '../../entities/sesionColumnList/sesionColumnList.service';
import { TranslateService } from '@ngx-translate/core';

import { Usuario } from '../../entities/usuario/usuario';
import { Menu } from '../../entities/menu/menu';

@Component({
	templateUrl: 'columnList.component.html',
	selector: 'ColumnList',
})
export class ColumnListComponent implements OnInit{

    @ViewChild('columnListForm', {static: false}) columnListForm:any;
    @ViewChild('column', {static: false}) column:any;
    
    sesionColumnList : SesionColumnList;
    private _menu : Menu;

    private params_subscription: any;
    guardo: boolean = false;

    @Input() sub : boolean = false;

    @Input() // used to pass the parent when creating a new SesionColumnList
    set menu(menu : Menu) {
      if(this.sesionColumnList == undefined){
        this.sesionColumnList = new SesionColumnList();
      }
      this.sesionColumnList.menu = menu;
    }

    @Input() // used to pass the parent when creating a new Paso
    set selectSesionColumnList(sesionColumnList : SesionColumnList) {
      if(this.sub){
        this.sesionColumnList = sesionColumnList;
      }
    }

    @Output() onSaveClicked = new EventEmitter<SesionColumnList>();
    @Output() onCancelClicked = new EventEmitter();

    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private sesionColumnListService: SesionColumnListService) {
    }

    menuWithColumnList:any;
    selectedMenu:any;
    columnList:any;
    selectedColumn:any[]=[];
    sesionColumnListEdit:SesionColumnList[] = [];
    existSesionColumn:boolean = false;

    async ngOnInit() {
      try{
        if(this.column){
          this.column.filterValue = null;
        }
        let value:any = await this.sesionColumnListService.getMenuWithSesionColumnList();
        value.map(val => {
          val.tlt_variable = this.translate.instant(val.tlt_variable)
        })
        //let replace = eval(JSON.stringify(value).replace(new RegExp('"tlt_variable":"','gi'),'"tlt_variable":this.translate.instant("').replace(new RegExp('"}', 'gi'),'")}'));
        this.menuWithColumnList = value;
        let index:any=-1;
        for(let menuColEdit of this.sesionColumnListEdit) {
          index = await this.menuWithColumnList.findIndex( menu => menu.id_column === menuColEdit.id );
          this.menuWithColumnList[index].menu_edit = true;
        }

        this.showColumn();
      }catch(error){
        this.messageService.customError(this.translate.instant("SesionColumnList"),this.translate.instant("lblErrorNgOnInit"), error);
      } 
      
    }

    async showColumn(){
      if(this.selectedMenu?.id_column){
        let index = await this.sesionColumnListEdit.findIndex( sessionColumn => sessionColumn.id === this.selectedMenu?.id_column );
        let replace:any = null;
        if(index != -1){
          let aux = JSON.parse(this.sesionColumnListEdit[index].columnas)
          aux.map(col =>{
            col.field = this.translate.instant(col.field);
          })
          this.columnList = aux
        }else{
          this.sesionColumnList = await this.sesionColumnListService.getSesionColumnList(this.selectedMenu.id_column);
          let aux = JSON.parse(this.sesionColumnList.columnas)
          aux.map(col =>{
            col.field = this.translate.instant(col.field);
          })
          this.columnList = aux
        }
      }
    }
    
    async columnEdited(col, columChk){
      if(columChk==1){
        col.delete = false;
      }else if(columChk==2){
        col.default = false;
        col.delete = true;
      }
      let indexCol = this.columnList.findIndex( column => column.id === col.id );
      let columnListTMP=JSON.parse(this.sesionColumnList.columnas);
      columnListTMP[indexCol].default = col.default;
      columnListTMP[indexCol].delete = col.delete;
      
      this.sesionColumnList.columnas = JSON.stringify(columnListTMP);
      let index = this.sesionColumnListEdit.findIndex( sessionColumn => sessionColumn.id === this.selectedMenu?.id_column );

      if(index != -1){
        this.sesionColumnListEdit[index].columnas = this.sesionColumnList.columnas;
      }else{
        this.sesionColumnListEdit.push(this.sesionColumnList);
      }
      
      let indexList: any = this.menuWithColumnList.findIndex( menuEdit => menuEdit.id_column === this.selectedMenu?.id_column);
      this.menuWithColumnList[indexList].menu_edit = true;
      
      this.existSesionColumn = true;
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'sesionColumnListKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
     if(this.columnListForm.form.valid && this.existSesionColumn){
        this.messageService.clear();
        try{

          await this.sesionColumnListService.saveSesionColumnList(this.sesionColumnListEdit);
          let value:any = await this.sesionColumnListService.getMenuWithSesionColumnList();

          await this.authService.setGlobalColumnsfromDB();

          this.guardo = true;
          this.selectedMenu = '';
          this.columnList = '';
          this.sesionColumnListEdit = [];
          this.sesionColumnList = new SesionColumnList();
          this.existSesionColumn = false;

          this.messageService.success(this.translate.instant("SesionColumnList"), this.translate.instant("lblGuardo"));
        }catch(error){
          this.messageService.customError(this.translate.instant("SesionColumnList"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("SesionColumnList"),this.translate.instant("lblNoHayCambios"), true);   //lblNoHayCambios  Pendiente agregar
      }
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
