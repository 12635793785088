//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { RequerimientoNuevoRegistro } from './requerimientoNuevoRegistro';
import { RequerimientoNuevoRegistroService } from './requerimientoNuevoRegistro.service';
import { TranslateService } from '@ngx-translate/core';
import { Etapa } from '../etapa/etapa';
import { Requerimiento } from '../requerimiento/requerimiento';

@Component({
	templateUrl: 'requerimientoNuevoRegistro-detail.component.html',
	selector: 'requerimientoNuevoRegistro-detail',
})
export class RequerimientoNuevoRegistroDetailComponent implements OnInit{

    @ViewChild('requerimientoNuevoRegistroForm', {static: false}) requerimientoNuevoRegistroForm:any;
    requerimientoNuevoRegistro : RequerimientoNuevoRegistro;

    private params_subscription: any;
    guardo: boolean = false;
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_requerimientoNuevoRegistro';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new RequerimientoNuevoRegistro
    set etapa(etapa : Etapa) {
      if(this.requerimientoNuevoRegistro == undefined){
        this.requerimientoNuevoRegistro = new RequerimientoNuevoRegistro();
      }
      this.requerimientoNuevoRegistro.etapa = etapa;
    }

    @Input() // used to pass the parent when creating a new RequerimientoNuevoRegistro
    set requerimiento(requerimiento : Requerimiento) {
      if(this.requerimientoNuevoRegistro == undefined){
        this.requerimientoNuevoRegistro = new RequerimientoNuevoRegistro();
      }
      this.requerimientoNuevoRegistro.requerimiento = requerimiento;
    }

    @Input() // used to pass the parent when creating a new RequerimientoNuevoRegistro
    set selectRequerimientoNuevoRegistro(requerimientoNuevoRegistro : RequerimientoNuevoRegistro) {
      if(this.sub){
        this.requerimientoNuevoRegistro = requerimientoNuevoRegistro;
      }
    }

    @Output() onSaveClicked = new EventEmitter<RequerimientoNuevoRegistro>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'nuevosRegistros': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'etapa': [
        { type: 'required', message: 'lblRequerido' },
      ],
      'requerimiento': [
        { type: 'required', message: 'lblRequerido' },
      ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private requerimientoNuevoRegistroService: RequerimientoNuevoRegistroService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.requerimientoNuevoRegistro == undefined && this.permisos.crear){
            this.requerimientoNuevoRegistro = new RequerimientoNuevoRegistro();
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.requerimientoNuevoRegistro = new RequerimientoNuevoRegistro();
            this.editContent = this.permisos.crear;
          } else {
            this.requerimientoNuevoRegistro = new RequerimientoNuevoRegistro(await this.requerimientoNuevoRegistroService.getRequerimientoNuevoRegistro(id));
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("RequerimientoNuevoRegistro"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'requerimientoNuevoRegistroKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.requerimientoNuevoRegistroForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.requerimientoNuevoRegistroForm.form.valid){
        this.messageService.clear();
        try{
          this.requerimientoNuevoRegistro = new RequerimientoNuevoRegistro(await this.requerimientoNuevoRegistroService.update(this.requerimientoNuevoRegistro));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("RequerimientoNuevoRegistro"), this.translate.instant("lblGuardo"));
            return this.requerimientoNuevoRegistro;
          }else{
            this.onSaveClicked.emit(this.requerimientoNuevoRegistro);
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("RequerimientoNuevoRegistro"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
