//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { Role } from './role';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class RoleService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a Role by id.
     */
    getRole(id : any) {
        return this.http.get<Role>('/api/roles/' + id).toPromise();
    }

    /**
     * Update the passed role.
     */
    update(role : Role) {
        let body = JSON.stringify(role);
        return this.http.post<Role>('/api/roles/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of Role using the passed
     * role as an example for the search by example facility.
     */
     getPage(role : Role, event : LazyLoadEvent, columns) {
        let req = new PageRequestByExample(role, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        return this.http.post('/api/roles/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by RoleListComponent.
      */
    getCSV(role:Role) {
      let body = JSON.stringify(role);
      return this.http.post<any>('/api/roles/csv', body, this.options).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by RoleCompleteComponent.
     */
    complete(role : Role) {
        if(role == null){
          role = new Role();
        }
        let body = JSON.stringify(role);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<Role[]>('/api/roles/complete', formData).toPromise();
    }

    /**
     * Delete an Role by id.
     */
    delete(id : any) {
        return this.http.delete('/api/roles/' + id).toPromise();
    }
}
