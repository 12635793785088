//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/custom/calendario-eventos.component.ts.p.vm
//
import {Component, OnInit, Input, Output, ViewChild, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Calendario } from '../../entities/calendario/calendario';
import { CalendarioService } from '../../entities/calendario/calendario.service';
import { Usuario } from '../../entities/usuario/usuario';
import  dayGridPlugin  from '@fullcalendar/daygrid';
import  timeGridPlugin  from '@fullcalendar/timegrid';
import  interactionPlugin  from '@fullcalendar/interaction';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/service/auth.service';
import { MsgService } from 'app/service/message.service';
import { RolePermisoEntidad } from '../../entities/rolePermisoEntidad/rolePermisoEntidad';
import { Requerimiento } from 'app/entities/requerimiento/requerimiento';
import { RequerimientoService } from 'app/entities/requerimiento/requerimiento.service';
import { CalendarOptions } from '@fullcalendar/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { FeriadoService } from 'app/entities/feriado/feriado.service';
import { Feriado } from 'app/entities/feriado/feriado';

//no funciona crear una interfaz para los eventos del calendario
// export interface eventCalendar {
//   title?:string;
//   start?:Date;
//   end?:Date;
// }

@Component({
    templateUrl: 'calendario-eventos.component.html',
  	selector: 'calendario-eventos',
    styleUrls: ['calendario-eventos.component.scss']
})

export class CalendarioEventosComponent implements OnInit{
  @ViewChild('calendar') calendar: FullCalendarComponent;
    listadoEventos: any[];
    evento:any = {"title":"","start":"","end":""};
    calendario:Calendario =  new Calendario();
    nuevoEvento:Calendario;
    dialogVisible: boolean = false;
    dialogNuevoEvento = false;
    options:CalendarOptions;
    keyEntity = 'key_calendario';
    permisos:any = new RolePermisoEntidad();
    finishLoad = false;
    constructor(private feriadoService: FeriadoService, private requerimientoService:RequerimientoService, private messageService: MsgService, private authService:AuthService, private translate: TranslateService,private calendarioService:CalendarioService){}

    async ngOnInit() {
      this.authService.getPermisosPorEntidad(this.keyEntity).then(resp=>{this.permisos = resp});     
      //await this.addRequerimientos(null);
      this.options = {
          plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
          height: 600,  
          defaultAllDay: true,
          eventColor: '#a0e6ba',
          eventTextColor: 'black',
          //viewClassNames:"calendar-style",        
          locale: this.authService.globalIdioma.code,//this.authService.globalIdioma ? this.authService.globalIdioma.code : 'es',
          // buttonText:{
          //   today: this.translate.instant("primeng.today"),
          // },
          firstDay:1,
          displayEventTime: false,
          nowIndicator:true,                    
          events: await this.addEvents(null),
          //eventsSet: async (e) => {await this.changeMonth(e)}, 
          customButtons:{
            next:{click: async (e)=>{
              await this.changeMonth(1)
            }}
            ,prev:{click: async (e)=>{
              await this.changeMonth(2)
            }}
            ,today:{
              text : this.translate.instant("primeng.today"),
              click: async (e)=>{
                await this.changeMonth(3)
            }}
          },
          //dayMaxEventRows:3,
          //moreLinkText: "más info",
          // views: {
          //   timeGrid: {
          //     dayMaxEventRows: 3,              
          //   }
          // },
          // header: {
          //   left: 'title',
          //   center: '',
          //   right: 'today,prev,next'
          // },

          // eventTimeFormat: {
          //   hour: '2-digit',
          //   minute: '2-digit',
          //   omitZeroMinute: false,
          //   meridiem: 'short',
          //   //hour12: true // añade a.m. y p.m.
          // },          
          selectable: true,
          eventClick: (e) =>  {
            //console.log(e);
            this.muestraEvento(e.event);
          },
          // select: (fechas) => {
          //   if(this.permisos.crear){
          //     this.setEvento(fechas);     
          //   }            
          // }          
      };
      this.finishLoad = true;
    }

    async changeMonth(action){
      //console.log("🚀 ~ file: calendario-eventos.component.ts:101 ~ CalendarioEventosComponent ~ changeMonth ~ event:", action)  
      if(action == 1){
        this.calendar.getApi().next();
      }else if(action == 2){
        this.calendar.getApi().prev();
      }else if(action == 3){
        this.calendar.getApi().today();
      }
      let newEvents = await this.addEvents(this.calendar.getApi().view);
      //console.log("🚀 ~ file: calendario-eventos.component.ts:101 ~ CalendarioEventosComponent ~ changeMonth ~ newEvents:", newEvents)
      this.calendar.events = newEvents;      
    }

    async addEvents(event){
      this.listadoEventos = [];
      let listReq = await this.getRequerimientos(event);
      for(let r of listReq){
        this.listadoEventos.push(this.setFormatRequerimiento(r));        
      }
      let listFer = await this.getFeriados(event);
      for(let f of listFer){
        this.listadoEventos.push(this,this.setFormatFeriado(f))
      }
      let tbl = { first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null };
      let response:any = await this.calendarioService.getPage(this.calendario, tbl, []);            
      for(let e of response.content){        
        this.listadoEventos.push(this.setFormatCalendarioAEvento(e));
      }
      return this.listadoEventos;
      //console.log("🚀 ~ file: calendario-eventos.component.ts:107 ~ CalendarioEventosComponent ~ addRequerimientos ~ this.listadoEventos:", this.listadoEventos)
      
    }
    async getRequerimientos(fechaFiltro?){
      let primerDiaDelMes;
      let ultimoDiaDelMes;
      if(fechaFiltro){
        primerDiaDelMes = fechaFiltro.activeStart;
        ultimoDiaDelMes = fechaFiltro.activeEnd;
      }else{
        let hoy = new Date();
        primerDiaDelMes = new Date(hoy.getFullYear(), hoy.getMonth()-1, 15)
        ultimoDiaDelMes = new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0)
      }      
      let rangeDate = {'fecha_cierre_propuesta':[primerDiaDelMes , ultimoDiaDelMes]}
      //console.log("🚀 ~ file: calendario-eventos.component.ts:86 ~ CalendarioEventosComponent ~ getRequerimientos ~ rangeDate:", rangeDate)      
      let req = new Requerimiento();
      req.contrato = this.authService.globalContrato;
      let result = await this.requerimientoService.completeCalendario(req, rangeDate)
      return result;
    }
    show(fcalen){
      //console.log("🚀 ~ file: calendario-eventos.component.ts:102 ~ CalendarioEventosComponent ~ show ~ fcalen:", fcalen)
      
    }
    setFormatRequerimiento(r:Requerimiento){
      let maxLen = 40;
      let evento = {"title":"","start":null,"end":null,extendedProps:null, color:null, textColor:null};      
      let titulo = 'Req. #' + r.numeroRequerimiento + ' - ' + r.asunto;//'Requerimiento N°'+r.numeroRequerimiento+' - '+r.asunto;
      evento.title = (titulo.length > maxLen)?titulo.substring(0,(maxLen-1)) + '...' : titulo;
      evento.start = r.fechaCierrePropuesta;//r.fechaIngreso;
      evento.end = r.fechaCierrePropuesta;
      evento.extendedProps = r;
      evento.color = '#abc9fb';
      evento.textColor = 'black';
      return evento;
    }
    async getFeriados(fechaFiltro?){
      let primerDiaDelMes;
      let ultimoDiaDelMes;
      if(fechaFiltro){
        primerDiaDelMes = fechaFiltro.activeStart;
        ultimoDiaDelMes = fechaFiltro.activeEnd;
      }else{
        let hoy = new Date();
        primerDiaDelMes = new Date(hoy.getFullYear(), hoy.getMonth()-1, 15)
        ultimoDiaDelMes = new Date(hoy.getFullYear(), hoy.getMonth() + 1, 0)
      }      
      let rangeDate = {'fecha_feriado':[primerDiaDelMes , ultimoDiaDelMes]}
      //console.log("🚀 ~ file: calendario-eventos.component.ts:86 ~ CalendarioEventosComponent ~ getRequerimientos ~ rangeDate:", rangeDate)      
      let fer = new Feriado();
      let result = await this.feriadoService.completeCalendario(fer, rangeDate)
      return result;
    }
    setFormatFeriado(f:Feriado){
      let maxLen = 40;
      let evento = {"title":"","start":null,"end":null,extendedProps:null};      
      let titulo = f.descripcion;
      evento.title = (titulo.length > maxLen)?titulo.substring(0,(maxLen-1)) + '...' : titulo;
      evento.start = f.fechaFeriado;
      evento.end = f.fechaFeriado;
      evento.extendedProps = f;      
      return evento;
    }
    setFormatCalendarioAEvento(e){
      let evento = {"title":"","start":"","end":""};
      evento.title = e.detalle;
      evento.start = e.fechaTermino;
      evento.end = e.fechaTermino;
      return evento;
    }
    muestraEvento(e:any) {
      this.evento = {"title":"","start":"","end":"", extendedProps:null};
      this.evento.title = e.title;
      if(!e.extendedProps.activo){
        this.evento.start = new Date(e.extendedProps.fechaIngreso);
        this.evento.end = new Date(e.extendedProps.fechaCierrePropuesta == null ? e.extendedProps.fechaIngreso:e.extendedProps.fechaCierrePropuesta);
      }else{
        this.evento.start = new Date(e.extendedProps.fechaFeriado);
        this.evento.end = new Date(e.extendedProps.fechaFeriado);
      }
      this.evento.extendedProps = e.extendedProps;
      this.dialogVisible = true;
    }
    setEvento(fechas){            
      let inicio = new Date(fechas.start.setHours(9)); // hora "9" por defecto para que no quede 00
      let termino = new Date(new Date(fechas.end.setDate(fechas.end.getDate() - 1)).setHours(19));
      this.nuevoEvento = new Calendario();
      this.nuevoEvento.usuario = this.authService.globalUser;
      this.nuevoEvento.estado = true;
      this.nuevoEvento.fechaInicio = inicio
      this.nuevoEvento.fechaTermino = termino;  
      this.dialogNuevoEvento = true;    
    }
    async saveEvento(){
      this.nuevoEvento = new Calendario(await this.calendarioService.update(this.nuevoEvento));
      this.listadoEventos = [...this.listadoEventos, this.setFormatCalendarioAEvento(this.nuevoEvento)];
      this.messageService.success(this.translate.instant("Calendario"), this.translate.instant("lblGuardo"));
      this.dialogNuevoEvento = false;
    }
    onCancel(){
      this.dialogNuevoEvento = false;
    }
    despliegaDia(e:any){
      ////console.log(new Date(e.date.format().replace(/-/g, '/'))); //si no se reemplaza "-" por "/" se resta un dia a la fecha (no se porque pasa eso)
      ////console.log(e.date.format());
    }
}

