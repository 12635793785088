// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .btn-archivo-choose {
  padding: 0.45rem 0.75rem !important;
}`, "",{"version":3,"sources":["webpack://./src/app/entities/requerimientoArchivo/requerimientoArchivo.component.scss"],"names":[],"mappings":"AAUA;EACI,mCAAA;AATJ","sourcesContent":["\r\n// :host ::ng-deep .p-card .p-card-content {\r\n//     padding-top: 0 !important;\r\n// }\r\n\r\n\r\n// :host ::ng-deep .p-button.p-fileupload-choose{\r\n//     padding: 0.45rem 0.75rem !important;\r\n// }\r\n\r\n:host ::ng-deep .btn-archivo-choose{\r\n    padding: 0.45rem 0.75rem !important;   \r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
