//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/custom/kpis.component.ts.p.vm
//

import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { LazyLoadEvent } from 'primeng/api';
import { PageResponse } from "../../support/paging";
import { MsgService } from '../../service/message.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import am4lang_es_ES from "@amcharts/amcharts4/lang/es_ES";


@Component({
	templateUrl: 'kpis.component.html',
	selector: 'kpis'
})
export class KpiComponent {
    @Input() header = "KPI's";
	@Input() home = false;
    activeFilter : boolean = true;
    filterRangeNumber:any={};
    filterRangeDate:any={};
    private chart: am4charts.XYChart;
	rangeDates: Date[];
	listKpi = []
	kpiSelect = [];

    constructor(private router : Router,
        private translate: TranslateService,
        private messageService : MsgService,
        //private AmCharts: AmChartsService,
        private http: HttpClient,
        private authService : AuthService) {
    }
    //carga inicial de las columnas de la tabla segun usuario conectado o por default
    async ngOnInit(){
	  this.listKpi = await this.http.get<any>('/api/kpis/listado').toPromise();
	  if(this.home){
		for(let k of this.listKpi){
			if(k.id == 4){
			  	let datos = await this.http.get<any>('/api/kpis/'+k.id).toPromise();
				this.graficoPie(datos,k);
			}			
		}
	  }
	}
	async setGrafico(){ 		
		for(let k of this.kpiSelect){			
			let datos = await this.http.get<any>('/api/kpis/'+k.id).toPromise();
			// this.graficoBase(datos,k);
			if(k.tipo == "pie"){
				this.graficoPie(datos,k);
			}else{
				this.graficoBase(datos,k);
			}      		
		}
 	
	}
	graficoPie(datos, valuesKpi){
		am4core.useTheme(am4themes_animated);
		let chart = am4core.create("chartdiv"+valuesKpi.id, am4charts.PieChart);
		chart.data = datos;
		let title = chart.titles.create();
		title.text = this.translate.instant(valuesKpi.titulo);
		title.fontSize = 25;
		title.marginBottom = 30;
		// Add and configure Series
		let pieSeries = chart.series.push(new am4charts.PieSeries());
		pieSeries.dataFields.value = valuesKpi.fields[0].value;
		pieSeries.dataFields.category = valuesKpi.categoryField;
		this.setFormat(chart);
	}
    graficoBase(datos, valuesKpi) {
		am4core.useTheme(am4themes_animated);
		let chart = am4core.create("chartdiv"+valuesKpi.id, am4charts.XYChart);
		chart.data = datos;
		let title = chart.titles.create();
		title.text = this.translate.instant(valuesKpi.titulo);
		title.fontSize = 25;
		title.marginBottom = 30;

		// Create axes
		let categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
		categoryAxis.title.text = this.translate.instant(valuesKpi.xTitle);

		let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.title.text = this.translate.instant(valuesKpi.yTitle);

		for(let field of valuesKpi.fields){
			// Create series
			let series = chart.series.push(new am4charts.LineSeries());
			series.dataFields.valueY = field.value;
			series.dataFields.dateX = valuesKpi.categoryField;
			series.fill = am4core.color(field.color);
			series.stroke = am4core.color(field.color);
			series.name = this.translate.instant(field.title);
			series.strokeWidth = 2;
			var bullet = series.bullets.push(new am4charts.CircleBullet());
			bullet.circle.stroke = am4core.color(field.color);
			bullet.circle.strokeWidth = 2;
			//series.columns.template.tooltipText = "Series: {name}\nCategory: {categoryX}\nValue: {valueY}";
			//series.columns.template.fill = am4core.color("#104547"); // fill
		}
		this.setFormat(chart);		
		
	}
	  
	setFormat(chart){
		chart.legend = new am4charts.Legend();
		chart.cursor = new am4charts.XYCursor();
		chart.language.locale = am4lang_es_ES;
		chart.numberFormatter.language = new am4core.Language();
		chart.numberFormatter.language.locale = am4lang_es_ES;
		chart.dateFormatter.language = new am4core.Language();
		chart.dateFormatter.language.locale = am4lang_es_ES;
	}
}