//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/custom/configuracion-sistema.component.ts.p.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { Config } from '../../entities/config/config';
import { ConfigService } from '../../entities/config/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	templateUrl: 'configuracion-sistema.component.html',
	selector: 'configuracion-sistema',
})
export class ConfiguracionSistemaComponent implements OnInit {
    @ViewChild('logoBinary', {static: false}) logoBinary:any;
    cantidadlogoBinary = 0;
    @ViewChild('faviconBinary', {static: false}) faviconBinary:any;
    cantidadfaviconBinary = 0;
    @ViewChild('configForm', {static: false}) configForm:any;
    config : Config;

    guardo: boolean = false;

    objectURLLogoBinary: any = null;
    fileSelectedLogoBinary: boolean = false;
    isImageLogoBinary: boolean = false;
    chooseValueLogoBinary: any = null;

    objectURLFaviconBinary: any = null;
    fileSelectedFaviconBinary: boolean = false;
    isImageFaviconBinary: boolean = false;
    chooseValueFaviconBinary: any = null;


    @Input() sub : boolean = false;
    @Output() onSaveClicked = new EventEmitter<Config>();
    @Output() onCancelClicked = new EventEmitter();
    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private router: Router, private messageService: MsgService, private configService: ConfigService, private sanitizer: DomSanitizer) {
    }

    async ngOnInit() {
      try{
          this.config = await this.configService.getConfig(1);
          if(this.config.logoFileName != undefined || this.config.logoFileName != null){
            this.chooseValueLogoBinary = this.config.logoFileName
          }
          if(this.config.faviconFileName != undefined || this.config.faviconFileName != null){
            this.chooseValueFaviconBinary = this.config.faviconFileName
          }
      }catch(error){
          this.messageService.customError(this.translate.instant("configuracionSistema"), this.translate.instant("lblErrorNgOnInit"), error);
      }
    }
    confirmacionVolver() {
        this.confirmationService.confirm({
          message: this.translate.instant("lblMensajeConfirmacion"),
          header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'configKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave(event: any) {
      if(this.configForm.form.valid){

        this.confirmationService.confirm({
          target: event.target,
          message: this.translate.instant("lblPreguntaUpdate"),
          icon: 'pi pi-exclamation-triangle',
          accept: async () => {        
            try{
              this.config = await this.configService.update(this.config, this.logoBinary.files[0], this.faviconBinary.files[0]);
              this.removeFileLogoBinary();          
              this.removeFileFaviconBinary();
              this.guardo = true;
              /* this.logoBinary.url = '/api/configs/'+this.config.id+'/upload/logoBinary';
              await this.logoBinary.upload();
              this.faviconBinary.url = '/api/configs/'+this.config.id+'/upload/faviconBinary';
              await this.faviconBinary.upload(); */
              this.messageService.success(this.translate.instant("configuracionSistema"), this.translate.instant("lblGuardo"));
            }catch(error){
              this.messageService.customError(this.translate.instant("configuracionSistema"),this.translate.instant("lblProblemaGuardar"), error);
            }
          },
          reject: () => {
             //reject action
          }
        });
      }else{
        this.messageService.warn(this.translate.instant("configuracionSistema"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    downloadFormat(file, name){
      var fileURL = window.URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', name);
      tempLink.click();
    }
    
    downloadLogoBinary(){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(this.config.logoFileName);
      let http = this.configService.downloadLogoBinary(this.config).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,this.config.logoFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    selectFileLogoBinary(fileTMP){
      this.fileSelectedLogoBinary = true;
      this.chooseValueLogoBinary = this.logoBinary.files[0].name;
      if(this.logoBinary.isImage(this.logoBinary.files[0])){
        this.isImageLogoBinary=true;
        this.objectURLLogoBinary = this.sanitizer.bypassSecurityTrustUrl(fileTMP.currentFiles[0].objectURL.changingThisBreaksApplicationSecurity);
      }
    }

    removeFileLogoBinary(){
      this.logoBinary.clear(); 
      this.fileSelectedLogoBinary = false; 
      this.isImageLogoBinary = false;
      if(this.config.logoFileName != undefined || this.config.logoFileName != null){
        this.chooseValueLogoBinary = this.config.logoFileName;
      }else{
        this.chooseValueLogoBinary = null;
      }
    } 
    
    downloadFaviconBinary(){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(this.config.faviconFileName);
      let http = this.configService.downloadFaviconBinary(this.config).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,this.config.faviconFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    selectFileFaviconBinary(fileTMP){
      this.fileSelectedFaviconBinary = true;
      this.chooseValueFaviconBinary = this.faviconBinary.files[0].name;
      if(this.faviconBinary.isImage(this.faviconBinary.files[0])){
        this.isImageFaviconBinary=true;
        this.objectURLFaviconBinary = this.sanitizer.bypassSecurityTrustUrl(fileTMP.currentFiles[0].objectURL.changingThisBreaksApplicationSecurity);
      }
    }

    removeFileFaviconBinary(){
      this.logoBinary.clear(); 
      this.fileSelectedFaviconBinary = false; 
      this.isImageFaviconBinary = false;
      if(this.config.faviconFileName != undefined || this.config.faviconFileName != null){
        this.chooseValueFaviconBinary = this.config.faviconFileName;
      }else{
        this.chooseValueFaviconBinary = null;
      }
    } 

    onCancel() {
        if (this.sub) {
            this.onCancelClicked.emit("cancel");
            this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }

}
