//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/support/all-pipes.pipe.ts.p.vm
//
import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: "pipeCustom"
})
export class AllPipes implements PipeTransform {
  constructor(private datePipe : DatePipe, private decimalPipe : DecimalPipe){

  }
  transform(value: any, tipo: any = 'NA', arg?:any):any {
    if(tipo == 'bool')
      return value ? "Sí" : "No";
    else if(tipo == 'date')
      return this.datePipe.transform(value,arg);
    else if(tipo == 'number' || tipo == 'rut')
      return this.decimalPipe.transform(value);    
    else
      return value;
  }
}
