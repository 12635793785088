//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-list.component.ts.e.vm
//
import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges, OnInit } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { PageResponse } from '../../support/paging';
import { MsgService } from '../../service/message.service';
import { GlobalAuditService } from '../../service/globalAudit.service';
import { ArchivoNotificacion } from './archivoNotificacion';
import { ArchivoNotificacionDetailComponent } from './archivoNotificacion-detail.component';
import { ArchivoNotificacionService } from './archivoNotificacion.service';
import { AuthService } from '../../service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { RolePermisoEntidad } from '../rolePermisoEntidad/rolePermisoEntidad';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { Notificacion } from '../notificacion/notificacion';
import { NotificacionLineComponent } from '../notificacion/notificacion-line.component';
import { RequerimientoTipo } from '../requerimientoTipo/requerimientoTipo';
import { RequerimientoTipoLineComponent } from '../requerimientoTipo/requerimientoTipo-line.component';
@Component({
	templateUrl: 'archivoNotificacion-list.component.html',
	selector: 'archivoNotificacion-list'
})
export class ArchivoNotificacionListComponent implements OnInit{
    @Input() header = "tltArchivoNotificacion";
    @Input() padre = ""; //nombre del padre que se pasa por parametro para el header de la tabla y poder aplicar el translate

    // When 'sub' is true, it means this list is used as a one-to-many list.
    // It belongs to a parent entity, as a result the addNew operation
    // must prefill the parent entity. The prefill is not done here, instead we
    // emit an event.
    // When 'sub' is false, we display basic search criterias
    @Input() sub : boolean;
    @Input() editContent : boolean = true;
    @Output() onAddNewClicked = new EventEmitter();
    @Output() onRowSelectSub = new EventEmitter();
    permisos:any = new RolePermisoEntidad();
    keyEntity = 'key_archivoNotificacion';
    key_sessionStorage = 'stateSessionArchivoNotificacion-list';
    optionsColumns: any;
    originalColumns:any;
    cols:any [] = [];
    menuRuta: any = 'archivoNotificacion-list';
    archivoNotificacionToDelete : ArchivoNotificacion;
    archivoNotificacionHistory : any;
    showDialog: boolean = false;
    loading: boolean = true;
    activeFilter : boolean = false;
    editColumnArchivoNotificacion:boolean = false;
    // basic search criterias (visible if not in 'sub' mode)
    example : ArchivoNotificacion = new ArchivoNotificacion();
    rowDataTmpView:any = null;
    urlView:string = null;
    formato:string = null;
    showDialogPreView:boolean = false;
    rangeNumber:any={};
    filterRangeNumber:any={};
    filterRangeDate:any={};
    public es: any = {firstDayOfWeek: 1,dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],dayNamesMin: [ "D","L","MA","MI","J","V","S" ],monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ]};
    sortOrder:any;
sortField:any;
loadColums = false;
    // list is paginated
    currentPage : PageResponse<ArchivoNotificacion> = new PageResponse<ArchivoNotificacion>(0,0,[]);

    // X to one: input param is used to filter the list when displayed
    // as a one-to-many list by the other side.
    private _notificacion : Notificacion;
    private _requerimientoTipo : RequerimientoTipo;

    constructor(private router : Router,
        private archivoNotificacionService : ArchivoNotificacionService,
        private messageService : MsgService,
        private globalAudit : GlobalAuditService,
        private authService : AuthService,
        private translate: TranslateService,
        private confirmationService: ConfirmationService) {
    }

    //carga inicial de las columnas de la tabla segun usuario conectado o por default
    async ngOnInit(){
      let filters = JSON.parse(sessionStorage.getItem(this.key_sessionStorage));
      this.cols = [];
      if(filters){
        this.example = filters.example;
        this.sortField = filters.sortField;
        this.sortOrder = filters.sortOrder;
        for(let f of Object.keys(filters.filterRangeDate)){          
          this.filterRangeDate[f] = filters.filterRangeDate[f].map(date => new Date(date))
        }
        for(let n of Object.keys(filters.filterRangeNumber)){
          this.rangeNumber[n] = filters.filterRangeNumber[n][0] + '-'+filters.filterRangeNumber[n][1];
          this.filterRangeNumber[n] = filters.filterRangeNumber[n];
        }
      }
      if(this.authService.globalContrato.finalizado || this.editContent == false){
        this.permisos.crear = false;
        this.permisos.actualizar = false;        
        this.permisos.eliminar = false; 
        this.permisos.descargar = true;       
      }else{
        this.authService.getPermisosPorEntidad(this.keyEntity).then(resp=>{this.permisos = resp});
      }
      if(this.authService.globalColumns == undefined){
        await this.authService.setGlobalColumnsfromDB();
      }
      this.originalColumns = this.authService.globalColumns[this.menuRuta];           
      this.optionsColumns = this.originalColumns.filter(col => (col.codeLanguage == this.authService.globalIdioma.code || (col.codeLanguage == undefined || col.codeLanguage == "all")) && col.delete == false);
      for(let cl of this.optionsColumns){
        if(cl.default){
          this.cols.push(cl);
        }
      }
      this.loadColums = true;
    }
    /**
     * When used as a 'sub' component (to display one-to-many list), refreshes the table
     * content when the input changes.
     */
    ngOnChanges(changes: SimpleChanges) {
        //this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
    }
    viewFilter(){
      if(this.activeFilter)
        this.activeFilter=false;
      else
        this.activeFilter=true;
    }
    clean(dt : any){
      //dt.reset();
      this.example = new ArchivoNotificacion();
      // evita que se pierda el filtro de los input (padres) cuandos es sub
      this.example.notificacion = this._notificacion;
      this.example.requerimientoTipo = this._requerimientoTipo;
      this.filterRangeDate = {};
      this.filterRangeNumber = {}
      this.rangeNumber = {}
      dt.filters = {};
      sessionStorage.removeItem(this.key_sessionStorage);
      dt.reset();
      this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
    }
    
    //Actualiza la tabla manteniendo orden, filtro y página
    async reload(){
      let tmp: any = JSON.parse(sessionStorage.getItem(this.key_sessionStorage));
      let sortField:any = tmp?.sortField?tmp.sortField:null;
      let sortOrder:any = tmp?.sortOrder?tmp.sortOrder:null;
      let filters:any = tmp?.filters?tmp.filters:null;      
      await this.ngOnInit();
      this.loadPage({ first: 0, rows: 10, sortField: sortField, sortOrder: sortOrder, filters: filters, multiSortMeta: null });
    }

    /**
     * Invoked when user presses the search button.
     */
    /*search(dt : any) {
      dt.reset();
      this.loadPage({ first: 0, rows: dt.rows, sortField: dt.sortField, sortOrder: dt.sortOrder, filters: null, multiSortMeta: dt.multiSortMeta });
    }*/

    /**
     * Invoked automatically by primeng datatable.
     */
    async loadPage(event : LazyLoadEvent) {
      this.loading = true;
      try {
        //this.currentPage = new PageResponse<ArchivoNotificacion>(0,0,[]);
        let page:any = await this.archivoNotificacionService.getPage(this.example, event, this.cols, this.filterRangeDate, this.filterRangeNumber);
        this.currentPage = new PageResponse<ArchivoNotificacion>(page.totalPages, page.totalElements, ArchivoNotificacion.toArray(page.content));
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.messageService.customError(this.translate.instant("ArchivoNotificacion"),this.translate.instant("lblErrorNgOnInit"), error);
      }
    }

    //Registra los atributos al Session Storage para mantener los filtros en la sesión
    setFilterTable(dt){       
      let filters = {
        'example':this.example,
        'filterRangeDate':this.filterRangeDate,
        'filterRangeNumber':this.filterRangeNumber,
        'sortOrder':dt.sortOrder,
        'sortField':dt.sortField
      }
      sessionStorage.setItem(this.key_sessionStorage,JSON.stringify(filters))
      this.loadPage({ first: 0, rows: dt.rows, sortField: dt.sortField, sortOrder: dt.sortOrder, filters: null, multiSortMeta: dt.multiSortMeta });      
    }

    // evita que los auto complete recargen toda la tabla al hacer click en el despliegue
    controlFiltros(attr, table){
      if(attr != null){
        this.setFilterTable(table);
      }
    }

    setFormatNumberRange(inputElement, sort) {
      const value = this.rangeNumber[sort]; 
      let spl = value.split("-");            
      
      if (spl.length < 2) {
        this.rangeNumber[sort] = value + "-";   
        inputElement.srcElement.value = this.rangeNumber[sort];
        inputElement.srcElement.setSelectionRange(this.rangeNumber[sort].length-1, this.rangeNumber[sort].length-1);
      }else if(spl.length >=3){
        this.rangeNumber[sort] = spl[0] +'-'+spl[1];   
        inputElement.srcElement.value = this.rangeNumber[sort];
        inputElement.srcElement.setSelectionRange(this.rangeNumber[sort].length, this.rangeNumber[sort].length);
      }            
    }

    setFilterRangeNumber(sort){
      if(!this.rangeNumber[sort]){
        return;
      }
      let spl = this.rangeNumber[sort].split("-");      
      if(spl.length == 2){
        let desde = spl[0].trim() != '' ? spl[0]:null;
        let hasta = spl[1].trim() != '' ? spl[1]:null;
        if(this.filterRangeNumber[sort] == null){
          this.filterRangeNumber[sort] = [null,null]
        }       
        this.filterRangeNumber[sort][0] = desde;
        this.filterRangeNumber[sort][1] = hasta;        
      }else{        
        this.filterRangeNumber[sort] = null;
      }      
    }

    //guarda la seleccion de columnas para perdurar en proximas conexiones del usuario
    async saveSelectedColumnsList(){ 
      if(this.editColumnArchivoNotificacion){        
        this.originalColumns.map(org => {org.default = false;});        
        if(this.cols.length > 0){
          let tmpCol = Object.assign([], this.cols);
          for(let col of tmpCol.reverse()){
            col.default = true;
            let index = this.originalColumns.findIndex(t => t.id == col.id);
            this.originalColumns.splice(index,1);     
            this.originalColumns.unshift(col);     
          }                  
        }   
        for(let col of this.cols){
        this.originalColumns.map((r,i) =>{   
          if(this.authService.globalIdioma.code!='es'){  
            if((r.codeLanguage!=this.authService.globalIdioma.code || r.codeLanguage!='all') && col.field.substring(0, col.field.length-2) == r.field){
              this.originalColumns[i].default = true;
            }
          }else{
            if((r.codeLanguage!=this.authService.globalIdioma.code || r.codeLanguage!='all') && r.field.substring(0, r.field.length-2) == col.field){
              this.originalColumns[i].default = true;
            }
          }
        
        });
      }              
        await this.authService.saveColumnsByRuta(this.originalColumns,this.menuRuta);
        await this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
        this.editColumnArchivoNotificacion = false;
      }
    }
    //guarda los anchos de las columnas por usuario
    async saveWidthColumnsList(e){
      let newWidth = (parseFloat(e.element.attributes.style.value.match(/[\d\.]+/))/16).toString() + "em";
      let idResize = e.element.attributes[1].value;
      this.originalColumns.filter(org => {
        for(let col of this.cols){
            if(org.id == idResize){
              org.width = newWidth;
            }
        }
      });  
      await this.authService.saveColumnsByRuta(this.originalColumns,this.menuRuta);
    }
    async getCSV(){
      try {
          let csv : any = await this.archivoNotificacionService.getCSV(this.example, this.filterRangeDate, this.filterRangeNumber);
          var csvData = new Blob(["\ufeff"+csv], {type: 'text/csv;charset=utf-8;'});
          var csvURL = window.URL.createObjectURL(csvData);
          var tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', this.translate.instant("ArchivoNotificacion")+'.csv');
          tempLink.click();
      } catch (error) {
          this.messageService.customError(this.translate.instant("ArchivoNotificacion"),this.translate.instant("lblErrorCSV"), error)
      }
    }


    // X to one: input param is used to filter the list when displayed
    // as a one-to-many list by the other side.
    @Input()
    set notificacion(notificacion : Notificacion) {
        if (notificacion == null) {
            return;
        }
        this._notificacion = notificacion;
        if(this.example == null){
            this.example = new ArchivoNotificacion();
        }
        this.example.notificacion = new Notificacion();
        this.example.notificacion.id = this._notificacion.id;
    }

    @Input()
    set requerimientoTipo(requerimientoTipo : RequerimientoTipo) {
        if (requerimientoTipo == null) {
            return;
        }
        this._requerimientoTipo = requerimientoTipo;
        if(this.example == null){
            this.example = new ArchivoNotificacion();
        }
        this.example.requerimientoTipo = new RequerimientoTipo();
        this.example.requerimientoTipo.id = this._requerimientoTipo.id;
    }


    onRowSelect(event : any) {
        if (this.sub){
            this.onRowSelectSub.emit(event.data);
        } else {
            let id =  event.data.id;
            this.router.navigate(['/archivoNotificacion', id]);
        }
    }

    addNew() {
        if (this.sub) {
            this.onAddNewClicked.emit("addNew");
        } else {
            this.router.navigate(['/archivoNotificacion', 'new']);
        }
    }

    showDeleteDialog(rowData : any, event: any) {
        let archivoNotificacionToDelete : ArchivoNotificacion = <ArchivoNotificacion> rowData;

        this.confirmationService.confirm({
            target: event.target,
            message: this.translate.instant("lblPreguntaDelete"),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.delete(archivoNotificacionToDelete);
            },
            reject: () => {
                //reject action
            }
        });
    }

    showPreView(data){
      this.rowDataTmpView = data;
      if(data.archivoContentType == "application/pdf"){
        this.formato = data.archivoContentType;
      }else if(data.archivoContentType.includes('image')){
        this.formato = "images";
      }else{
        this.formato = "FNS";//Formato no soportado
      }
      this.urlView = "/api/archivoNotificacions/"+data.id+"/download/archivoBinary";
      this.showDialogPreView = true;
    }

    downloadArchivoBinary(rowData){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(rowData.archivoFileName);
      let http = this.archivoNotificacionService.downloadArchivoBinary(rowData).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,rowData.archivoFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    downloadFormat(file, name){
      var fileURL = window.URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', name);
      tempLink.click();
    }
    async historico(archivoNotificacionToHistory : ArchivoNotificacion){
      let entidad:any = {"entidad":"ArchivoNotificacion","id":archivoNotificacionToHistory.id};
      try{
        this.archivoNotificacionHistory = await this.globalAudit.getHistory(entidad);
        this.showDialog = true;
      }catch(error){
        this.messageService.customError("ArchivoNotificacion:", this.translate.instant("lblProblemaObtenerHistorico"), error);
      }
    }    
    
    private async delete(archivoNotificacionToDelete : ArchivoNotificacion) {
        let id =  archivoNotificacionToDelete.id;
        try {
          await this.archivoNotificacionService.delete(id);
          this.currentPage.remove(archivoNotificacionToDelete);
          this.messageService.info("ArchivoNotificacion:", this.translate.instant("lblRegistroEliminado"));
        } catch (error) {
          this.messageService.customError("ArchivoNotificacion:", this.translate.instant("lblNoPudoEliminar"), error);
        }
    }
}
