//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/support/all-pipes.pipe.ts.p.vm
//
import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: "rowClassNuevosRegistros"
})
export class RowClassNuevosRegistros implements PipeTransform {
  constructor(){

  }
  transform(list: any[], id):any {
    return list.findIndex( r => r.id == id) > -1;
  }
}
