//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { ArchivoAnexo } from './archivoAnexo';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class ArchivoAnexoService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a ArchivoAnexo by id.
     */
    getArchivoAnexo(id : any) {
        return this.http.get<ArchivoAnexo>('/api/archivoAnexos/' + id).toPromise();
    }

    /**
     * Update the passed archivoAnexo.
     */
    update(archivoAnexo : ArchivoAnexo, archivoAnexoBinary : File) {
      let formData = new FormData();
      formData.append('body',new Blob([JSON.stringify(archivoAnexo)],{type: "application/json"}));
      formData.append('archivoAnexoBinary', archivoAnexoBinary ? archivoAnexoBinary : new Blob());
      return this.http.post<ArchivoAnexo>('/api/archivoAnexos/save', formData).toPromise();
    }

    /**
     * Download File archivoAnexoBinary.
     */
     downloadArchivoAnexoBinary(archivoAnexo : ArchivoAnexo) {
      const req = new HttpRequest('GET', '/api/archivoAnexos/'+archivoAnexo.id+'/download/archivoAnexoBinary', {
          reportProgress: true,
          'responseType': 'blob' as 'json'
        });
      return this.http.request(req);
     }

    /**
     * Load a page (for paginated datatable) of ArchivoAnexo using the passed
     * archivoAnexo as an example for the search by example facility.
     */
     getPage(archivoAnexo : ArchivoAnexo, event : LazyLoadEvent, columns, filterRangeDate?, filterRangeNumber?) {
        let req = new PageRequestByExample(archivoAnexo, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        if(filterRangeDate != undefined){
          formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
        }
        return this.http.post('/api/archivoAnexos/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by ArchivoAnexoListComponent.
      */
    getCSV(archivoAnexo:ArchivoAnexo, filterRangeDate?, filterRangeNumber?) {
      let body = JSON.stringify(archivoAnexo);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      if(filterRangeDate != undefined){
        formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
      }
      return this.http.post('/api/archivoAnexos/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by ArchivoAnexoCompleteComponent.
     */
    complete(archivoAnexo : ArchivoAnexo) {
        if(archivoAnexo == null){
          archivoAnexo = new ArchivoAnexo();
        }
        let body = JSON.stringify(archivoAnexo);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<ArchivoAnexo[]>('/api/archivoAnexos/complete', formData).toPromise();
    }

    /**
     * Delete an ArchivoAnexo by id.
     */
    delete(id : any) {
        return this.http.delete('/api/archivoAnexos/' + id).toPromise();
    }
}
