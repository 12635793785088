//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-line.component.ts.e.vm
//
import {Component, Input} from '@angular/core';
import {AuditoriaSistema} from './auditoriaSistema';

@Component({
	template: `
{{ auditoriaSistema?.usuario }} 
{{ auditoriaSistema?.accion }} 
{{ auditoriaSistema?.dataOld }} 
{{ auditoriaSistema?.dataNew }} 
{{ auditoriaSistema?.tabla }} 
{{ auditoriaSistema?.entidad }} 
{{ auditoriaSistema?.entidadIdNombre }} 
{{ auditoriaSistema?.entidadIdNombre2 }} 
{{ auditoriaSistema?.entidadIdNombre3 }} 
{{ auditoriaSistema?.cambios }} 
	`,
	selector: 'auditoriaSistema-line',
})
export class AuditoriaSistemaLineComponent {
    @Input() auditoriaSistema : AuditoriaSistema;
}



