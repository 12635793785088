//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { Usuario } from './usuario';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class UsuarioService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a Usuario by id.
     */
    getUsuario(id : any) {
        return this.http.get<Usuario>('/api/usuarios/' + id).toPromise();
    }

    /**
     * Update the passed usuario.
     */
    update(usuario : Usuario) {
        let body = JSON.stringify(usuario);
        return this.http.post<Usuario>('/api/usuarios/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of Usuario using the passed
     * usuario as an example for the search by example facility.
     */
     getPage(usuario : Usuario, event : LazyLoadEvent, columns, filterRangeDate?, filterRangeNumber?) {
        let req = new PageRequestByExample(usuario, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        if(filterRangeDate != undefined){
          formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
        }
        return this.http.post('/api/usuarios/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by UsuarioListComponent.
      */
    getCSV(usuario:Usuario, filterRangeDate?, filterRangeNumber?) {
      let body = JSON.stringify(usuario);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      if(filterRangeDate != undefined){
        formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
      }
      return this.http.post('/api/usuarios/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by UsuarioCompleteComponent.
     */
    complete(usuario : Usuario) {
        if(usuario == null){
          usuario = new Usuario();
        }
        let body = JSON.stringify(usuario);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<Usuario[]>('/api/usuarios/complete', formData).toPromise();
    }

    /**
     * Delete an Usuario by id.
     */
    delete(id : any) {
        return this.http.delete('/api/usuarios/' + id).toPromise();
    }
cambiarPasswd(usuario:Usuario, passwdOld : any){
      let body = JSON.stringify(usuario);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));

      if(passwdOld != undefined || passwdOld != null){
        let tmpPasswd = {"passwdOld" : passwdOld};
        formData.append('passwdOld', new Blob([JSON.stringify(tmpPasswd)],{type: "application/json"}));
      }

      return this.http.post<any>('/api/usuarios/cambiarpasswd', formData).toPromise();
    } 
}
