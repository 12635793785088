//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/app.module.ts.p.vm
//
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule, LOCALE_ID, Injector } from '@angular/core';
import { DatePipe, DecimalPipe, registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule  }  from '@angular/router';

//Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

//primeng import
import { KeyFilterModule } from 'primeng/keyfilter';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressBarModule } from 'primeng/progressbar';
import { CardModule } from 'primeng/card';
import { TabViewModule } from 'primeng/tabview';
import { PasswordModule } from 'primeng/password';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { CheckboxModule } from 'primeng/checkbox';
import { PickListModule } from 'primeng/picklist';
import { DropdownModule } from 'primeng/dropdown';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MenubarModule } from 'primeng/menubar';
import { PanelModule } from 'primeng/panel';
import { FileUploadModule } from 'primeng/fileupload';
import { SpinnerModule } from 'primeng/spinner';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TooltipModule } from 'primeng/tooltip';
import { MultiSelectModule } from 'primeng/multiselect';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmationService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TreeTableModule } from 'primeng/treetable';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import { TreeDragDropService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ColorPickerModule } from 'primeng/colorpicker';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { StepsModule } from 'primeng/steps';
import { TimelineModule } from 'primeng/timeline';
import { CarouselModule } from 'primeng/carousel';
import { FieldsetModule } from 'primeng/fieldset';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AppComponent }   from './app.component';
import { HomeComponent }  from './home.component';
import { AuthService } from './service/auth.service';
import { MsgService } from './service/message.service';
import { FormatNumber } from './service/formatNumber.service';
import { GlobalAuditService } from './service/globalAudit.service';
import { routing }        from './app.routes';
import { EmailValidator } from './support/email.validator';
import { authInterceptorProviders } from './service/auth.interceptor';
import { TokenStorageService } from './service/token-storage.service';
import { AllPipes } from './support/all-pipes.pipe';
import { ValidaEdicionUsuario } from './support/valida-edicion-usuario.pipe';
import { RowClassNuevosRegistros } from './support/row-class-nuevos-registros.pipe';
import { RequerimientosPorVencer } from './support/requerimientos-por-vencer.pipe';
import { PrivilegiosEtapa } from './support/privilegios-etapa.pipe';
import { SrcLink } from './support/srcLink.pipe';
import { AvatarModule } from "primeng/avatar";
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DividerModule } from 'primeng/divider';
import { BadgeModule } from 'primeng/badge';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChartModule } from 'primeng/chart';
import { InputMaskModule } from 'primeng/inputmask';

// Full Calendar
import { FullCalendarModule } from '@fullcalendar/angular';

// custom ...
import { DownloadProgressComponent } from './support/download-progress.component';
import { HistorialDialogComponent } from './support/historial-dialog.component';
import { CalendarioEventosComponent } from './custom/calendario/calendario-eventos.component';
import { AuditoriaHistorialComponent } from './custom/auditoria/auditoria-historial.component';
import { KpiComponent } from './custom/kpis/kpis.component';
import { ConfiguracionSistemaComponent } from './custom/configuracionSistema/configuracion-sistema.component';
import { MenuDNDComponent } from './custom/menuDND/menu-dnd.component';
import { ContrasenaComponent } from './custom/contrasena/contrasena.component';
import { PermisosPorEntidadComponent } from './custom/permisos-por-entidad/permisos-por-entidad.component';
import { ColumnListComponent } from './custom/columnList/columnList.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
export let AppInjector: Injector;

// el problema de esta libreria es que utiliza google para la lectura por lo que la ruta del archivo que se debe entregar a google debe ser publica, sin credenciales para la API
//import { NgxDocViewerModule } from 'ngx-doc-viewer';

// AccionEtapa ...
import { AccionEtapaService } from './entities/accionEtapa/accionEtapa.service';
import { AccionEtapaListComponent } from './entities/accionEtapa/accionEtapa-list.component';
import { AccionEtapaDetailComponent } from './entities/accionEtapa/accionEtapa-detail.component';
import { AccionEtapaLineComponent } from './entities/accionEtapa/accionEtapa-line.component';
import { AccionEtapaCompleteComponent } from './entities/accionEtapa/accionEtapa-auto-complete.component';

// Antecedente ...
import { AntecedenteService } from './entities/antecedente/antecedente.service';
import { AntecedenteListComponent } from './entities/antecedente/antecedente-list.component';
import { AntecedenteDetailComponent } from './entities/antecedente/antecedente-detail.component';
import { AntecedenteLineComponent } from './entities/antecedente/antecedente-line.component';
import { AntecedenteCompleteComponent } from './entities/antecedente/antecedente-auto-complete.component';

// AppLabel ...
import { AppLabelService } from './entities/appLabel/appLabel.service';
import { AppLabelListComponent } from './entities/appLabel/appLabel-list.component';
import { AppLabelDetailComponent } from './entities/appLabel/appLabel-detail.component';
import { AppLabelLineComponent } from './entities/appLabel/appLabel-line.component';
import { AppLabelCompleteComponent } from './entities/appLabel/appLabel-auto-complete.component';

// ArchivoAnexo ...
import { ArchivoAnexoService } from './entities/archivoAnexo/archivoAnexo.service';
import { ArchivoAnexoListComponent } from './entities/archivoAnexo/archivoAnexo-list.component';
import { ArchivoAnexoDetailComponent } from './entities/archivoAnexo/archivoAnexo-detail.component';
import { ArchivoAnexoLineComponent } from './entities/archivoAnexo/archivoAnexo-line.component';
import { ArchivoAnexoCompleteComponent } from './entities/archivoAnexo/archivoAnexo-auto-complete.component';

// ArchivoNotificacion ...
import { ArchivoNotificacionService } from './entities/archivoNotificacion/archivoNotificacion.service';
import { ArchivoNotificacionListComponent } from './entities/archivoNotificacion/archivoNotificacion-list.component';
import { ArchivoNotificacionDetailComponent } from './entities/archivoNotificacion/archivoNotificacion-detail.component';
import { ArchivoNotificacionLineComponent } from './entities/archivoNotificacion/archivoNotificacion-line.component';
import { ArchivoNotificacionCompleteComponent } from './entities/archivoNotificacion/archivoNotificacion-auto-complete.component';

// Area ...
import { AreaService } from './entities/area/area.service';
import { AreaListComponent } from './entities/area/area-list.component';
import { AreaDetailComponent } from './entities/area/area-detail.component';
import { AreaLineComponent } from './entities/area/area-line.component';
import { AreaCompleteComponent } from './entities/area/area-auto-complete.component';

// AuditoriaSistema ...
import { AuditoriaSistemaService } from './entities/auditoriaSistema/auditoriaSistema.service';
import { AuditoriaSistemaListComponent } from './entities/auditoriaSistema/auditoriaSistema-list.component';
import { AuditoriaSistemaDetailComponent } from './entities/auditoriaSistema/auditoriaSistema-detail.component';
import { AuditoriaSistemaLineComponent } from './entities/auditoriaSistema/auditoriaSistema-line.component';
import { AuditoriaSistemaCompleteComponent } from './entities/auditoriaSistema/auditoriaSistema-auto-complete.component';

// Calendario ...
import { CalendarioService } from './entities/calendario/calendario.service';
import { CalendarioListComponent } from './entities/calendario/calendario-list.component';
import { CalendarioDetailComponent } from './entities/calendario/calendario-detail.component';
import { CalendarioLineComponent } from './entities/calendario/calendario-line.component';
import { CalendarioCompleteComponent } from './entities/calendario/calendario-auto-complete.component';

// Cargo ...
import { CargoService } from './entities/cargo/cargo.service';
import { CargoListComponent } from './entities/cargo/cargo-list.component';
import { CargoDetailComponent } from './entities/cargo/cargo-detail.component';
import { CargoLineComponent } from './entities/cargo/cargo-line.component';
import { CargoCompleteComponent } from './entities/cargo/cargo-auto-complete.component';

// Config ...
import { ConfigService } from './entities/config/config.service';
import { ConfigListComponent } from './entities/config/config-list.component';
import { ConfigDetailComponent } from './entities/config/config-detail.component';
import { ConfigLineComponent } from './entities/config/config-line.component';
import { ConfigCompleteComponent } from './entities/config/config-auto-complete.component';

// Contrato ...
import { ContratoService } from './entities/contrato/contrato.service';
import { ContratoListComponent } from './entities/contrato/contrato-list.component';
import { ContratoDetailComponent } from './entities/contrato/contrato-detail.component';
import { ContratoLineComponent } from './entities/contrato/contrato-line.component';
import { ContratoCompleteComponent } from './entities/contrato/contrato-auto-complete.component';

// ContratoTipo ...
import { ContratoTipoService } from './entities/contratoTipo/contratoTipo.service';
import { ContratoTipoListComponent } from './entities/contratoTipo/contratoTipo-list.component';
import { ContratoTipoDetailComponent } from './entities/contratoTipo/contratoTipo-detail.component';
import { ContratoTipoLineComponent } from './entities/contratoTipo/contratoTipo-line.component';
import { ContratoTipoCompleteComponent } from './entities/contratoTipo/contratoTipo-auto-complete.component';

// Empresa ...
import { EmpresaService } from './entities/empresa/empresa.service';
import { EmpresaListComponent } from './entities/empresa/empresa-list.component';
import { EmpresaDetailComponent } from './entities/empresa/empresa-detail.component';
import { EmpresaLineComponent } from './entities/empresa/empresa-line.component';
import { EmpresaCompleteComponent } from './entities/empresa/empresa-auto-complete.component';

// EmpresaTipo ...
import { EmpresaTipoService } from './entities/empresaTipo/empresaTipo.service';
import { EmpresaTipoListComponent } from './entities/empresaTipo/empresaTipo-list.component';
import { EmpresaTipoDetailComponent } from './entities/empresaTipo/empresaTipo-detail.component';
import { EmpresaTipoLineComponent } from './entities/empresaTipo/empresaTipo-line.component';
import { EmpresaTipoCompleteComponent } from './entities/empresaTipo/empresaTipo-auto-complete.component';

// Entidad ...
import { EntidadService } from './entities/entidad/entidad.service';
import { EntidadListComponent } from './entities/entidad/entidad-list.component';
import { EntidadDetailComponent } from './entities/entidad/entidad-detail.component';
import { EntidadLineComponent } from './entities/entidad/entidad-line.component';
import { EntidadCompleteComponent } from './entities/entidad/entidad-auto-complete.component';

// Etapa ...
import { EtapaService } from './entities/etapa/etapa.service';
import { EtapaListComponent } from './entities/etapa/etapa-list.component';
import { EtapaDetailComponent } from './entities/etapa/etapa-detail.component';
import { EtapaLineComponent } from './entities/etapa/etapa-line.component';
import { EtapaCompleteComponent } from './entities/etapa/etapa-auto-complete.component';

// Feriado ...
import { FeriadoService } from './entities/feriado/feriado.service';
import { FeriadoListComponent } from './entities/feriado/feriado-list.component';
import { FeriadoDetailComponent } from './entities/feriado/feriado-detail.component';
import { FeriadoLineComponent } from './entities/feriado/feriado-line.component';
import { FeriadoCompleteComponent } from './entities/feriado/feriado-auto-complete.component';

// Funcionalidad ...
import { FuncionalidadService } from './entities/funcionalidad/funcionalidad.service';
import { FuncionalidadListComponent } from './entities/funcionalidad/funcionalidad-list.component';
import { FuncionalidadDetailComponent } from './entities/funcionalidad/funcionalidad-detail.component';
import { FuncionalidadLineComponent } from './entities/funcionalidad/funcionalidad-line.component';
import { FuncionalidadCompleteComponent } from './entities/funcionalidad/funcionalidad-auto-complete.component';

// Manuales ...
import { ManualesService } from './entities/manuales/manuales.service';
import { ManualesListComponent } from './entities/manuales/manuales-list.component';
import { ManualesDetailComponent } from './entities/manuales/manuales-detail.component';
import { ManualesLineComponent } from './entities/manuales/manuales-line.component';
import { ManualesCompleteComponent } from './entities/manuales/manuales-auto-complete.component';

// Menu ...
import { MenuService } from './entities/menu/menu.service';
import { MenuListComponent } from './entities/menu/menu-list.component';
import { MenuDetailComponent } from './entities/menu/menu-detail.component';
import { MenuLineComponent } from './entities/menu/menu-line.component';
import { MenuCompleteComponent } from './entities/menu/menu-auto-complete.component';

// Notificacion ...
import { NotificacionService } from './entities/notificacion/notificacion.service';
import { NotificacionListComponent } from './entities/notificacion/notificacion-list.component';
import { NotificacionDetailComponent } from './entities/notificacion/notificacion-detail.component';
import { NotificacionLineComponent } from './entities/notificacion/notificacion-line.component';
import { NotificacionCompleteComponent } from './entities/notificacion/notificacion-auto-complete.component';

// Requerimiento ...
import { RequerimientoService } from './entities/requerimiento/requerimiento.service';
import { RequerimientoListComponent } from './entities/requerimiento/requerimiento-list.component';
import { RequerimientoResumenListComponent } from './entities/requerimiento/requerimiento-resumen-list.component';
import { RequerimientoDetailComponent } from './entities/requerimiento/requerimiento-detail.component';
import { RequerimientoLineComponent } from './entities/requerimiento/requerimiento-line.component';
import { RequerimientoCompleteComponent } from './entities/requerimiento/requerimiento-auto-complete.component';

// RequerimientoArchivo ...
import { RequerimientoArchivoService } from './entities/requerimientoArchivo/requerimientoArchivo.service';
import { RequerimientoArchivoListComponent } from './entities/requerimientoArchivo/requerimientoArchivo-list.component';
import { RequerimientoArchivoDetailComponent } from './entities/requerimientoArchivo/requerimientoArchivo-detail.component';
import { RequerimientoArchivoLineComponent } from './entities/requerimientoArchivo/requerimientoArchivo-line.component';
import { RequerimientoArchivoCompleteComponent } from './entities/requerimientoArchivo/requerimientoArchivo-auto-complete.component';

// RequerimientoComentario ...
import { RequerimientoComentarioService } from './entities/requerimientoComentario/requerimientoComentario.service';
import { RequerimientoComentarioListComponent } from './entities/requerimientoComentario/requerimientoComentario-list.component';
import { RequerimientoComentarioDetailComponent } from './entities/requerimientoComentario/requerimientoComentario-detail.component';
import { RequerimientoComentarioLineComponent } from './entities/requerimientoComentario/requerimientoComentario-line.component';
import { RequerimientoComentarioCompleteComponent } from './entities/requerimientoComentario/requerimientoComentario-auto-complete.component';

// RequerimientoHistorico ...
import { RequerimientoHistoricoService } from './entities/requerimientoHistorico/requerimientoHistorico.service';
import { RequerimientoHistoricoListComponent } from './entities/requerimientoHistorico/requerimientoHistorico-list.component';
import { RequerimientoHistoricoDetailComponent } from './entities/requerimientoHistorico/requerimientoHistorico-detail.component';
import { RequerimientoHistoricoLineComponent } from './entities/requerimientoHistorico/requerimientoHistorico-line.component';
import { RequerimientoHistoricoCompleteComponent } from './entities/requerimientoHistorico/requerimientoHistorico-auto-complete.component';

// RequerimientoInstruccion ...
import { RequerimientoInstruccionService } from './entities/requerimientoInstruccion/requerimientoInstruccion.service';
import { RequerimientoInstruccionListComponent } from './entities/requerimientoInstruccion/requerimientoInstruccion-list.component';
import { RequerimientoInstruccionDetailComponent } from './entities/requerimientoInstruccion/requerimientoInstruccion-detail.component';
import { RequerimientoInstruccionLineComponent } from './entities/requerimientoInstruccion/requerimientoInstruccion-line.component';
import { RequerimientoInstruccionCompleteComponent } from './entities/requerimientoInstruccion/requerimientoInstruccion-auto-complete.component';

// RequerimientoNuevoRegistro ...
import { RequerimientoNuevoRegistroService } from './entities/requerimientoNuevoRegistro/requerimientoNuevoRegistro.service';
import { RequerimientoNuevoRegistroListComponent } from './entities/requerimientoNuevoRegistro/requerimientoNuevoRegistro-list.component';
import { RequerimientoNuevoRegistroDetailComponent } from './entities/requerimientoNuevoRegistro/requerimientoNuevoRegistro-detail.component';
import { RequerimientoNuevoRegistroLineComponent } from './entities/requerimientoNuevoRegistro/requerimientoNuevoRegistro-line.component';
import { RequerimientoNuevoRegistroCompleteComponent } from './entities/requerimientoNuevoRegistro/requerimientoNuevoRegistro-auto-complete.component';

// RequerimientoPrefijo ...
import { RequerimientoPrefijoService } from './entities/requerimientoPrefijo/requerimientoPrefijo.service';
import { RequerimientoPrefijoListComponent } from './entities/requerimientoPrefijo/requerimientoPrefijo-list.component';
import { RequerimientoPrefijoDetailComponent } from './entities/requerimientoPrefijo/requerimientoPrefijo-detail.component';
import { RequerimientoPrefijoLineComponent } from './entities/requerimientoPrefijo/requerimientoPrefijo-line.component';
import { RequerimientoPrefijoCompleteComponent } from './entities/requerimientoPrefijo/requerimientoPrefijo-auto-complete.component';

// RequerimientoTipo ...
import { RequerimientoTipoService } from './entities/requerimientoTipo/requerimientoTipo.service';
import { RequerimientoTipoListComponent } from './entities/requerimientoTipo/requerimientoTipo-list.component';
import { RequerimientoTipoDetailComponent } from './entities/requerimientoTipo/requerimientoTipo-detail.component';
import { RequerimientoTipoLineComponent } from './entities/requerimientoTipo/requerimientoTipo-line.component';
import { RequerimientoTipoCompleteComponent } from './entities/requerimientoTipo/requerimientoTipo-auto-complete.component';

// Role ...
import { RoleService } from './entities/role/role.service';
import { RoleListComponent } from './entities/role/role-list.component';
import { RoleDetailComponent } from './entities/role/role-detail.component';
import { RoleLineComponent } from './entities/role/role-line.component';
import { RoleCompleteComponent } from './entities/role/role-auto-complete.component';

// RolePermisoEntidad ...
import { RolePermisoEntidadService } from './entities/rolePermisoEntidad/rolePermisoEntidad.service';
import { RolePermisoEntidadListComponent } from './entities/rolePermisoEntidad/rolePermisoEntidad-list.component';
import { RolePermisoEntidadDetailComponent } from './entities/rolePermisoEntidad/rolePermisoEntidad-detail.component';
import { RolePermisoEntidadLineComponent } from './entities/rolePermisoEntidad/rolePermisoEntidad-line.component';
import { RolePermisoEntidadCompleteComponent } from './entities/rolePermisoEntidad/rolePermisoEntidad-auto-complete.component';

// SecuencialNumeroRequerimiento ...
import { SecuencialNumeroRequerimientoService } from './entities/secuencialNumeroRequerimiento/secuencialNumeroRequerimiento.service';
import { SecuencialNumeroRequerimientoListComponent } from './entities/secuencialNumeroRequerimiento/secuencialNumeroRequerimiento-list.component';
import { SecuencialNumeroRequerimientoDetailComponent } from './entities/secuencialNumeroRequerimiento/secuencialNumeroRequerimiento-detail.component';
import { SecuencialNumeroRequerimientoLineComponent } from './entities/secuencialNumeroRequerimiento/secuencialNumeroRequerimiento-line.component';
import { SecuencialNumeroRequerimientoCompleteComponent } from './entities/secuencialNumeroRequerimiento/secuencialNumeroRequerimiento-auto-complete.component';

// SesionColumnList ...
import { SesionColumnListService } from './entities/sesionColumnList/sesionColumnList.service';
import { SesionColumnListListComponent } from './entities/sesionColumnList/sesionColumnList-list.component';
import { SesionColumnListDetailComponent } from './entities/sesionColumnList/sesionColumnList-detail.component';
import { SesionColumnListLineComponent } from './entities/sesionColumnList/sesionColumnList-line.component';
import { SesionColumnListCompleteComponent } from './entities/sesionColumnList/sesionColumnList-auto-complete.component';

// SesionJwt ...
import { SesionJwtService } from './entities/sesionJwt/sesionJwt.service';
import { SesionJwtListComponent } from './entities/sesionJwt/sesionJwt-list.component';
import { SesionJwtDetailComponent } from './entities/sesionJwt/sesionJwt-detail.component';
import { SesionJwtLineComponent } from './entities/sesionJwt/sesionJwt-line.component';
import { SesionJwtCompleteComponent } from './entities/sesionJwt/sesionJwt-auto-complete.component';

// Usuario ...
import { UsuarioService } from './entities/usuario/usuario.service';
import { UsuarioListComponent } from './entities/usuario/usuario-list.component';
import { UsuarioDetailComponent } from './entities/usuario/usuario-detail.component';
import { UsuarioLineComponent } from './entities/usuario/usuario-line.component';
import { UsuarioCompleteComponent } from './entities/usuario/usuario-auto-complete.component';

// Video ...
import { VideoService } from './entities/video/video.service';
import { VideoListComponent } from './entities/video/video-list.component';
import { VideoDetailComponent } from './entities/video/video-detail.component';
import { VideoLineComponent } from './entities/video/video-line.component';
import { VideoCompleteComponent } from './entities/video/video-auto-complete.component';

// Region ...
import { RegionService } from './entities/region/region.service';
import { RegionListComponent } from './entities/region/region-list.component';
import { RegionDetailComponent } from './entities/region/region-detail.component';
import { RegionLineComponent } from './entities/region/region-line.component';
import { RegionCompleteComponent } from './entities/region/region-auto-complete.component';

// Provincia ...
import { ProvinciaService } from './entities/provincia/provincia.service';
import { ProvinciaListComponent } from './entities/provincia/provincia-list.component';
import { ProvinciaDetailComponent } from './entities/provincia/provincia-detail.component';
import { ProvinciaLineComponent } from './entities/provincia/provincia-line.component';
import { ProvinciaCompleteComponent } from './entities/provincia/provincia-auto-complete.component';

// Comuna ...
import { ComunaService } from './entities/comuna/comuna.service';
import { ComunaListComponent } from './entities/comuna/comuna-list.component';
import { ComunaDetailComponent } from './entities/comuna/comuna-detail.component';
import { ComunaLineComponent } from './entities/comuna/comuna-line.component';
import { ComunaCompleteComponent } from './entities/comuna/comuna-auto-complete.component';


@NgModule({
  declarations: [
    AllPipes,
    ValidaEdicionUsuario,
    RowClassNuevosRegistros,
    RequerimientosPorVencer,
    PrivilegiosEtapa,
    SrcLink,
    AppComponent,
    HomeComponent,
    EmailValidator
    ,
// custom
    DownloadProgressComponent,
    HistorialDialogComponent,
    CalendarioEventosComponent,
    AuditoriaHistorialComponent,
    KpiComponent,
    ConfiguracionSistemaComponent,
    MenuDNDComponent,
    ContrasenaComponent,
    PermisosPorEntidadComponent,
    ColumnListComponent

// entities
    ,
    AccionEtapaListComponent,
    AccionEtapaDetailComponent,
    AccionEtapaLineComponent,
    AccionEtapaCompleteComponent
    ,
    AntecedenteListComponent,
    AntecedenteDetailComponent,
    AntecedenteLineComponent,
    AntecedenteCompleteComponent
    ,
    AppLabelListComponent,
    AppLabelDetailComponent,
    AppLabelLineComponent,
    AppLabelCompleteComponent
    ,
    ArchivoAnexoListComponent,
    ArchivoAnexoDetailComponent,
    ArchivoAnexoLineComponent,
    ArchivoAnexoCompleteComponent
    ,
    ArchivoNotificacionListComponent,
    ArchivoNotificacionDetailComponent,
    ArchivoNotificacionLineComponent,
    ArchivoNotificacionCompleteComponent
    ,
    AreaListComponent,
    AreaDetailComponent,
    AreaLineComponent,
    AreaCompleteComponent
    ,
    AuditoriaSistemaListComponent,
    AuditoriaSistemaDetailComponent,
    AuditoriaSistemaLineComponent,
    AuditoriaSistemaCompleteComponent
    ,
    CalendarioListComponent,
    CalendarioDetailComponent,
    CalendarioLineComponent,
    CalendarioCompleteComponent
    ,
    CargoListComponent,
    CargoDetailComponent,
    CargoLineComponent,
    CargoCompleteComponent
    ,
    ConfigListComponent,
    ConfigDetailComponent,
    ConfigLineComponent,
    ConfigCompleteComponent
    ,
    ContratoListComponent,
    ContratoDetailComponent,
    ContratoLineComponent,
    ContratoCompleteComponent
    ,
    ContratoTipoListComponent,
    ContratoTipoDetailComponent,
    ContratoTipoLineComponent,
    ContratoTipoCompleteComponent
    ,
    EmpresaListComponent,
    EmpresaDetailComponent,
    EmpresaLineComponent,
    EmpresaCompleteComponent
    ,
    EmpresaTipoListComponent,
    EmpresaTipoDetailComponent,
    EmpresaTipoLineComponent,
    EmpresaTipoCompleteComponent
    ,
    EntidadListComponent,
    EntidadDetailComponent,
    EntidadLineComponent,
    EntidadCompleteComponent
    ,
    EtapaListComponent,
    EtapaDetailComponent,
    EtapaLineComponent,
    EtapaCompleteComponent
    ,
    FeriadoListComponent,
    FeriadoDetailComponent,
    FeriadoLineComponent,
    FeriadoCompleteComponent
    ,
    FuncionalidadListComponent,
    FuncionalidadDetailComponent,
    FuncionalidadLineComponent,
    FuncionalidadCompleteComponent
    ,
    ManualesListComponent,
    ManualesDetailComponent,
    ManualesLineComponent,
    ManualesCompleteComponent
    ,
    MenuListComponent,
    MenuDetailComponent,
    MenuLineComponent,
    MenuCompleteComponent
    ,
    NotificacionListComponent,
    NotificacionDetailComponent,
    NotificacionLineComponent,
    NotificacionCompleteComponent
    ,
    RequerimientoListComponent,
    RequerimientoResumenListComponent,
    RequerimientoDetailComponent,
    RequerimientoLineComponent,
    RequerimientoCompleteComponent
    ,
    RequerimientoArchivoListComponent,
    RequerimientoArchivoDetailComponent,
    RequerimientoArchivoLineComponent,
    RequerimientoArchivoCompleteComponent
    ,
    RequerimientoComentarioListComponent,
    RequerimientoComentarioDetailComponent,
    RequerimientoComentarioLineComponent,
    RequerimientoComentarioCompleteComponent
    ,
    RequerimientoHistoricoListComponent,
    RequerimientoHistoricoDetailComponent,
    RequerimientoHistoricoLineComponent,
    RequerimientoHistoricoCompleteComponent
    ,
    RequerimientoInstruccionListComponent,
    RequerimientoInstruccionDetailComponent,
    RequerimientoInstruccionLineComponent,
    RequerimientoInstruccionCompleteComponent
    ,
    RequerimientoNuevoRegistroListComponent,
    RequerimientoNuevoRegistroDetailComponent,
    RequerimientoNuevoRegistroLineComponent,
    RequerimientoNuevoRegistroCompleteComponent
    ,
    RequerimientoPrefijoListComponent,
    RequerimientoPrefijoDetailComponent,
    RequerimientoPrefijoLineComponent,
    RequerimientoPrefijoCompleteComponent
    ,
    RequerimientoTipoListComponent,
    RequerimientoTipoDetailComponent,
    RequerimientoTipoLineComponent,
    RequerimientoTipoCompleteComponent
    ,
    RoleListComponent,
    RoleDetailComponent,
    RoleLineComponent,
    RoleCompleteComponent
    ,
    RolePermisoEntidadListComponent,
    RolePermisoEntidadDetailComponent,
    RolePermisoEntidadLineComponent,
    RolePermisoEntidadCompleteComponent
    ,
    SecuencialNumeroRequerimientoListComponent,
    SecuencialNumeroRequerimientoDetailComponent,
    SecuencialNumeroRequerimientoLineComponent,
    SecuencialNumeroRequerimientoCompleteComponent
    ,
    SesionColumnListListComponent,
    SesionColumnListDetailComponent,
    SesionColumnListLineComponent,
    SesionColumnListCompleteComponent
    ,
    SesionJwtListComponent,
    SesionJwtDetailComponent,
    SesionJwtLineComponent,
    SesionJwtCompleteComponent
    ,
    UsuarioListComponent,
    UsuarioDetailComponent,
    UsuarioLineComponent,
    UsuarioCompleteComponent
    ,
    VideoListComponent,
    VideoDetailComponent,
    VideoLineComponent,
    VideoCompleteComponent
    ,
    RegionListComponent,
    RegionDetailComponent,
    RegionLineComponent,
    RegionCompleteComponent
    ,
    ProvinciaListComponent,
    ProvinciaDetailComponent,
    ProvinciaLineComponent,
    ProvinciaCompleteComponent
    ,
    ComunaListComponent,
    ComunaDetailComponent,
    ComunaLineComponent,
    ComunaCompleteComponent
    
    
    ],
    imports: [
// angular
        BrowserModule,        
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
                loader: {
                        provide: TranslateLoader,
                        useFactory: (http: HttpClient) => {
                        return new TranslateHttpLoader(http);
                        },
                        deps: [ HttpClient ]
                }
        }),

// primeng
        // primeng
        KeyFilterModule,
        SkeletonModule,
        ProgressBarModule,
        CardModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        FileUploadModule,
        PanelMenuModule,        
        ColorPickerModule,
        PanelModule,
        MenubarModule,
        DialogModule,
        ButtonModule,
        AutoCompleteModule,
        DropdownModule,
        PickListModule,
        CheckboxModule,
        TriStateCheckboxModule,
        InputTextModule,
        InputTextareaModule,
        InputNumberModule,
        CalendarModule,
        PasswordModule,
        TabViewModule,
        SpinnerModule,
        TableModule,
        ToastModule,
        TreeModule,
        TreeTableModule,
        AccordionModule,
        SplitButtonModule,
        TooltipModule,
        MultiSelectModule,
        SidebarModule,
        OverlayPanelModule,
        AvatarModule,
        ListboxModule,
        StepsModule,
        TimelineModule,
        CarouselModule,
        FieldsetModule,
        RadioButtonModule,
        MenuModule,
        BreadcrumbModule,
        DividerModule,
        BadgeModule,
        SelectButtonModule,
        ChartModule,
        InputMaskModule,

// Full Calendar
        FullCalendarModule,

//custom PDF
        PdfViewerModule,
        //NgxDocViewerModule,

// our application routes
        routing
    ],
    providers: [
        authInterceptorProviders,
        { provide: LOCALE_ID, useValue: 'es' },
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        DownloadProgressComponent,
// our application entity services
        TreeDragDropService,
        AccionEtapaService,
        AntecedenteService,
        AppLabelService,
        ArchivoAnexoService,
        ArchivoNotificacionService,
        AreaService,
        AuditoriaSistemaService,
        CalendarioService,
        CargoService,
        ConfigService,
        ContratoService,
        ContratoTipoService,
        EmpresaService,
        EmpresaTipoService,
        EntidadService,
        EtapaService,
        FeriadoService,
        FuncionalidadService,
        ManualesService,
        MenuService,
        NotificacionService,
        RequerimientoService,
        RequerimientoArchivoService,
        RequerimientoComentarioService,
        RequerimientoHistoricoService,
        RequerimientoInstruccionService,
        RequerimientoNuevoRegistroService,
        RequerimientoPrefijoService,
        RequerimientoTipoService,
        RoleService,
        RolePermisoEntidadService,
        SecuencialNumeroRequerimientoService,
        SesionColumnListService,
        SesionJwtService,
        UsuarioService,
        VideoService,
        RegionService,
        ProvinciaService,
        ComunaService,

// our application services
        TokenStorageService,
        AuthService,        
        MsgService,
        FormatNumber,
        MessageService,
        GlobalAuditService,
        DatePipe,
        DecimalPipe,

// primeng service
        ConfirmationService
    ],
    //entryComponents: [],
    bootstrap: [ AppComponent ]
})
export class AppModule {
      constructor(private injector: Injector) {
        AppInjector = this.injector;
      }
}
