//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-line.component.ts.e.vm
//
import {Component, Input} from '@angular/core';
import {Contrato} from './contrato';

@Component({
	template: `
{{ contrato?.nombre }} 
	`,
	selector: 'contrato-line',
})
export class ContratoLineComponent {
    @Input() contrato : Contrato;
}

/*
{{ contrato?.comuna }} 
{{ contrato?.ciudad }} 
{{ contrato?.codigo }} 
{{ contrato?.region }} 
{{ contrato?.descripcion }} 
{{ contrato?.responsableTecnico }} 
{{ contrato?.responsableTecnicoCorreo }} 
{{ contrato?.responsableTecnicoTelefono }} 
{{ contrato?.responsableTecnicoTelefonoAnexo }} 
{{ contrato?.responsableAdministrativo }} 
{{ contrato?.responsableAdministrativoCorreo }} 
{{ contrato?.responsableAdministratioTelefono }} 
{{ contrato?.responsableAdministrativoTelefonoAnexo }} 
*/



