//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { Etapa } from './etapa';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class EtapaService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a Etapa by id.
     */
    getEtapa(id : any) {
        return this.http.get<Etapa>('/api/etapas/' + id).toPromise();
    }

    getStepsEtapas(idEtapa, idContratoTipo){
      let formData = new FormData();
      formData.append('idEtapa',new Blob([JSON.stringify(idEtapa)],{type: "application/json"}));
      formData.append('idContratoTipo',new Blob([JSON.stringify(idContratoTipo)],{type: "application/json"}));
      return this.http.post<any>('/api/etapas/steps/', formData).toPromise();
    }
    /**
     * Update the passed etapa.
     */
    update(etapa : Etapa) {
        let body = JSON.stringify(etapa);
        return this.http.post<Etapa>('/api/etapas/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of Etapa using the passed
     * etapa as an example for the search by example facility.
     */
     getPage(etapa : Etapa, event : LazyLoadEvent, columns, filterRangeNumber?) {
        let req = new PageRequestByExample(etapa, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        return this.http.post('/api/etapas/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by EtapaListComponent.
      */
    getCSV(etapa:Etapa, filterRangeNumber?) {
      let body = JSON.stringify(etapa);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      return this.http.post('/api/etapas/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by EtapaCompleteComponent.
     */
    complete(etapa : Etapa) {
        if(etapa == null){
          etapa = new Etapa();
        }
        let body = JSON.stringify(etapa);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<Etapa[]>('/api/etapas/complete', formData).toPromise();
    }

    completeRequerimientoFilter(etapa : Etapa, viewId) {
      if(etapa == null){
        etapa = new Etapa();
      }
      let body = JSON.stringify(etapa);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));   
      formData.append('viewId',new Blob([JSON.stringify(viewId)],{type: "application/json"}));             
      return this.http.post<Etapa[]>('/api/etapas/completeRequerimientoFilter', formData).toPromise();
  }    

    /**
     * Delete an Etapa by id.
     */
    delete(id : any) {
        return this.http.delete('/api/etapas/' + id).toPromise();
    }
}
