//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/support/all-pipes.pipe.ts.p.vm
//
import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe, DecimalPipe } from '@angular/common';
import { AuthService } from "app/service/auth.service";

@Pipe({
  name: "validaEdicionUsuario"
})
export class ValidaEdicionUsuario implements PipeTransform {
  constructor(private authService: AuthService){

  }
  transform(listContrato: any[], id):any {
    //console.log("🚀 ~ ValidaEdicionUsuario ~ transform ~ this.authService.globalUser:", this.authService.globalUser)
    let puedeEditar = false;//this.authService.globalUser.cargo.role.id //this.authService.globalUser.roles.find(r =>{ r.id == 1 || r.id == 2}) //admin y adminPsur    
    for(let r of this.authService.globalUser.roles){
      if(r.id == 1 || r.id == 2){
        puedeEditar = true;
        break;
      }
    }

    if(puedeEditar){// si es admin tiene autorizacion para editar cualquier aspecto del usuario
      return true;
    }else if(this.authService.globalContrato && listContrato && listContrato.length > 0){
      for(let contrato of listContrato){
        if(contrato.id == this.authService.globalContrato.id){
          puedeEditar = true;
          break;
        }else{
          puedeEditar = false;
        }
      }
      return puedeEditar;
    }
    return false; //sin permisos

    // let isJa = this.authService.globalUser.roles.findIndex(r =>{ r.id == 4 }) //JA
    // if(isJa == -1) return false; // si no es JA automaticamente no tiene permitido editar usuarios
  }
}