//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { Empresa } from './empresa';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class EmpresaService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a Empresa by id.
     */
    getEmpresa(id : any) {
        return this.http.get<Empresa>('/api/empresas/' + id).toPromise();
    }

    /**
     * Update the passed empresa.
     */
    update(empresa : Empresa) {
        let body = JSON.stringify(empresa);
        return this.http.post<Empresa>('/api/empresas/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of Empresa using the passed
     * empresa as an example for the search by example facility.
     */
     getPage(empresa : Empresa, event : LazyLoadEvent, columns, filterRangeNumber?) {
        let req = new PageRequestByExample(empresa, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        return this.http.post('/api/empresas/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by EmpresaListComponent.
      */
    getCSV(empresa:Empresa, filterRangeNumber?) {
      let body = JSON.stringify(empresa);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      return this.http.post('/api/empresas/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by EmpresaCompleteComponent.
     */
    complete(empresa : Empresa) {
        if(empresa == null){
          empresa = new Empresa();
        }
        let body = JSON.stringify(empresa);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<Empresa[]>('/api/empresas/complete', formData).toPromise();
    }

    /**
     * Delete an Empresa by id.
     */
    delete(id : any) {
        return this.http.delete('/api/empresas/' + id).toPromise();
    }
}
