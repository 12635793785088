//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/support/historial-dialog.component.ts.p.vm
//
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'historial-dialog',
  template: `
              <p-dialog id="dialogo" [(visible)]="showDialog" (onHide)="closeClicked()" [closable]="true" [modal]="true" [showHeader]="false" appendTo="body" [contentStyle]="{'max-height':'40em'}">
                  <p-table #dth [value]="value" emptyMessage="Sin Cambios" appendTo="body" [columns]="[{field:'4',header:'Fecha'},{field:'1',header:'Usuario'},{field:'0',header:'Acción'},{field:'3',header:'Nuevo y/o Modificado'},{field:'4',header:'Original y/o Eliminado'}]">
                      <ng-template pTemplate="caption">
                        <div class="grid align-items-center vertical-container">
                            <div class="col-5">
                            </div>
                            <div class="col-2 text-center">
                              {{'tlpHistoricoRegistro' | translate}}
                            </div>
                            <div class="col-5 text-right">
                              <button type="button" pButton icon="pi pi-file" iconPos="left" pTooltip="{{'tlpDescargar' | translate}}" label="CSV" (click)="exportHistoryToCSV(dth)"></button>
                            </div>
                        </div>
                      </ng-template>
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th>{{'fecha' | translate}}</th>
                              <th>{{'usuario' | translate}}</th>
                              <th>{{'accion' | translate}}</th>
                              <th>{{'dataNew' | translate}}</th>
                              <th>{{'dataOld' | translate}}</th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData >
                          <tr [pSelectableRow]="rowData">
                              <td>{{rowData[4]}}</td>
                              <td>{{rowData[1]}}</td>
                              <td>{{rowData[0]}}</td>
                              <td><div class="html-field-auditoria" [innerHTML]="rowData[3]"></div></td>
                              <td><div class="html-field-auditoria" [innerHTML]="rowData[2]"></div></td>
                          </tr>
                      </ng-template>
                  </p-table>
                  <p-footer>
                    <button type="button" pButton icon="fa fa-close" (click)="closeClicked()" label="{{'lblCerrar' | translate}}"></button>
                  </p-footer>
              </p-dialog>
  `
})
export class HistorialDialogComponent implements OnInit  {
    @Input() value : any;

    @Output() onCloseClicked = new EventEmitter();
    showDialog : any;

    ngOnInit(){
        this.showDialog=true;
    }
    closeClicked() {
        this.showDialog = false;
        this.onCloseClicked.emit(false);
    }

    exportHistoryToCSV(dthCSV:any){
       let index = 0;
       var tabla = dthCSV;
       var aux = (JSON.parse(JSON.stringify(dthCSV.value)));
       for (let obj of aux) {
         if(obj[2] != undefined){
             aux[index][2] = obj[2].replace(/<br>/g,",");
         }
         if(obj[3] != undefined){
           aux[index][3] = obj[3].replace(/<br>/g,",");
         }
         index++;
       }
       tabla.value = aux;
       tabla.csvSeparator = ";";
       tabla.exportFilename = "HistCSV";
       tabla.exportCSV();
       tabla.value = this.value;
   }

}
