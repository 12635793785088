//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/support/all-pipes.pipe.ts.p.vm
//
import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe, DecimalPipe } from '@angular/common';

@Pipe({
  name: "reqPorVencer"
})
export class RequerimientosPorVencer implements PipeTransform {
  constructor(){

  }
  transform(list: any[], id):any {
    for(let req of list){
        if(req.id == id && req.vencimiento <= 0){
            return 1; //rojo
        }else if(req.id == id && req.vencimiento <= 3){
            return 2; // amarillo   
        }
    }
    return 0; //sin color
  }
}