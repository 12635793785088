//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-line.component.ts.e.vm
//
import {Component, Input} from '@angular/core';
import {Empresa} from './empresa';

@Component({
	template: `
{{ empresa?.nombre }} - {{ empresa?.codigo }} 
 
	`,
	selector: 'empresa-line',
})
export class EmpresaLineComponent {
    @Input() empresa : Empresa;
}
/*

{{ empresa?.dv }} 
{{ empresa?.correoElectronico }} 
{{ empresa?.direccion }} 
{{ empresa?.telefono }} 
{{ empresa?.telefonoAnexo }} 
{{ empresa?.responsableTecnico }} 
{{ empresa?.responsableTecnicoCorreo }} 
{{ empresa?.responsableTecnicoTelefono }} 
{{ empresa?.responsableTecnicoTelefonoAnexo }} 
{{ empresa?.responsableAdministrativo }} 
{{ empresa?.responsableAdministrativoCorreo }} 
{{ empresa?.responsableAdministratioTelefono }} 
{{ empresa?.responsableAdministrativoTelefonoAnexo }}

*/


