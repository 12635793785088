//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";
import {Role} from '../role/role';
import {Entidad} from '../entidad/entidad';

export class RolePermisoEntidad {

    menuRuta = 'rolePermisoEntidad-list';
    // Raw attributes
     id : number;
     crear : boolean;
     leer : boolean;
     actualizar : boolean;
     eliminar : boolean;
     descargar : boolean;
     historial : boolean;
    // x-to-one
    role : Role;
    entidad : Entidad;

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            this.crear = json.crear;
            this.leer = json.leer;
            this.actualizar = json.actualizar;
            this.eliminar = json.eliminar;
            this.descargar = json.descargar;
            this.historial = json.historial;

            if (json.role != null) {
                this.role = new Role(json.role);
            }

            if (json.entidad != null) {
                this.entidad = new Entidad(json.entidad);
            }
        }
    }

    // Utils

    static toArray(jsons : any[]) : RolePermisoEntidad[] {
        let rolePermisoEntidads : RolePermisoEntidad[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                rolePermisoEntidads.push(new RolePermisoEntidad(json));
            }
        }
        return rolePermisoEntidads;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(){
        let auth = AppInjector.get(AuthService)
        let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        let code = auth.globalIdioma.code;
        let concatString = '';
        for (let col of allColumnsString) {
            if(col.codeLanguage == code || col.codeLanguage == 'all'){
                concatString += this[col.field]+' ';
            }
        }
        if(concatString){
            return concatString.replace(/null/g,'').trim();
        }
        return '';
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
