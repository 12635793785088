//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";

export class Config {

    menuRuta = 'config-list';
    // Raw attributes
     id : number;
     titulo : string;
     nombreEmpresa : string;
     nombreSistema : string;
     frase : string;
     logoBinary : any;
     logoFileName : string;
     logoContentType : string;
     logoSize : number;
     cssColor1 : string;
     cssColor2 : string;
     cssColor3 : string;
     cssColor4 : string;
     cssColor5 : string;
     direccion : string;
     telefono : string;
     footer : string;
     urlFacebook : string;
     urlInstagram : string;
     urlTwitter : string;
     faviconBinary : any;
     faviconFileName : string;
     faviconContentType : string;
     faviconSize : number;
     appKey : string;
     rutaBase : string;
     usuarioCorreo : string;
     pwdCorreo : string;
     servidorCorreo : string;
     puertoCorreo : number;
     dominio : string;
     versionSistema : string;

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            this.titulo = json.titulo;
            this.nombreEmpresa = json.nombreEmpresa;
            this.nombreSistema = json.nombreSistema;
            this.frase = json.frase;
            this.logoFileName = json.logoFileName;
            this.logoContentType = json.logoContentType;
            this.logoSize = json.logoSize;
            this.cssColor1 = json.cssColor1;
            this.cssColor2 = json.cssColor2;
            this.cssColor3 = json.cssColor3;
            this.cssColor4 = json.cssColor4;
            this.cssColor5 = json.cssColor5;
            this.direccion = json.direccion;
            this.telefono = json.telefono;
            this.footer = json.footer;
            this.urlFacebook = json.urlFacebook;
            this.urlInstagram = json.urlInstagram;
            this.urlTwitter = json.urlTwitter;
            this.faviconFileName = json.faviconFileName;
            this.faviconContentType = json.faviconContentType;
            this.faviconSize = json.faviconSize;
            this.appKey = json.appKey;
            this.rutaBase = json.rutaBase;
            this.usuarioCorreo = json.usuarioCorreo;
            this.pwdCorreo = json.pwdCorreo;
            this.servidorCorreo = json.servidorCorreo;
            this.puertoCorreo = json.puertoCorreo;
            this.dominio = json.dominio;
            this.versionSistema = json.versionSistema;
        }
    }

    // Utils

    static toArray(jsons : any[]) : Config[] {
        let configs : Config[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                configs.push(new Config(json));
            }
        }
        return configs;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(){
        let auth = AppInjector.get(AuthService)
        let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        let code = auth.globalIdioma.code;
        let concatString = '';
        for (let col of allColumnsString) {
            if(col.codeLanguage == code || col.codeLanguage == 'all'){
                concatString += this[col.field]+' ';
            }
        }
        if(concatString){
            return concatString.replace(/null/g,'').trim();
        }
        return '';
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
