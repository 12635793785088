//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/home.component.ts.p.vm
//
import { Component, ViewChild } from '@angular/core';
import { CalendarioEventosComponent } from './custom/calendario/calendario-eventos.component';

@Component({
    templateUrl: 'home.component.html',
    styles: [`
             h1 {color: blue;}
             h4 {color: green;}
             .ui-g {
                text-align: center;
                background-color: aliceblue;}
             `]
})
export class HomeComponent {
   @ViewChild("ce", {static: false}) calendarioEventos:CalendarioEventosComponent;
}
