//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { RolePermisoEntidad } from './rolePermisoEntidad';
import { RolePermisoEntidadService } from './rolePermisoEntidad.service';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '../role/role';
import { Entidad } from '../entidad/entidad';

@Component({
	templateUrl: 'rolePermisoEntidad-detail.component.html',
	selector: 'rolePermisoEntidad-detail',
})
export class RolePermisoEntidadDetailComponent implements OnInit{

    @ViewChild('rolePermisoEntidadForm', {static: false}) rolePermisoEntidadForm:any;
    rolePermisoEntidad : RolePermisoEntidad;

    private params_subscription: any;
    guardo: boolean = false;
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_rolePermisoEntidad';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new RolePermisoEntidad
    set role(role : Role) {
      if(this.rolePermisoEntidad == undefined){
        this.rolePermisoEntidad = new RolePermisoEntidad();
      }
      this.rolePermisoEntidad.role = role;
    }

    @Input() // used to pass the parent when creating a new RolePermisoEntidad
    set entidad(entidad : Entidad) {
      if(this.rolePermisoEntidad == undefined){
        this.rolePermisoEntidad = new RolePermisoEntidad();
      }
      this.rolePermisoEntidad.entidad = entidad;
    }

    @Input() // used to pass the parent when creating a new RolePermisoEntidad
    set selectRolePermisoEntidad(rolePermisoEntidad : RolePermisoEntidad) {
      if(this.sub){
        this.rolePermisoEntidad = rolePermisoEntidad;
      }
    }

    @Output() onSaveClicked = new EventEmitter<RolePermisoEntidad>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'crear': [
    ],
      'leer': [
    ],
      'actualizar': [
    ],
      'eliminar': [
    ],
      'descargar': [
    ],
      'historial': [
    ],
      'role': [
      ],
      'entidad': [
      ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private rolePermisoEntidadService: RolePermisoEntidadService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.rolePermisoEntidad == undefined && this.permisos.crear){
            this.rolePermisoEntidad = new RolePermisoEntidad();
            this.rolePermisoEntidad.crear = true;
            this.rolePermisoEntidad.leer = true;
            this.rolePermisoEntidad.actualizar = true;
            this.rolePermisoEntidad.eliminar = true;
            this.rolePermisoEntidad.descargar = true;
            this.rolePermisoEntidad.historial = true;
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.rolePermisoEntidad = new RolePermisoEntidad();
            this.rolePermisoEntidad.crear = true;
            this.rolePermisoEntidad.leer = true;
            this.rolePermisoEntidad.actualizar = true;
            this.rolePermisoEntidad.eliminar = true;
            this.rolePermisoEntidad.descargar = true;
            this.rolePermisoEntidad.historial = true;
            this.editContent = this.permisos.crear;
          } else {
            this.rolePermisoEntidad = new RolePermisoEntidad(await this.rolePermisoEntidadService.getRolePermisoEntidad(id));
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("RolePermisoEntidad"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'rolePermisoEntidadKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.rolePermisoEntidadForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.rolePermisoEntidadForm.form.valid){
        this.messageService.clear();
        try{
          this.rolePermisoEntidad = new RolePermisoEntidad(await this.rolePermisoEntidadService.update(this.rolePermisoEntidad));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("RolePermisoEntidad"), this.translate.instant("lblGuardo"));
            return this.rolePermisoEntidad;
          }else{
            this.onSaveClicked.emit(this.rolePermisoEntidad);
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("RolePermisoEntidad"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
