//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { ArchivoAnexo } from './archivoAnexo';
import { ArchivoAnexoService } from './archivoAnexo.service';
import { TranslateService } from '@ngx-translate/core';
import { RequerimientoArchivo } from '../requerimientoArchivo/requerimientoArchivo';
import { RequerimientoTipo } from '../requerimientoTipo/requerimientoTipo';
import { Requerimiento } from '../requerimiento/requerimiento';

import { Usuario } from '../usuario/usuario';

@Component({
	templateUrl: 'archivoAnexo-detail.component.html',
	selector: 'archivoAnexo-detail',
})
export class ArchivoAnexoDetailComponent implements OnInit{

    @ViewChild('archivoAnexoBinary', {static: false}) archivoAnexoBinary:any;
    @ViewChild('archivoAnexoForm', {static: false}) archivoAnexoForm:any;
    archivoAnexo : ArchivoAnexo;

    private params_subscription: any;
    guardo: boolean = false;
    displayLoading = false;
    emitirSave:boolean = true;
    objectURLArchivoAnexoBinary: any = null;
    fileSelectedArchivoAnexoBinary: boolean = false;
    isImageArchivoAnexoBinary: boolean = false;
    chooseValueArchivoAnexoBinary: any = null;
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_archivoAnexo';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new ArchivoAnexo
    set archivo(archivo : RequerimientoArchivo) {
      if(this.archivoAnexo == undefined){
        this.archivoAnexo = new ArchivoAnexo();
      }
      this.archivoAnexo.archivo = archivo;
    }

    @Input() // used to pass the parent when creating a new ArchivoAnexo
    set requerimientoTipo(requerimientoTipo : RequerimientoTipo) {
      if(this.archivoAnexo == undefined){
        this.archivoAnexo = new ArchivoAnexo();
      }
      this.archivoAnexo.requerimientoTipo = requerimientoTipo;
    }

    @Input() // used to pass the parent when creating a new ArchivoAnexo
    set requerimiento(requerimiento : Requerimiento) {
      if(this.archivoAnexo == undefined){
        this.archivoAnexo = new ArchivoAnexo();
      }
      this.archivoAnexo.requerimiento = requerimiento;
    }

    @Input() // used to pass the parent when creating a new ArchivoAnexo
    set usuario(usuario : Usuario) {
      if(this.archivoAnexo == undefined){
        this.archivoAnexo = new ArchivoAnexo();
      }
      this.archivoAnexo.usuario = usuario;
    }

    @Input() // used to pass the parent when creating a new ArchivoAnexo
    set selectArchivoAnexo(archivoAnexo : ArchivoAnexo) {
      if(this.sub){
        this.archivoAnexo = archivoAnexo;
      }
    }

    @Output() onSaveClicked = new EventEmitter<ArchivoAnexo>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'fechaRegistro': [
    ],
      'archivoAnexoFileName': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'archivoAnexoContentType': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'archivoAnexoSize': [
    ],
      'archivoAnexoRuta': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'archivoAnexoBinary': [
    ],
      'borrado': [
    ],
      'archivo': [
      ],
      'requerimientoTipo': [
      ],
      'requerimiento': [
      ],
      'usuario': [
      ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private sanitizer: DomSanitizer, private archivoAnexoService: ArchivoAnexoService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.archivoAnexo == undefined && this.permisos.crear){
            this.archivoAnexo = new ArchivoAnexo();
            this.archivoAnexo.fechaRegistro = new Date();
            this.archivoAnexo.usuario = this.authService.globalUser;
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.archivoAnexo = new ArchivoAnexo();
            this.archivoAnexo.usuario = this.authService.globalUser;
            this.archivoAnexo.fechaRegistro = new Date();
            this.editContent = this.permisos.crear;
          } else {
            this.archivoAnexo = new ArchivoAnexo(await this.archivoAnexoService.getArchivoAnexo(id));
          }
          if(this.archivoAnexo.archivoAnexoFileName != undefined || this.archivoAnexo.archivoAnexoFileName != null){
            this.chooseValueArchivoAnexoBinary = this.archivoAnexo.archivoAnexoFileName
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("ArchivoAnexo"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'archivoAnexoKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.archivoAnexoForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.archivoAnexoForm.form.valid){
        this.messageService.clear();
        try{
          this.displayLoading = true;
          this.archivoAnexo = new ArchivoAnexo(await this.archivoAnexoService.update(this.archivoAnexo, this.archivoAnexoBinary.files[0]));
          this.removeFileArchivoAnexoBinary();
          this.guardo = true;

          if(!this.sub){
            this.displayLoading = false;
            this.messageService.success(this.translate.instant("ArchivoAnexo"), this.translate.instant("lblGuardo"));
            return this.archivoAnexo;
          }else{
            this.onSaveClicked.emit(this.archivoAnexo);
          }
        }catch(error){
          this.displayLoading = false;
          this.messageService.customError(this.translate.instant("ArchivoAnexo"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    downloadFormat(file, name){
      var fileURL = window.URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', name);
      tempLink.click();
    }
    downloadArchivoAnexoBinary(){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(this.archivoAnexo.archivoAnexoFileName);
      let http = this.archivoAnexoService.downloadArchivoAnexoBinary(this.archivoAnexo).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,this.archivoAnexo.archivoAnexoFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    selectFileArchivoAnexoBinary(fileTMP){
      this.fileSelectedArchivoAnexoBinary = true;
      this.chooseValueArchivoAnexoBinary = this.archivoAnexoBinary.files[0].name;
      if(this.archivoAnexoBinary.isImage(this.archivoAnexoBinary.files[0])){
        this.isImageArchivoAnexoBinary=true;
        this.objectURLArchivoAnexoBinary = this.sanitizer.bypassSecurityTrustUrl(fileTMP.currentFiles[0].objectURL.changingThisBreaksApplicationSecurity);
      }
    }

    removeFileArchivoAnexoBinary(){
      this.archivoAnexoBinary.clear(); 
      this.fileSelectedArchivoAnexoBinary = false; 
      this.isImageArchivoAnexoBinary = false;
      if(this.archivoAnexo.archivoAnexoFileName != undefined || this.archivoAnexo.archivoAnexoFileName != null){
        this.chooseValueArchivoAnexoBinary = this.archivoAnexo.archivoAnexoFileName;
      }else{
        this.chooseValueArchivoAnexoBinary = null;
      }
    }  
      
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
