//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/service/globalAudit.service.ts.p.vm
//

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { catchError, map } from 'rxjs/operators';
import { Usuario } from '../entities/usuario/usuario';

@Injectable()
export class GlobalAuditService {
    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient) {}

    getHistory(entidad:any) {
      let formData = new FormData();
      formData.append('entidadAudit', new Blob([JSON.stringify(entidad)],{type: "application/json"}));
      return this.http.post('/api/auditoriaSistemas/historia-registro', formData).toPromise();
    } 

    // sample method from angular doc
    private handleError (error: any) {
        let errMsg = (error.message) ? error.message :
        error.status ? `Status: ${error.status} - Text: ${error.statusText}` : 'Server error';
        console.error(errMsg); // log to console instead
        return null;//Observable.throw(errMsg);
    }
}
