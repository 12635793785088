// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .p-card .p-card-content {
  padding-top: 0 !important;
}

:host ::ng-deep .p-card .p-card-body {
  padding: 0.25rem !important;
}

:host ::ng-deep .p-badge {
  min-width: 1.2rem !important;
  height: 1.2rem !important;
  line-height: 1.3rem !important;
}

:host ::ng-deep .style-tab-etapas {
  background-color: #cbdfff !important;
}

.etapa-inactiva {
  background-color: #f9f9f9;
  font-size: 1.2rem;
  height: 100%;
}

.etapa-activa {
  background-color: #dddddd;
  font-size: 1.2rem;
  font-weight: bold;
  height: 120%;
}`, "",{"version":3,"sources":["webpack://./src/app/entities/requerimiento/requerimiento.component.scss"],"names":[],"mappings":"AACA;EACI,yBAAA;AAAJ;;AAGA;EACI,2BAAA;AAAJ;;AAGA;EACI,4BAAA;EACA,yBAAA;EACA,8BAAA;AAAJ;;AAGA;EACI,oCAAA;AAAJ;;AAIA;EACI,yBAAA;EACA,iBAAA;EAEA,YAAA;AAFJ;;AAKA;EACI,yBAAA;EACA,iBAAA;EACA,iBAAA;EACA,YAAA;AAFJ","sourcesContent":["\r\n:host ::ng-deep .p-card .p-card-content {\r\n    padding-top: 0 !important;\r\n}\r\n\r\n:host ::ng-deep  .p-card .p-card-body {\r\n    padding: 0.25rem !important;\r\n}\r\n\r\n:host ::ng-deep  .p-badge {\r\n    min-width: 1.2rem !important;\r\n    height: 1.2rem !important;\r\n    line-height: 1.3rem !important;\r\n}\r\n\r\n:host ::ng-deep .style-tab-etapas{\r\n    background-color: #cbdfff !important;\r\n\r\n}\r\n\r\n.etapa-inactiva{\r\n    background-color: #f9f9f9;\r\n    font-size: 1.2rem;\r\n    // font-weight: bold;\r\n    height: 100%;\r\n    // border: 1px solid;\r\n}\r\n.etapa-activa{\r\n    background-color: #dddddd;\r\n    font-size: 1.2rem;\r\n    font-weight: bold;\r\n    height: 120%;\r\n    // border: 1px solid;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
