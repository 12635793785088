//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { RequerimientoHistorico } from './requerimientoHistorico';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class RequerimientoHistoricoService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a RequerimientoHistorico by id.
     */
    getRequerimientoHistorico(id : any) {
        return this.http.get<RequerimientoHistorico>('/api/requerimientoHistoricos/' + id).toPromise();
    }

    /**
     * Update the passed requerimientoHistorico.
     */
    update(requerimientoHistorico : RequerimientoHistorico) {
        let body = JSON.stringify(requerimientoHistorico);
        return this.http.post<RequerimientoHistorico>('/api/requerimientoHistoricos/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of RequerimientoHistorico using the passed
     * requerimientoHistorico as an example for the search by example facility.
     */
     getPage(requerimientoHistorico : RequerimientoHistorico, event : LazyLoadEvent, columns, filterRangeDate?, filterRangeNumber?) {
        let req = new PageRequestByExample(requerimientoHistorico, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        if(filterRangeDate != undefined){
          formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
        }
        return this.http.post('/api/requerimientoHistoricos/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by RequerimientoHistoricoListComponent.
      */
    getCSV(requerimientoHistorico:RequerimientoHistorico, filterRangeDate?, filterRangeNumber?) {
      let body = JSON.stringify(requerimientoHistorico);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      if(filterRangeDate != undefined){
        formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
      }
      return this.http.post('/api/requerimientoHistoricos/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by RequerimientoHistoricoCompleteComponent.
     */
    complete(requerimientoHistorico : RequerimientoHistorico) {
        if(requerimientoHistorico == null){
          requerimientoHistorico = new RequerimientoHistorico();
        }
        let body = JSON.stringify(requerimientoHistorico);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<RequerimientoHistorico[]>('/api/requerimientoHistoricos/complete', formData).toPromise();
    }

    /**
     * Delete an RequerimientoHistorico by id.
     */
    delete(id : any) {
        return this.http.delete('/api/requerimientoHistoricos/' + id).toPromise();
    }
}
