//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";
import {Usuario} from '../usuario/usuario';
import {Contrato} from '../contrato/contrato';
import {RequerimientoTipo} from '../requerimientoTipo/requerimientoTipo';
import {Etapa} from '../etapa/etapa';
import {RequerimientoPrefijo} from '../requerimientoPrefijo/requerimientoPrefijo';
import {Empresa} from '../empresa/empresa';
import {Area} from '../area/area';
import { RequerimientoArchivo } from "../requerimientoArchivo/requerimientoArchivo";

export class Requerimiento {

    menuRuta = 'requerimiento-list';
    // Raw attributes
     id : number;
     folioEntrada : string;
     numeroRequerimiento : number;
     fechaIngreso : Date;
     fechaRequerimiento : Date;
     fechaRecepcion : Date;
     fechaRegistro : Date;
     fechaCierreParcial : Date;
     fechaCierrePropuesta : Date;
     fechaCierre : Date;
     fechaDespacho : Date;
     descripcion : string;
     asunto : string;
     revisaAl : boolean;
     revisaPr : boolean;
     finalizado : boolean;
     folioSalida : string;
     notaCierre : string;
     borrado : boolean;
    // x-to-one
    ingresadoPor : Usuario;
    contrato : Contrato;
    recinto : Contrato;
    asistente : Usuario;
    requerimientoTipo : RequerimientoTipo;
    coordinador : Usuario;
    etapa : Etapa;
    supervisor : Usuario;
    requerimientoPrefijo : RequerimientoPrefijo;
    empresaDestinataria : Empresa;
    area : Area;
    iteResponsableEP : Usuario;
    iteCorresponsableEP : Usuario;
    supervisorResponsableEP : Usuario;
    residenteOC : Usuario;
    // many-to-many
    usuarioConCopia : Usuario[];
    requerimientoArchivo : RequerimientoArchivo;

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            this.folioEntrada = json.folioEntrada;
            this.numeroRequerimiento = json.numeroRequerimiento;
            if (json.fechaIngreso != null) {
                this.fechaIngreso = new Date(json.fechaIngreso);
            }
            if (json.fechaRequerimiento != null) {
                this.fechaRequerimiento = new Date(json.fechaRequerimiento);
            }
            if (json.fechaRecepcion != null) {
                this.fechaRecepcion = new Date(json.fechaRecepcion);
            }
            if (json.fechaRegistro != null) {
                this.fechaRegistro = new Date(json.fechaRegistro);
            }
            if (json.fechaCierreParcial != null) {
                this.fechaCierreParcial = new Date(json.fechaCierreParcial);
            }
            if (json.fechaCierrePropuesta != null) {
                this.fechaCierrePropuesta = new Date(json.fechaCierrePropuesta);
            }
            if (json.fechaCierre != null) {
                this.fechaCierre = new Date(json.fechaCierre);
            }
            if (json.fechaDespacho != null) {
                this.fechaDespacho = new Date(json.fechaDespacho);
            }
            this.descripcion = json.descripcion;
            this.asunto = json.asunto;
            this.revisaAl = json.revisaAl;
            this.revisaPr = json.revisaPr;
            this.finalizado = json.finalizado;
            this.folioSalida = json.folioSalida;
            this.notaCierre = json.notaCierre;
            this.borrado = json.borrado;

            if (json.ingresadoPor != null) {
                this.ingresadoPor = new Usuario(json.ingresadoPor);
            }

            if (json.contrato != null) {
                this.contrato = new Contrato(json.contrato);
            }
            if (json.recinto != null) {
                this.recinto = new Contrato(json.recinto);
            }

            if (json.asistente != null) {
                this.asistente = new Usuario(json.asistente);
            }

            if (json.requerimientoTipo != null) {
                this.requerimientoTipo = new RequerimientoTipo(json.requerimientoTipo);
            }

            if (json.coordinador != null) {
                this.coordinador = new Usuario(json.coordinador);
            }

            if (json.etapa != null) {
                this.etapa = new Etapa(json.etapa);
            }

            if (json.supervisor != null) {
                this.supervisor = new Usuario(json.supervisor);
            }

            if (json.requerimientoPrefijo != null) {
                this.requerimientoPrefijo = new RequerimientoPrefijo(json.requerimientoPrefijo);
            }

            if (json.empresaDestinataria != null) {
                this.empresaDestinataria = new Empresa(json.empresaDestinataria);
            }

            if (json.area != null) {
                this.area = new Area(json.area);
            }

            if (json.usuarioConCopia != null) {
                this.usuarioConCopia = Usuario.toArray(json.usuarioConCopia);
            }
            if (json.requerimientoArchivo != null) {
                this.requerimientoArchivo = new RequerimientoArchivo(json.requerimientoArchivo);
            }

            if (json.iteResponsableEP != null) {
                this.iteResponsableEP = new Usuario(json.iteResponsableEP);
            }
            if (json.iteCorresponsableEP != null) {
                this.iteCorresponsableEP = new Usuario(json.iteCorresponsableEP);
            }
            if (json.supervisorResponsableEP != null) {
                this.supervisorResponsableEP = new Usuario(json.supervisorResponsableEP);
            }
            if (json.residenteOC != null) {
                this.residenteOC = new Usuario(json.residenteOC);
            }
        }
    }

    // Utils

    static toArray(jsons : any[]) : Requerimiento[] {
        let requerimientos : Requerimiento[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                requerimientos.push(new Requerimiento(json));
            }
        }
        return requerimientos;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(){
        // let auth = AppInjector.get(AuthService)
        // let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        // let code = auth.globalIdioma.code;
        // let concatString = '';
        // for (let col of allColumnsString) {
        //     if(col.codeLanguage == code || col.codeLanguage == 'all'){
        //         concatString += this[col.field]+' ';
        //     }
        // }
        // if(concatString){
        //     return concatString.replace(/null/g,'').trim();
        // }
        return this.asunto;
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
