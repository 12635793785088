//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";
import {Etapa} from '../etapa/etapa';
import {AccionEtapa} from '../accionEtapa/accionEtapa';
import {Requerimiento} from '../requerimiento/requerimiento';
import {Usuario} from '../usuario/usuario';

export class RequerimientoHistorico {

    menuRuta = 'requerimientoHistorico-list';
    // Raw attributes
     id : number;
     fechaRegistro : Date;
     detalle : string;
    // x-to-one
    etapa : Etapa;
    accion : AccionEtapa;
    requerimiento : Requerimiento;
    ejecutor : Usuario;

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            if (json.fechaRegistro != null) {
                this.fechaRegistro = new Date(json.fechaRegistro);
            }
            this.detalle = json.detalle;

            if (json.etapa != null) {
                this.etapa = new Etapa(json.etapa);
            }

            if (json.accion != null) {
                this.accion = new AccionEtapa(json.accion);
            }

            if (json.requerimiento != null) {
                this.requerimiento = new Requerimiento(json.requerimiento);
            }

            if (json.ejecutor != null) {
                this.ejecutor = new Usuario(json.ejecutor);
            }
        }
    }

    // Utils

    static toArray(jsons : any[]) : RequerimientoHistorico[] {
        let requerimientoHistoricos : RequerimientoHistorico[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                requerimientoHistoricos.push(new RequerimientoHistorico(json));
            }
        }
        return requerimientoHistoricos;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(){
        let auth = AppInjector.get(AuthService)
        let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        let code = auth.globalIdioma.code;
        let concatString = '';
        for (let col of allColumnsString) {
            if(col.codeLanguage == code || col.codeLanguage == 'all'){
                concatString += this[col.field]+' ';
            }
        }
        if(concatString){
            return concatString.replace(/null/g,'').trim();
        }
        return '';
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
