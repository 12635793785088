//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { Role } from './role';
import { RoleService } from './role.service';
import { TranslateService } from '@ngx-translate/core';
import { Menu } from '../menu/menu';
import { MenuService } from '../menu/menu.service';
import { Funcionalidad } from '../funcionalidad/funcionalidad';
import { FuncionalidadService } from '../funcionalidad/funcionalidad.service';

@Component({
	templateUrl: 'role-detail.component.html',
	selector: 'role-detail',
})
export class RoleDetailComponent implements OnInit{

    @ViewChild('roleForm', {static: false}) roleForm:any;
    role : Role;

    private params_subscription: any;
    guardo: boolean = false;
    loading: boolean = false;
    sourceMenu : Menu[] = [];
    sourceFuncionalidad : Funcionalidad[] = [];
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_role';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new Role
    set selectRole(role : Role) {
      if(this.sub){
        this.role = role;
      }
    }

    @Output() onSaveClicked = new EventEmitter<Role>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'roleName': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'descripcion': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'borrado': [
    ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private roleService: RoleService, private menuService : MenuService, private funcionalidadService : FuncionalidadService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.role == undefined && this.permisos.crear){
            this.role = new Role();
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.role = new Role();
            this.role.menus = [];
            this.role.funcionalidads = [];
            this.editContent = this.permisos.crear;
          } else {
            this.role = new Role(await this.roleService.getRole(id));
            this.sourceMenu = this.role.menus;
            this.sourceFuncionalidad = this.role.funcionalidads;
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("Role"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'roleKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.roleForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.roleForm.form.valid){
        this.messageService.clear();
        try{
          this.role = new Role(await this.roleService.update(this.role));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("Role"), this.translate.instant("lblGuardo"));
            return this.role;
          }else{
            this.onSaveClicked.emit(this.role);
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("Role"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    async getMenus(){
      this.loading = true;
      this.sourceMenu= await this.menuService.complete(null);
      this.loading = false;
    }
    async getFuncionalidads(){
      this.loading = true;
      this.sourceFuncionalidad= await this.funcionalidadService.complete(null);
      this.loading = false;
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
