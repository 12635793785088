//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-list.component.ts.e.vm
//
import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges, OnInit } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { PageResponse } from '../../support/paging';
import { MsgService } from '../../service/message.service';
import { GlobalAuditService } from '../../service/globalAudit.service';
import { Comuna } from './comuna';
import { ComunaDetailComponent } from './comuna-detail.component';
import { ComunaService } from './comuna.service';
import { AuthService } from '../../service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { RolePermisoEntidad } from '../rolePermisoEntidad/rolePermisoEntidad';
import { Provincia } from '../provincia/provincia';
import { ProvinciaLineComponent } from '../provincia/provincia-line.component';
@Component({
	templateUrl: 'comuna-list.component.html',
	selector: 'comuna-list'
})
export class ComunaListComponent implements OnInit{
    @Input() header = "tltComuna";
    @Input() padre = ""; //nombre del padre que se pasa por parametro para el header de la tabla y poder aplicar el translate

    // When 'sub' is true, it means this list is used as a one-to-many list.
    // It belongs to a parent entity, as a result the addNew operation
    // must prefill the parent entity. The prefill is not done here, instead we
    // emit an event.
    // When 'sub' is false, we display basic search criterias
    @Input() sub : boolean;
    @Output() onAddNewClicked = new EventEmitter();
    @Output() onRowSelectSub = new EventEmitter();
    permisos:any = new RolePermisoEntidad();
    keyEntity = 'key_comuna';
    key_sessionStorage = 'stateSessionComuna-list';
    optionsColumns: any;
    originalColumns:any;
    cols:any [] = [];
    menuRuta: any = 'comuna-list';
    comunaToDelete : Comuna;
    comunaHistory : any;
    showDialog: boolean = false;
    loading: boolean = true;
    activeFilter : boolean = true;
    editColumnComuna:boolean = false;
    // basic search criterias (visible if not in 'sub' mode)
    example : Comuna = new Comuna();
    rangeNumber:any={};
    filterRangeNumber:any={};
    sortOrder:any;
    sortField:any;
    // list is paginated
    currentPage : PageResponse<Comuna> = new PageResponse<Comuna>(0,0,[]);

    // X to one: input param is used to filter the list when displayed
    // as a one-to-many list by the other side.
    private _provincia : Provincia;

    constructor(private router : Router,
        private comunaService : ComunaService,
        private messageService : MsgService,
        private globalAudit : GlobalAuditService,
        private authService : AuthService,
        private translate: TranslateService,
        private confirmationService: ConfirmationService) {
    }

    //carga inicial de las columnas de la tabla segun usuario conectado o por default
    async ngOnInit(){
      let filters = JSON.parse(sessionStorage.getItem(this.key_sessionStorage));
      this.cols = [];
      if(filters){
        this.example = filters.example;
        this.sortField = filters.sortField;
        this.sortOrder = filters.sortOrder;
        for(let n of Object.keys(filters.filterRangeNumber)){
          this.rangeNumber[n+"_desde"] = filters.filterRangeNumber[n][0];
          this.rangeNumber[n+"_hasta"] = filters.filterRangeNumber[n][1];
          this.filterRangeNumber[n] = filters.filterRangeNumber[n];
        }
      }
      this.authService.getPermisosPorEntidad(this.keyEntity).then(resp=>{this.permisos = resp});
      if(this.authService.globalColumns == undefined){
        await this.authService.setGlobalColumnsfromDB();
      }
      this.originalColumns = this.authService.globalColumns[this.menuRuta];           
      this.optionsColumns = this.originalColumns.filter(col => (col.codeLanguage == this.authService.globalIdioma.code || (col.codeLanguage == undefined || col.codeLanguage == "all")) && col.delete == false);
      for(let cl of this.optionsColumns){
        if(cl.default){
          this.cols.push(cl);
        }
      }
    }
    /**
     * When used as a 'sub' component (to display one-to-many list), refreshes the table
     * content when the input changes.
     */
    ngOnChanges(changes: SimpleChanges) {
        //this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
    }
    viewFilter(){
      if(this.activeFilter)
        this.activeFilter=false;
      else
        this.activeFilter=true;
    }
    clean(dt : any){
      //dt.reset();
      this.example = new Comuna();
      // evita que se pierda el filtro de los input (padres) cuandos es sub
      this.example.provincia = this._provincia;
      this.filterRangeNumber = {}
      this.rangeNumber = {}
      dt.filters = {};
      sessionStorage.removeItem(this.key_sessionStorage);
      dt.reset();
      this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
    }
    
    //Actualiza la tabla manteniendo orden, filtro y página
    reload(){
      let tmp: any = JSON.parse(sessionStorage.getItem(this.key_sessionStorage));
      let sortField:any = tmp?.sortField?tmp.sortField:null;
      let sortOrder:any = tmp?.sortOrder?tmp.sortOrder:null;
      let filters:any = tmp?.filters?tmp.filters:null;      
      this.ngOnInit();
      this.loadPage({ first: 0, rows: 10, sortField: sortField, sortOrder: sortOrder, filters: filters, multiSortMeta: null });
    }

    /**
     * Invoked when user presses the search button.
     */
    /*search(dt : any) {
      dt.reset();
      this.loadPage({ first: 0, rows: dt.rows, sortField: dt.sortField, sortOrder: dt.sortOrder, filters: null, multiSortMeta: dt.multiSortMeta });
    }*/

    /**
     * Invoked automatically by primeng datatable.
     */
    async loadPage(event : LazyLoadEvent) {
      this.loading = true;
      try {
        //this.currentPage = new PageResponse<Comuna>(0,0,[]);
        let page:any = await this.comunaService.getPage(this.example, event, this.cols, this.filterRangeNumber);
        this.currentPage = new PageResponse<Comuna>(page.totalPages, page.totalElements, Comuna.toArray(page.content));
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.messageService.customError(this.translate.instant("Comuna"),this.translate.instant("lblErrorNgOnInit"), error);
      }
    }

    //Registra los atributos al Session Storage para mantener los filtros en la sesión
    setFilterTable(dt){       
      let filters = {
        'example':this.example,
        'filterRangeNumber':this.filterRangeNumber,
        'sortOrder':dt.sortOrder,
        'sortField':dt.sortField
      }
      sessionStorage.setItem(this.key_sessionStorage,JSON.stringify(filters))
      this.loadPage({ first: 0, rows: dt.rows, sortField: dt.sortField, sortOrder: dt.sortOrder, filters: null, multiSortMeta: dt.multiSortMeta });      
    }

    // evita que los auto complete recargen toda la tabla al hacer click en el despliegue
    controlFiltros(attr, table){
      if(attr != null){
        this.setFilterTable(table);
      }
    }

    setFilterRangeNumber(field, value, posicion){
      if(this.filterRangeNumber[field] == null){
        this.filterRangeNumber[field] = [null,null]
      }       
      this.filterRangeNumber[field][posicion] = value;
    }

    //guarda la seleccion de columnas para perdurar en proximas conexiones del usuario
    async saveSelectedColumnsList(){ 
      if(this.editColumnComuna){        
        this.originalColumns.filter(org => {
          org.default = false;
          for(let col of this.cols){
              if(org.id == col.id){
                  org.default = true;
              }
          }
        });   
        for(let col of this.cols){
        this.originalColumns.map((r,i) =>{   
          if(this.authService.globalIdioma.code!='es'){  
            if((r.codeLanguage!=this.authService.globalIdioma.code || r.codeLanguage!='all') && col.field.substring(0, col.field.length-2) == r.field){
              this.originalColumns[i].default = true;
            }
          }else{
            if((r.codeLanguage!=this.authService.globalIdioma.code || r.codeLanguage!='all') && r.field.substring(0, r.field.length-2) == col.field){
              this.originalColumns[i].default = true;
            }
          }
        
        });
      }              
        await this.authService.saveColumnsByRuta(this.originalColumns,this.menuRuta);
        await this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
        this.editColumnComuna = false;
      }
    }
    //guarda los anchos de las columnas por usuario
    async saveWidthColumnsList(e){
      let newWidth = (parseFloat(e.element.attributes.style.value.match(/[\d\.]+/))/16).toString() + "em";
      let idResize = e.element.attributes[1].value;
      this.originalColumns.filter(org => {
        for(let col of this.cols){
            if(org.id == idResize){
              org.width = newWidth;
            }
        }
      });  
      await this.authService.saveColumnsByRuta(this.originalColumns,this.menuRuta);
    }
    async getCSV(){
      try {
          let csv : any = await this.comunaService.getCSV(this.example, this.filterRangeNumber);
          var csvData = new Blob(["\ufeff"+csv], {type: 'text/csv;charset=utf-8;'});
          var csvURL = window.URL.createObjectURL(csvData);
          var tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', this.translate.instant("Comuna")+'.csv');
          tempLink.click();
      } catch (error) {
          this.messageService.customError(this.translate.instant("Comuna"),this.translate.instant("lblErrorCSV"), error)
      }
    }


    // X to one: input param is used to filter the list when displayed
    // as a one-to-many list by the other side.
    @Input()
    set provincia(provincia : Provincia) {
        if (provincia == null) {
            return;
        }
        this._provincia = provincia;
        if(this.example == null){
            this.example = new Comuna();
        }
        this.example.provincia = new Provincia();
        this.example.provincia.id = this._provincia.id;
    }


    onRowSelect(event : any) {
        if (this.sub){
            this.onRowSelectSub.emit(event.data);
        } else {
            let id =  event.data.id;
            this.router.navigate(['/comuna', id]);
        }
    }

    addNew() {
        if (this.sub) {
            this.onAddNewClicked.emit("addNew");
        } else {
            this.router.navigate(['/comuna', 'new']);
        }
    }

    showDeleteDialog(rowData : any, event: any) {
        let comunaToDelete : Comuna = <Comuna> rowData;

        this.confirmationService.confirm({
            target: event.target,
            message: this.translate.instant("lblPreguntaDelete"),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.delete(comunaToDelete);
            },
            reject: () => {
                //reject action
            }
        });
    }

    async historico(comunaToHistory : Comuna){
      let entidad:any = {"entidad":"Comuna","id":comunaToHistory.id};
      try{
        this.comunaHistory = await this.globalAudit.getHistory(entidad);
        this.showDialog = true;
      }catch(error){
        this.messageService.customError("Comuna:", this.translate.instant("lblProblemaObtenerHistorico"), error);
      }
    }    
    
    private async delete(comunaToDelete : Comuna) {
        let id =  comunaToDelete.id;
        try {
          await this.comunaService.delete(id);
          this.currentPage.remove(comunaToDelete);
          this.messageService.info("Comuna:", this.translate.instant("lblRegistroEliminado"));
        } catch (error) {
          this.messageService.customError("Comuna:", this.translate.instant("lblNoPudoEliminar"), error);
        }
    }
}
