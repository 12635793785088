//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { EmpresaTipo } from './empresaTipo';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class EmpresaTipoService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a EmpresaTipo by id.
     */
    getEmpresaTipo(id : any) {
        return this.http.get<EmpresaTipo>('/api/empresaTipos/' + id).toPromise();
    }

    /**
     * Update the passed empresaTipo.
     */
    update(empresaTipo : EmpresaTipo) {
        let body = JSON.stringify(empresaTipo);
        return this.http.post<EmpresaTipo>('/api/empresaTipos/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of EmpresaTipo using the passed
     * empresaTipo as an example for the search by example facility.
     */
     getPage(empresaTipo : EmpresaTipo, event : LazyLoadEvent, columns) {
        let req = new PageRequestByExample(empresaTipo, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        return this.http.post('/api/empresaTipos/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by EmpresaTipoListComponent.
      */
    getCSV(empresaTipo:EmpresaTipo) {
      let body = JSON.stringify(empresaTipo);
      return this.http.post<any>('/api/empresaTipos/csv', body, this.options).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by EmpresaTipoCompleteComponent.
     */
    complete(empresaTipo : EmpresaTipo) {
        if(empresaTipo == null){
          empresaTipo = new EmpresaTipo();
        }
        let body = JSON.stringify(empresaTipo);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<EmpresaTipo[]>('/api/empresaTipos/complete', formData).toPromise();
    }

    /**
     * Delete an EmpresaTipo by id.
     */
    delete(id : any) {
        return this.http.delete('/api/empresaTipos/' + id).toPromise();
    }
}
