//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";

export class AuditoriaSistema {

    menuRuta = 'auditoriaSistema-list';
    // Raw attributes
     id : number;
     fecha : Date;
     usuario : string;
     accion : string;
     dataOld : string;
     dataNew : string;
     tabla : string;
     entidad : string;
     entidadId : number;
     entidadIdNombre : string;
     entidadId2 : number;
     entidadIdNombre2 : string;
     entidadId3 : number;
     entidadIdNombre3 : string;
     cambios : string;

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            if (json.fecha != null) {
                this.fecha = new Date(json.fecha);
            }
            this.usuario = json.usuario;
            this.accion = json.accion;
            this.dataOld = json.dataOld;
            this.dataNew = json.dataNew;
            this.tabla = json.tabla;
            this.entidad = json.entidad;
            this.entidadId = json.entidadId;
            this.entidadIdNombre = json.entidadIdNombre;
            this.entidadId2 = json.entidadId2;
            this.entidadIdNombre2 = json.entidadIdNombre2;
            this.entidadId3 = json.entidadId3;
            this.entidadIdNombre3 = json.entidadIdNombre3;
            this.cambios = json.cambios;
        }
    }

    // Utils

    static toArray(jsons : any[]) : AuditoriaSistema[] {
        let auditoriaSistemas : AuditoriaSistema[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                auditoriaSistemas.push(new AuditoriaSistema(json));
            }
        }
        return auditoriaSistemas;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(){
        let auth = AppInjector.get(AuthService)
        let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        let code = auth.globalIdioma.code;
        let concatString = '';
        for (let col of allColumnsString) {
            if(col.codeLanguage == code || col.codeLanguage == 'all'){
                concatString += this[col.field]+' ';
            }
        }
        if(concatString){
            return concatString.replace(/null/g,'').trim();
        }
        return '';
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
