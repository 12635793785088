//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-line.component.ts.e.vm
//
import {Component, Input} from '@angular/core';
import {RequerimientoArchivo} from './requerimientoArchivo';

@Component({
	template: `
	<ng-container *ngIf="detalleReq else simple">
	{{requerimientoArchivo.requerimiento?.contrato.nombre}} /
	N° Req. {{requerimientoArchivo.requerimiento?.numeroRequerimiento}} /
	{{requerimientoArchivo.requerimiento?.asunto}} /
	{{requerimientoArchivo?.archivoFileName}} /
	{{requerimientoArchivo?.requerimiento.fechaCierre | date:'dd-MM-yyyy HH:mm'}}
	</ng-container>
	<ng-template #simple>
	{{requerimientoArchivo?.archivoFileName}} 
	</ng-template>
	`,
	selector: 'requerimientoArchivo-line',
})
export class RequerimientoArchivoLineComponent {
    @Input() requerimientoArchivo : RequerimientoArchivo;
	@Input() detalleReq : boolean;
}



