//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/support/srcLink.pipe.ts.p.vm
//
import { Pipe, PipeTransform } from "@angular/core";
import { HttpClient, HttpHeaders, HttpRequest, } from '@angular/common/http';

@Pipe({
  name: "srcLink"
})
export class SrcLink implements PipeTransform {
  constructor(private http: HttpClient){

  }
  async transform(src: string): Promise<string> {
    const fileBlob = await this.http.get(src, {responseType: 'blob'}).toPromise();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(fileBlob);
    });
  }
}
