//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-line.component.ts.e.vm
//
import {Component, Input} from '@angular/core';
import {Manuales} from './manuales';

@Component({
	template: `
{{ manuales?.titulo }} 
{{ manuales?.ruta }} 
	`,
	selector: 'manuales-line',
})
export class ManualesLineComponent {
    @Input() manuales : Manuales;
}



