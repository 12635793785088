//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";
import {EmpresaTipo} from '../empresaTipo/empresaTipo';
import {Contrato} from '../contrato/contrato';

export class Empresa {

    menuRuta = 'empresa-list';
    // Raw attributes
     id : number;
     nombre : string;
     rut : number;
     dv : string;
     correoElectronico : string;
     direccion : string;
     telefono : string;
     telefonoAnexo : string;
     codigo : string;
     giroPrincipal : string;
     responsableTecnico : string;
     responsableTecnicoCorreo : string;
     responsableTecnicoTelefono : string;
     responsableTecnicoTelefonoAnexo : string;
     responsableAdministrativo : string;
     responsableAdministrativoCorreo : string;
     responsableAdministratioTelefono : string;
     responsableAdministrativoTelefonoAnexo : string;
     interna : boolean;
     borrado : boolean;
    // x-to-one
    tipoEmpresa : EmpresaTipo;
    contrato : Contrato;

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            this.nombre = json.nombre;
            this.rut = json.rut;
            this.dv = json.dv;
            this.correoElectronico = json.correoElectronico;
            this.direccion = json.direccion;
            this.telefono = json.telefono;
            this.telefonoAnexo = json.telefonoAnexo;
            this.codigo = json.codigo;
            this.giroPrincipal = json.giroPrincipal;
            this.responsableTecnico = json.responsableTecnico;
            this.responsableTecnicoCorreo = json.responsableTecnicoCorreo;
            this.responsableTecnicoTelefono = json.responsableTecnicoTelefono;
            this.responsableTecnicoTelefonoAnexo = json.responsableTecnicoTelefonoAnexo;
            this.responsableAdministrativo = json.responsableAdministrativo;
            this.responsableAdministrativoCorreo = json.responsableAdministrativoCorreo;
            this.responsableAdministratioTelefono = json.responsableAdministratioTelefono;
            this.responsableAdministrativoTelefonoAnexo = json.responsableAdministrativoTelefonoAnexo;
            this.interna = json.interna;
            this.borrado = json.borrado;

            if (json.tipoEmpresa != null) {
                this.tipoEmpresa = new EmpresaTipo(json.tipoEmpresa);
            }

            if (json.contrato != null) {
                this.contrato = new Contrato(json.contrato);
            }
        }
    }

    // Utils

    static toArray(jsons : any[]) : Empresa[] {
        let empresas : Empresa[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                empresas.push(new Empresa(json));
            }
        }
        return empresas;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(){
        let auth = AppInjector.get(AuthService)
        let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        let code = auth.globalIdioma.code;
        let concatString = '';
        for (let col of allColumnsString) {
            if(col.codeLanguage == code || col.codeLanguage == 'all'){
                concatString += this[col.field]+' ';
            }
        }
        if(concatString){
            return concatString.replace(/null/g,'').trim();
        }
        return '';
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
