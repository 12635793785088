//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/support/privilegios-etapa.pipe.ts.p.vm
//

import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from "app/service/auth.service";
//import { HttpClient, HttpHeaders, HttpRequest, } from '@angular/common/http';

@Pipe({
  name: "privilegiosEtapa"
})
export class PrivilegiosEtapa implements PipeTransform {
  constructor(private authService: AuthService){

  }
/*
  Etapa  = idEtapa (number)
  editElement = nombre del elemento a editar (String)
  permisos = privilegios del usuario sobre la etapa <'puedeAutorizar', 'puedeCrear', etc> (String Array)
*/
  transform(etapa, editElement): boolean {
  let funcionalidades = this.authService.globalFuncionalidades;
      //console.log("🚀 ~ file: privilegios-etapa.pipe.ts:27 ~ PrivilegiosEtapa ~ transform ~ funcionalidades:", funcionalidades)
      let listEtapa_1_13 = [
        'formularioEtapas'
        , 'btnGuardar'
        , 'btnSecreEnvia_JA' //se aplica solo para rol Secretaria
        , 'btnSecreEnvia_IF' //se aplica solo para rol Secretaria
        , 'btnJACreaReq' //se aplica solo para rol JA
        , 'btnJAEnviaInstruccion_IF' //se aplica solo para rol JA
        , 'autoRecPrefijo'
        , 'autoRecinto'
        , 'inpFolioEntrada'
        , 'autoRecTipo'
        , 'inpAsunto'
        , 'inpFechaRecepcion'
        , 'inpDescripcion'
        , 'tabComentario'
        , 'tabArchivo'
      ]

      let listEtapa_2 = [
        'formularioEtapas'
        , 'btnGuardar'
        , 'btnJAAnulaReq'
        , 'btnJACerradoForzoso'
        , 'btnJAEnvia_Coordinador'
        , 'autoRecTipo'
        , 'autoArea'
        , 'autoEmpresaDestinataria'
        , 'chkRevisaAL'
        , 'chkRevisaPR'
        , 'multiConCopia'
        , 'autoCoordinador'
        //, 'autoSupervisor'
        // , 'autoAsistente'
        , 'inpFechaCierrePropuesta'
        , 'tabComentario'
        , 'tabArchivo'
        , 'tabAntecedente'
        , 'btnJAEnvia_ITE'
        , 'autoITEResponsableEP'
      ]

      let listEtapa_3 = [
        'formularioEtapas'
        , 'btnGuardar'
        , 'btnCoordinadorEnvia_JA'
        , 'btnCoordinadorEnvia_Supervisor'
        //, 'autoEmpresaDestinataria'
        , 'autoSupervisor'
        // , 'autoAsistente'        
        , 'tabComentario'
        , 'tabArchivo'
        , 'multiConCopia'
      ]

      let listEtapa_5_15_16_JA = [
        'btnJAAnulaReq'
        , 'btnJACerradoForzoso'
      ]

      let listEtapa_3_JA = [
        'btnJAAnulaReq'
        , 'btnJACerradoForzoso'
        ,'btnJAReasigna_Analista'
      ]

      let listEtapa_4 = [
        'formularioEtapas'
        , 'btnJAEnvia_IF_e4'
        , 'btnJARechaza_Analista'        
        , 'btnJAEnvia_AL_e4' //btn exclusivo etapa 4
        , 'btnJAEnvia_PR'
        , 'btnJAApruebaCierre'
        , 'btnGuardar'
        , 'btnJAAnulaReq'
        , 'btnJACerradoForzoso'
        //, 'inpFechaCierrePropuesta'
        , 'autoEmpresaDestinataria'
        , 'autoRecTipo'
        , 'autoArea'        
        , 'tabComentario'
        , 'tabArchivo'
        , 'btnJARechaza_Coordinador'
        , 'btnJAApruebaRotulacion'
      ]

      let listEtapa_28 = [ //rotulacion
        'formularioEtapas'        
        , 'btnGuardar'    
        , 'btnSEEnvia_IF'  
        , 'inpFolioSalida'  
        , 'tabComentario'
        , 'tabArchivo'
      ]

      let listEtapa_5 = [
        'formularioEtapas'
        , 'btnALEnvia_JA'
        , 'btnGuardar'       
        , 'tabComentario'
        , 'tabArchivo'
      ]

      let listEtapa_18 = [
        'formularioEtapas'
        , 'btnPREnvia_JA'
        , 'btnGuardar'       
        , 'tabComentario'
        , 'tabArchivo'
      ]

      let listEtapa_6 = [
        'formularioEtapas'
        , 'btnIFAprueba_JA'
        , 'btnIFRechaza_JA'
        , 'btnGuardar'       
        , 'tabComentario'
        , 'tabArchivo'
      ]

      let listEtapa_7 = [
        'formularioEtapas'
        //, 'btnGuardar'
        , 'inpFolioSalida'
        , 'inpFechaDespacho'
        , 'inpNotaCierre'
        , 'docTipoReqSalida'
        , 'btnSecreAdjuntaCierraReq'
      ]

      let listEtapa_8 = [
        'formularioEtapas'
        , 'btnGuardar'
        , 'btnJAEnvia_IF_e8'
        , 'btnJARechaza_AL'
        , 'autoEmpresaDestinataria'
        , 'autoRecTipo'
        , 'autoArea'
        , 'tabComentario'
        , 'tabArchivo'
      ]

      let listEtapa_19 = [
        'formularioEtapas'
        , 'btnGuardar'        
        , 'btnJARechaza_PR'
        , 'btnJAEnvia_AL_e19' //btn exclusivo etapa 19
        , 'btnJAEnvia_IF_e19'
        , 'autoEmpresaDestinataria'
        , 'autoRecTipo'
        , 'autoArea'
        , 'tabComentario'
        , 'tabArchivo'
      ]

      let listEtapa_12 = [
        'formularioEtapas'
        , 'btnGuardar'
        , 'btnIFInstruye_JA'
        , 'btnIFDevuelve_Secre'
        , 'tabInstruccion' 
        , 'tabComentario'
        , 'tabArchivo'  
      ]

      let listEtapa_15 = [
        'formularioEtapas'
        , 'btnGuardar'
        , 'btnSupervisorEnvia_Coordinador'
        // , 'btnSupervisorEnvia_Asistente'                 
        // , 'autoAsistente'
        //, 'autoEmpresaDestinataria'
        , 'tabComentario'
        , 'tabArchivo'  
      ]

      let listEtapa_16 = [
        'formularioEtapas'
        , 'btnGuardar'
        // , 'btnAsistenteEnvia_Supervisor'                        
        , 'autoEmpresaDestinataria'          
        , 'tabComentario'
        , 'tabArchivo'  
      ]
      let listEtapaEP_1_13 = [
        'formularioEtapas'
        , 'btnGuardar'
        , 'btnSecreEnvia_ITE' //se aplica solo para rol Secretaria
        //, 'btnSecreEnvia_IF' //se aplica solo para rol Secretaria
        , 'btnITECreaReq' //se aplica solo para rol JA
        //, 'btnJAEnviaInstruccion_IF' //se aplica solo para rol JA
        , 'autoRecPrefijo'
        , 'autoArea'
        , 'inpFolioEntrada'
        , 'autoRecTipo'
        , 'inpAsunto'
        , 'inpFechaRecepcion'
        , 'inpDescripcion'
        , 'tabComentario'
        , 'tabArchivo'       
      ];
      let listEtapaEP_2 = [
        'formularioEtapas'
        , 'btnGuardar'
        , 'btnITEAnulaReq'
        , 'btnITECerradoForzoso'
        //, 'btnJAEnvia_Coordinador'
        , 'btnITEEnvia_Supervisor'
        , 'autoRecTipo'
        //, 'autoArea'
        , 'autoEmpresaDestinataria'
        //, 'chkRevisaAL'
        //, 'chkRevisaPR'
        , 'multiConCopia'
        , 'autoSupervisorEP'
        , 'autoITEResponsableEP'
        , 'autoITECorresponsableEP'
        //, 'autoSupervisor'
        // , 'autoAsistente'
        , 'inpFechaCierrePropuesta'
        , 'tabComentario'
        , 'tabArchivo'
        , 'tabAntecedente'
        , 'btnIteCorresponsableEnvia_Responsable'
        , 'btnIteResposableEnvia_Corresponsable'
      ]
      let listEtapaEP_20 = [      //análisis Supervisor EP
        'formularioEtapas'
        , 'btnGuardar'
        , 'btnSupervisorEPEnvia_ITE'
        //, 'btnCoordinadorEnvia_Supervisor'
        //, 'autoEmpresaDestinataria'
        //, 'autoSupervisor'
        // , 'autoAsistente'        
        , 'tabComentario'
        , 'tabArchivo'
        //, 'multiConCopia'
      ]

      let listEtapaEP_20_ITE = [
        'btnITEAnulaReq'
        , 'btnITECerradoForzoso'
      ]

      let listEtapaEP_21 = [
        'formularioEtapas'
        , 'btnITEEnvia_ResidenteOC'   //btnJAEnvia_IF_e4
        , 'btnITERegresa_ResidenteOC'
        , 'btnITERechaza_Supervisor'    //btnJARechaza_Analista
        , 'btnITEReasigna_Supervisor'   //btnJAReasigna_Analista
        //, 'btnJAEnvia_AL_e4' //btn exclusivo etapa 4
        //, 'btnJAEnvia_PR'
        , 'btnGuardar'
        , 'btnITEAnulaReq'            //btnJAAnulaReq
        , 'btnITECerradoForzoso'      //btnJACerradoForzoso
        //, 'inpFechaCierrePropuesta'
        //, 'autoEmpresaDestinataria'
        //, 'autoRecTipo'
        //, 'autoArea'        
        , 'tabComentario'
        , 'tabArchivo'
        //btnJARechaza_Coordinador
        , 'btnIteCorresponsableEnvia_Responsable'
        , 'btnIteResposableEnvia_Corresponsable'
      ]

      let listEtapaEP_22 = [
        'formularioEtapas'
        , 'btnResidenteOCAprueba_ITE'     //, 'btnIFAprueba_JA'
        , 'btnResidenteOCRechaza_ITE'     //, 'btnIFRechaza_JA'
        , 'btnResidenteOCEnvia_OC'
        , 'btnGuardar'       
        , 'tabComentario'
        , 'tabArchivo'
      ]

      let listEtapaEP_26 = [
        'formularioEtapas'
        , 'btnITERegresa_ResidenteOC'  
        , 'autoSupervisorEP'
        , 'btnGuardar'
        , 'btnITEEnvia_Supervisor'      
        , 'tabComentario'
        , 'tabArchivo'
      ]

      if(this.authService.globalContrato.contratoTipo.id != 3){ //Contrato tipo "Oficina Central (OC)"
          if(
            (etapa == 1 || etapa == 13)  // Ingreso - Creación de la secretaria
            && funcionalidades.findIndex(f => {return f.id == 1} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 4} ) > -1
            && (listEtapa_1_13.includes(editElement))
          ){
            return true;
          }else if(
            (etapa == 2 || etapa == 27) // Asignacion - Jefe de asesoria
            && funcionalidades.findIndex(f => {return f.id == 6} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 7} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 5} ) > -1 
            && (listEtapa_2.includes(editElement))
          ){
            return true;
          }else if(etapa == 3){ // Analisis coordinador - Analistas (Coordinador - Supervisor - Asistente) / JA puede cerrar y anular
              
              if(funcionalidades.findIndex(f => {return f.id == 2} ) > -1  && funcionalidades.findIndex(f => {return f.id == 3} ) > -1 && listEtapa_3.includes(editElement)){
                return true
              }else if (funcionalidades.findIndex(f => {return f.id == 6} ) > -1  && funcionalidades.findIndex(f => {return f.id == 7} ) > -1 && funcionalidades.findIndex(f => {return f.id == 11} ) > -1 && listEtapa_3_JA.includes(editElement)) {
                return true
              }  

          }else if(
            (etapa == 4) // Validación JA para analista - Jefe de asesoria
            && funcionalidades.findIndex(f => {return f.id == 6} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 7} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 8} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 10} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 27} ) > -1 
            //&& funcionalidades.findIndex(f => {return f.id == 11} ) > -1 
            && (listEtapa_4.includes(editElement))
          ){
            return true;
          }else if(etapa == 18){ // Analisis Prev Riesgos

            if(funcionalidades.findIndex(f => {return f.id == 21} ) > -1 && listEtapa_18.includes(editElement)){
              return true
            }else if (funcionalidades.findIndex(f => {return f.id == 6} ) > -1  && funcionalidades.findIndex(f => {return f.id == 7} ) > -1 && listEtapa_5_15_16_JA.includes(editElement)) {
              return true
            }               

          }else if(etapa == 5){ // Analisis AL

            if(funcionalidades.findIndex(f => {return f.id == 16} ) > -1 && listEtapa_5.includes(editElement)){
              return true
            }else if (funcionalidades.findIndex(f => {return f.id == 6} ) > -1  && funcionalidades.findIndex(f => {return f.id == 7} ) > -1 && listEtapa_5_15_16_JA.includes(editElement)) {
              return true
            }               

          }else if(
            (etapa == 28) // Validación IF para JA - Inspector fiscal
            && funcionalidades.findIndex(f => {return f.id == 28} ) > -1 
            && (listEtapa_28.includes(editElement))
          ){
            return true;
          }else if(
            (etapa == 6) // Validación IF para JA - Inspector fiscal
            && funcionalidades.findIndex(f => {return f.id == 13} ) > -1 
            && (listEtapa_6.includes(editElement))
          ){
            return true;
          }else if(
            (etapa == 7) // Pendiente de cierre (adjuntar doc firmado del IF) - Secretaria
            && funcionalidades.findIndex(f => {return f.id == 14} ) > -1 
            && (listEtapa_7.includes(editElement))
          ){
            return true;
          }else if(
            (etapa == 19) // Validacion JA para PR - JA
            && funcionalidades.findIndex(f => {return f.id == 20} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 22} ) > -1 
            && (listEtapa_19.includes(editElement))
          ){
            return true;
          }else if(
            (etapa == 8) // Validacion JA para AL - JA
            && funcionalidades.findIndex(f => {return f.id == 12} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 8} ) > -1 
            && (listEtapa_8.includes(editElement))
          ){
            return true;
          }else if(
            (etapa == 12) // Pendiente de cierre (adjuntar doc firmado del IF) - Secretaria
            && funcionalidades.findIndex(f => {return f.id == 15} ) > -1 
            && (listEtapa_12.includes(editElement))
          ){
            return true;
          }else if(etapa == 15){ // Analisis supervisor - Analistas (Coordinador - Supervisor - Asistente) / JA puede cerrar y anular

            if(funcionalidades.findIndex(f => {return f.id == 3} ) > -1 && listEtapa_15.includes(editElement)){
              return true
            }else if (funcionalidades.findIndex(f => {return f.id == 6} ) > -1  && funcionalidades.findIndex(f => {return f.id == 7} ) > -1 && listEtapa_5_15_16_JA.includes(editElement)) {
              return true
            }               

          }else if(etapa == 16){ // Analisis asistente - Analistas (Coordinador - Supervisor - Asistente) / JA puede cerrar y anular

            if(funcionalidades.findIndex(f => {return f.id == 3} ) > -1 && listEtapa_16.includes(editElement)){
              return true
            }else if (funcionalidades.findIndex(f => {return f.id == 6} ) > -1  && funcionalidades.findIndex(f => {return f.id == 7} ) > -1 && listEtapa_5_15_16_JA.includes(editElement)) {
              return true
            }               

          }else if(
            (etapa == 22) // Validación Residente OC para ITE - Oficina Central
            && funcionalidades.findIndex(f => {return f.id == 26} ) > -1 
            && (listEtapaEP_22.includes(editElement))
          ){
            return true;
          }
        }else if(this.authService.globalContrato.contratoTipo.id == 3){ //Contrato tipo "Gestión de Establecimiento Penitenciario (EP)"
          if(
            (etapa == 1 || etapa == 13)  // Ingreso - Cierre de la secretaria //Creación ITE
            && funcionalidades.findIndex(f => {return f.id == 1} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 14} ) > -1
            && funcionalidades.findIndex(f => {return f.id == 17} ) > -1
            && (listEtapaEP_1_13.includes(editElement))
          ){
            return true;
          }else if(
            (etapa == 2) // Asignacion - ITE
            && funcionalidades.findIndex(f => {return f.id == 6} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 7} ) > -1 
            && funcionalidades.findIndex(f => {return f.id == 23} ) > -1 
            && (listEtapaEP_2.includes(editElement))
          ){
            return true;
          }else if(etapa == 20){ // Analisis supervisor - Analistas/Supervisor / ITE puede cerrar y anular
              
            if(funcionalidades.findIndex(f => {return f.id == 2} ) > -1  && funcionalidades.findIndex(f => {return f.id == 24} ) > -1 && listEtapaEP_20.includes(editElement)){
              return true
            }else if (funcionalidades.findIndex(f => {return f.id == 6} ) > -1  && funcionalidades.findIndex(f => {return f.id == 7} ) > -1 && listEtapaEP_20_ITE.includes(editElement)) {
              return true
            }  

        }else if(
          (etapa == 21 || etapa == 23)  // Validación ITE para supervisor 
          && funcionalidades.findIndex(f => {return f.id == 6} ) > -1 
          && funcionalidades.findIndex(f => {return f.id == 7} ) > -1 
          && funcionalidades.findIndex(f => {return f.id == 25} ) > -1 
          && funcionalidades.findIndex(f => {return f.id == 10} ) > -1 
          && funcionalidades.findIndex(f => {return f.id == 11} ) > -1 
          && (listEtapaEP_21.includes(editElement))
        ){
          return true;
        }else if(
          (etapa == 7) // Pendiente de cierre (adjuntar doc firmado del IF) - Secretaria
          && funcionalidades.findIndex(f => {return f.id == 14} ) > -1 
          && (listEtapa_7.includes(editElement))
        ){
          return true;
        }else if(
          (etapa == 26) // Análisis ITE
          && funcionalidades.findIndex(f => {return f.id == 23} ) > -1
          && funcionalidades.findIndex(f => {return f.id == 25} ) > -1 
          && (listEtapaEP_26.includes(editElement))
        ){
          return true;
        }
        }
    return false;    
  }
}
