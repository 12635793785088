// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
             h1 {color: blue;}
             h4 {color: green;}
             .ui-g {
                text-align: center;
                background-color: aliceblue;}
             `, "",{"version":3,"sources":["webpack://./src/app/home.component.ts"],"names":[],"mappings":";aACa,IAAI,WAAW,CAAC;aAChB,IAAI,YAAY,CAAC;aACjB;gBACG,kBAAkB;gBAClB,2BAA2B,CAAC","sourcesContent":["\n             h1 {color: blue;}\n             h4 {color: green;}\n             .ui-g {\n                text-align: center;\n                background-color: aliceblue;}\n             "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
