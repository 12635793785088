//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";
import {Cargo} from '../cargo/cargo';
import {Role} from '../role/role';
import {Contrato} from '../contrato/contrato';

export class Usuario {

    menuRuta = 'usuario-list';
    // Raw attributes
     id : number;
     nombre : string;
     apellidoPaterno : string;
     apellidoMaterno : string;
     correoElectronico : string;
     username : string;
     passwd : string;
     movil : string;
     rut : number;
     dv : string;
     enabled : boolean;
     fechaRegistro : Date;
     fechaActualizacion : Date;
     telefono : string;
     borrado : boolean;
     passwdDefAdmin : boolean;
     codigoPwd : string;
     fechaExpiraCodPwd : Date;
     numIntentoCodPwd : number;
    // x-to-one
    cargo : Cargo;
    // many-to-many
    roles : Role[];
    contratos : Contrato[];

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            this.nombre = json.nombre;
            this.apellidoPaterno = json.apellidoPaterno;
            this.apellidoMaterno = json.apellidoMaterno;
            this.correoElectronico = json.correoElectronico;
            this.username = json.username;
            this.passwd = json.passwd;
            this.movil = json.movil;
            this.rut = json.rut;
            this.dv = json.dv;
            this.enabled = json.enabled;
            if (json.fechaRegistro != null) {
                this.fechaRegistro = new Date(json.fechaRegistro);
            }
            if (json.fechaActualizacion != null) {
                this.fechaActualizacion = new Date(json.fechaActualizacion);
            }
            this.telefono = json.telefono;
            this.borrado = json.borrado;
            this.passwdDefAdmin = json.passwdDefAdmin;
            this.codigoPwd = json.codigoPwd;
            if (json.fechaExpiraCodPwd != null) {
                this.fechaExpiraCodPwd = new Date(json.fechaExpiraCodPwd);
            }
            this.numIntentoCodPwd = json.numIntentoCodPwd;

            if (json.cargo != null) {
                this.cargo = new Cargo(json.cargo);
            }

            if (json.roles != null) {
                this.roles = Role.toArray(json.roles);
            }

            if (json.contratos != null) {
                this.contratos = Contrato.toArray(json.contratos);
            }
        }
    }

    // Utils

    static toArray(jsons : any[]) : Usuario[] {
        let usuarios : Usuario[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                usuarios.push(new Usuario(json));
            }
        }
        return usuarios;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(){
        let auth = AppInjector.get(AuthService)
        let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        let code = auth.globalIdioma.code;
        // let concatString = '';
        // for (let col of allColumnsString) {
        //     if(col.codeLanguage == code || col.codeLanguage == 'all'){
        //         concatString += this[col.field]+' ';
        //     }
        // }
        // if(concatString){
        //     return concatString.replace(/null/g,'').trim();
        // }
        return this.nombre;
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
