//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-list.component.ts.e.vm
//
import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges, OnInit } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { first, switchAll } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { PageResponse } from '../../support/paging';
import { MsgService } from '../../service/message.service';
import { GlobalAuditService } from '../../service/globalAudit.service';
import { Requerimiento } from './requerimiento';
import { RequerimientoDetailComponent } from './requerimiento-detail.component';
import { RequerimientoService } from './requerimiento.service';
import { AuthService } from '../../service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { RolePermisoEntidad } from '../rolePermisoEntidad/rolePermisoEntidad';
import { Usuario } from '../usuario/usuario';
import { UsuarioLineComponent } from '../usuario/usuario-line.component';
import { Contrato } from '../contrato/contrato';
import { ContratoLineComponent } from '../contrato/contrato-line.component';
import { RequerimientoTipo } from '../requerimientoTipo/requerimientoTipo';
import { RequerimientoTipoLineComponent } from '../requerimientoTipo/requerimientoTipo-line.component';
import { Etapa } from '../etapa/etapa';
import { EtapaLineComponent } from '../etapa/etapa-line.component';
import { RequerimientoPrefijo } from '../requerimientoPrefijo/requerimientoPrefijo';
import { RequerimientoPrefijoLineComponent } from '../requerimientoPrefijo/requerimientoPrefijo-line.component';
import { Empresa } from '../empresa/empresa';
import { EmpresaLineComponent } from '../empresa/empresa-line.component';
import { Area } from '../area/area';
import { AreaLineComponent } from '../area/area-line.component';
import { RequerimientoArchivoService } from '../requerimientoArchivo/requerimientoArchivo.service';
import { indexOf } from '@amcharts/amcharts4/.internal/core/utils/Array';

@Component({
	templateUrl: 'requerimiento-list.component.html',
	selector: 'requerimiento-list'
})
export class RequerimientoListComponent implements OnInit{
    @Input() header = "tltRequerimiento";
    @Input() padre = ""; //nombre del padre que se pasa por parametro para el header de la tabla y poder aplicar el translate

    // When 'sub' is true, it means this list is used as a one-to-many list.
    // It belongs to a parent entity, as a result the addNew operation
    // must prefill the parent entity. The prefill is not done here, instead we
    // emit an event.
    // When 'sub' is false, we display basic search criterias
    @Input() sub : boolean;
    @Output() onAddNewClicked = new EventEmitter();
    @Output() onRowSelectSub = new EventEmitter();
    permisos:any = new RolePermisoEntidad();
    keyEntity = 'key_requerimiento';
    key_sessionStorage = 'stateSessionRequerimiento-list';
    optionsColumns: any;
    originalColumns:any;
    cols:any [] = [];
    menuRuta: any = 'requerimiento-list';
    requerimientoToDelete : Requerimiento;
    requerimientoHistory : any;
    showDialog: boolean = false;
    showDialogPreView:boolean = false;
    listReqPorVencer = [];
    showDialogReqPorVencer = false;
    rowDataTmpView:any = null;
    urlView:string = null;
    formato:string = null;
    loading: boolean = true;
    activeFilter : boolean = true;
    editColumnRequerimiento:boolean = false;
    // basic search criterias (visible if not in 'sub' mode)
    example : Requerimiento = new Requerimiento();
    rangeNumber:any={};
    filterRangeNumber:any={};
    filterRangeDate:any={};
    public es: any = {firstDayOfWeek: 1,dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],dayNamesMin: [ "D","L","MA","MI","J","V","S" ],monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ]};
    sortOrder:any;
sortField:any;
loadColums = false;
    viewId;    
    titulosList = ['tltRequerimientoPendiente','tltMisRequerimientos','tltRequerimientoCerrado'];
    isContratoTipoOC = false;
    // cantFiltrosActivos:number;




    
    //SelectButton value parameters
    //optionsRequerimiento: any[] = [{label: 'Mis Requerimientos', value: 'mis_req'}, {label: 'Estado de los Requerimientos', value: 'est_req'}, {label: 'Listado General', value: 'gen_req'}];
    //value: string = 'mis_req';

    // list is paginated
    currentPage : PageResponse<Requerimiento> = new PageResponse<Requerimiento>(0,0,[]);

    // X to one: input param is used to filter the list when displayed
    // as a one-to-many list by the other side.
    private _ingresadoPor : Usuario;
    private _contrato : Contrato;
    private _recinto : Contrato;
    private _asistente : Usuario;
    private _requerimientoTipo : RequerimientoTipo;
    private _coordinador : Usuario;
    private _etapa : Etapa;
    private _supervisor : Usuario;
    private _requerimientoPrefijo : RequerimientoPrefijo;
    private _empresaDestinataria : Empresa;
    private _area : Area;

    constructor(private router : Router,
        private requerimientoService : RequerimientoService,
        private requerimientoArchivoService:RequerimientoArchivoService,
        private messageService : MsgService,
        private globalAudit : GlobalAuditService,
        private authService : AuthService,
        private translate: TranslateService,
        private route: ActivatedRoute,
        private confirmationService: ConfirmationService,
        private datePipe: DatePipe) {
    }

    //carga inicial de las columnas de la tabla segun usuario conectado o por default
    async ngOnInit(){
      let params:any = await this.route.data;
      this.viewId = params.value.viewId; //mis pendientes = 1 / mis requerimientos = 2 / cerrados = 3
      if(this.viewId == 1){
        //await this.getRequerimientosPorVencer();
        this.getRequerimientosPorVencer();
      }
      let filters = JSON.parse(sessionStorage.getItem(this.key_sessionStorage));
      this.cols = [];
      if(filters){
        this.example = filters.example;
        this.sortField = filters.sortField;
        this.sortOrder = filters.sortOrder;
        for(let f of Object.keys(filters.filterRangeDate)){          
          this.filterRangeDate[f] = filters.filterRangeDate[f].map(date => new Date(date))
        }
        // for(let n of Object.keys(filters.filterRangeNumber)){
        //   this.rangeNumber[n+"_desde"] = filters.filterRangeNumber[n][0];
        //   this.rangeNumber[n+"_hasta"] = filters.filterRangeNumber[n][1];
        //   this.filterRangeNumber[n] = filters.filterRangeNumber[n];
        // }
        for(let n of Object.keys(filters.filterRangeNumber)){
          this.rangeNumber[n] = filters.filterRangeNumber[n][0] + '-'+filters.filterRangeNumber[n][1];
          this.filterRangeNumber[n] = filters.filterRangeNumber[n];
        }
      }
      if(this.authService.globalContrato?.finalizado){
        this.permisos.crear = false;
        this.permisos.actualizar = false;        
        this.permisos.eliminar = false;
      }else{
        this.authService.getPermisosPorEntidad(this.keyEntity).then(resp=>{this.permisos = resp});
      }
      
      
      if(this.authService.globalColumns == undefined){
        await this.authService.setGlobalColumnsfromDB();
      }
      this.originalColumns = this.authService.globalColumns[this.menuRuta];           
      this.optionsColumns = this.originalColumns.filter(col => (col.codeLanguage == this.authService.globalIdioma.code || (col.codeLanguage == undefined || col.codeLanguage == "all")) && col.delete == false);
      for(let cl of this.optionsColumns){
        if(cl.default){
          this.cols.push(cl);
        }
      }
            
      this.loadColums = true;            
    }

    async getRequerimientosPorVencer(){
      this.setContrato();
      this.listReqPorVencer = await this.requerimientoService.getRequerimientosPorVencer(this.example);
      //console.log("🚀 ~ AppComponent ~ login ~ this.listReqPorVencer:", this.listReqPorVencer)
      if(this.listReqPorVencer.length > 0){   
        let existAtrasados = this.listReqPorVencer.findIndex(r => r?.vencimiento <= 0);
        if(existAtrasados > -1){
          this.showDialogReqPorVencer = true;
        }        
      } 
    }

    

    setFormatNumberRange(inputElement, sort) {
      const value = this.rangeNumber[sort]; 
      let spl = value.split("-");            
      
      if (spl.length < 2) {
        this.rangeNumber[sort] = value + "-";   
        inputElement.srcElement.value = this.rangeNumber[sort];
        inputElement.srcElement.setSelectionRange(this.rangeNumber[sort].length-1, this.rangeNumber[sort].length-1);
      }else if(spl.length >=3){
        this.rangeNumber[sort] = spl[0] +'-'+spl[1];   
        inputElement.srcElement.value = this.rangeNumber[sort];
        inputElement.srcElement.setSelectionRange(this.rangeNumber[sort].length, this.rangeNumber[sort].length);
      }            
    }

    setFilterRangeNumber(sort){
      if(!this.rangeNumber[sort]){
        return;
      }
      let spl = this.rangeNumber[sort].split("-");      
      if(spl.length == 2){
        let desde = spl[0].trim() != '' ? spl[0]:null;
        let hasta = spl[1].trim() != '' ? spl[1]:null;
        if(this.filterRangeNumber[sort] == null){
          this.filterRangeNumber[sort] = [null,null]
        }       
        this.filterRangeNumber[sort][0] = desde;
        this.filterRangeNumber[sort][1] = hasta;        
      }else{        
        this.filterRangeNumber[sort] = null;
      }      
    }
    /**
     * When used as a 'sub' component (to display one-to-many list), refreshes the table
     * content when the input changes.
     */
    ngOnChanges(changes: SimpleChanges) {
        //this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
    }
    viewFilter(){
      if(this.activeFilter)
        this.activeFilter=false;
      else
        this.activeFilter=true;
    }
    clean(dt : any){
      //dt.reset();
      this.example = new Requerimiento();
      // evita que se pierda el filtro de los input (padres) cuandos es sub
      this.example.ingresadoPor = this._ingresadoPor;
      this.example.contrato = this.authService.globalContrato;
      this.example.recinto = this._recinto;
      this.example.asistente = this._asistente;
      this.example.requerimientoTipo = this._requerimientoTipo;
      this.example.coordinador = this._coordinador;
      this.example.etapa = this._etapa;
      this.example.supervisor = this._supervisor;
      this.example.requerimientoPrefijo = this._requerimientoPrefijo;
      this.example.empresaDestinataria = this._empresaDestinataria;
      this.example.area = this._area;
      this.filterRangeDate = {};
      this.filterRangeNumber = {}
      this.rangeNumber = {}
      dt.filters = {};
      sessionStorage.removeItem(this.key_sessionStorage);
      // this.cantFiltrosActivos = 0;
      dt.reset();
      this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
    }
    
    //Actualiza la tabla manteniendo orden, filtro y página
    async reload(){
      let tmp: any = JSON.parse(sessionStorage.getItem(this.key_sessionStorage));
      let sortField:any = tmp?.sortField?tmp.sortField:null;
      let sortOrder:any = tmp?.sortOrder?tmp.sortOrder:null;
      let filters:any = tmp?.filters?tmp.filters:null;      
      await this.ngOnInit();
      this.loadPage({ first: 0, rows: 10, sortField: sortField, sortOrder: sortOrder, filters: filters, multiSortMeta: null });
    }

    /**
     * Invoked when user presses the search button.
     */
    /*search(dt : any) {
      dt.reset();
      this.loadPage({ first: 0, rows: dt.rows, sortField: dt.sortField, sortOrder: dt.sortOrder, filters: null, multiSortMeta: dt.multiSortMeta });
    }*/

    /**
     * Invoked automatically by primeng datatable.
     */
    async loadPage(event : LazyLoadEvent) {
      this.loading = true;
      this.setContrato();
      try {
        let page:any = await this.requerimientoService.getPage(this.example, event, this.cols, this.viewId, this.filterRangeDate, this.filterRangeNumber);                
        this.currentPage = new PageResponse<Requerimiento>(page.totalPages, page.totalElements, Requerimiento.toArray(page.content));        
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.messageService.customError(this.translate.instant("Requerimiento"),this.translate.instant("lblErrorNgOnInit"), error);
      }
     
    }

    setContrato(){    
      this.example.contrato = new Contrato(this.authService.globalContrato);         
      if(this.example.contrato.contratoTipo.id == 2){
        this.isContratoTipoOC = true;
      }else{
        this.isContratoTipoOC = false;
      }
    }
    //Registra los atributos al Session Storage para mantener los filtros en la sesión
    setFilterTable(dt){  
      //console.log(this.example)     
      let filters = {
        'example':this.example,
        'filterRangeDate':this.filterRangeDate,
        'filterRangeNumber':this.filterRangeNumber,
        'sortOrder':dt.sortOrder,
        'sortField':dt.sortField
      }
      sessionStorage.setItem(this.key_sessionStorage,JSON.stringify(filters))
      this.loadPage({ first: 0, rows: dt.rows, sortField: dt.sortField, sortOrder: dt.sortOrder, filters: null, multiSortMeta: dt.multiSortMeta });      
    }

    // evita que los auto complete recargen toda la tabla al hacer click en el despliegue
    controlFiltros(attr, table){
      if(attr != null){
        this.setFilterTable(table);
        // this.setCountFilters();
      }
    }

    // setCountFilters(){
    //   console.log("asdfasfdasf")
    //   this.cantFiltrosActivos = 0;
    //   for(let key in this.example){
    //     if(key != 'menuRuta'){
    //       if(this.example[key] != null && this.example[key].id != null){
    //         this.cantFiltrosActivos++;
    //       }            
    //     }        
    //   }
    //   console.log("🚀 ~ RequerimientoListComponent ~ setCountFilters ~ this.cantFiltrosActivos:", this.cantFiltrosActivos)
    // }

    //guarda la seleccion de columnas para perdurar en proximas conexiones del usuario
    async saveSelectedColumnsList(){ 
      if(this.editColumnRequerimiento){           
        this.originalColumns.map(org => {org.default = false;});        
        if(this.cols.length > 0){
          let tmpCol = Object.assign([], this.cols);
          for(let col of tmpCol.reverse()){
            col.default = true;
            let index = this.originalColumns.findIndex(t => t.id == col.id);
            this.originalColumns.splice(index,1);     
            this.originalColumns.unshift(col);     
          }                  
        }        
              
        for(let col of this.cols){
        this.originalColumns.map((r,i) =>{   
          if(this.authService.globalIdioma.code!='es'){  
            if((r.codeLanguage!=this.authService.globalIdioma.code || r.codeLanguage!='all') && col.field.substring(0, col.field.length-2) == r.field){
              this.originalColumns[i].default = true;
            }
          }else{
            if((r.codeLanguage!=this.authService.globalIdioma.code || r.codeLanguage!='all') && r.field.substring(0, r.field.length-2) == col.field){
              this.originalColumns[i].default = true;
            }
          }
        
        });
      }      

        await this.authService.saveColumnsByRuta(this.originalColumns,this.menuRuta);
        await this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
        this.editColumnRequerimiento = false;
      }
    }
    //guarda los anchos de las columnas por usuario
    async saveWidthColumnsList(e){
      let newWidth = (parseFloat(e.element.attributes.style.value.match(/[\d\.]+/))/16).toString() + "em";
      let idResize = e.element.attributes[1].value;
      this.originalColumns.filter(org => {
        for(let col of this.cols){
            if(org.id == idResize){
              org.width = newWidth;
            }
        }
      });  
      await this.authService.saveColumnsByRuta(this.originalColumns,this.menuRuta);
    }
    async getCSV(){
      try {
          let csv : any = await this.requerimientoService.getCSV(this.example, this.viewId, this.filterRangeDate, this.filterRangeNumber);
          var csvData = new Blob(["\ufeff"+csv], {type: 'text/csv;charset=utf-8;'});
          var csvURL = window.URL.createObjectURL(csvData);
          var tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', this.translate.instant(this.titulosList[this.viewId - 1]).replace(" ", "_") + '_' + this.datePipe.transform(new Date(),'ddMMyyyy_HHmm') + '.csv');
          tempLink.click();
      } catch (error) {
          this.messageService.customError(this.translate.instant("Requerimiento"),this.translate.instant("lblErrorCSV"), error)
      }
    }


    // X to one: input param is used to filter the list when displayed
    // as a one-to-many list by the other side.
    @Input()
    set ingresadoPor(ingresadoPor : Usuario) {
        if (ingresadoPor == null) {
            return;
        }
        this._ingresadoPor = ingresadoPor;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.ingresadoPor = new Usuario();
        this.example.ingresadoPor.id = this._ingresadoPor.id;
    }

    @Input()
    set contrato(contrato : Contrato) {
        if (contrato == null) {
            return;
        }
        this._contrato = contrato;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.contrato = new Contrato();
        this.example.contrato.id = this._contrato.id;
    }

    @Input()
    set recinto(recinto : Contrato) {
        if (recinto == null) {
            return;
        }
        this._recinto = recinto;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.recinto = new Contrato();
        this.example.recinto.id = this._recinto.id;
    }

    @Input()
    set asistente(asistente : Usuario) {
        if (asistente == null) {
            return;
        }
        this._asistente = asistente;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.asistente = new Usuario();
        this.example.asistente.id = this._asistente.id;
    }

    @Input()
    set requerimientoTipo(requerimientoTipo : RequerimientoTipo) {
        if (requerimientoTipo == null) {
            return;
        }
        this._requerimientoTipo = requerimientoTipo;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.requerimientoTipo = new RequerimientoTipo();
        this.example.requerimientoTipo.id = this._requerimientoTipo.id;
    }

    @Input()
    set coordinador(coordinador : Usuario) {
        if (coordinador == null) {
            return;
        }
        this._coordinador = coordinador;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.coordinador = new Usuario();
        this.example.coordinador.id = this._coordinador.id;
    }

    @Input()
    set etapa(etapa : Etapa) {
        if (etapa == null) {
            return;
        }
        this._etapa = etapa;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.etapa = new Etapa();
        this.example.etapa.id = this._etapa.id;
    }

    @Input()
    set supervisor(supervisor : Usuario) {
        if (supervisor == null) {
            return;
        }
        this._supervisor = supervisor;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.supervisor = new Usuario();
        this.example.supervisor.id = this._supervisor.id;
    }

    @Input()
    set requerimientoPrefijo(requerimientoPrefijo : RequerimientoPrefijo) {
        if (requerimientoPrefijo == null) {
            return;
        }
        this._requerimientoPrefijo = requerimientoPrefijo;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.requerimientoPrefijo = new RequerimientoPrefijo();
        this.example.requerimientoPrefijo.id = this._requerimientoPrefijo.id;
    }

    @Input()
    set empresaDestinataria(empresaDestinataria : Empresa) {
        if (empresaDestinataria == null) {
            return;
        }
        this._empresaDestinataria = empresaDestinataria;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.empresaDestinataria = new Empresa();
        this.example.empresaDestinataria.id = this._empresaDestinataria.id;
    }

    @Input()
    set area(area : Area) {
        if (area == null) {
            return;
        }
        this._area = area;
        if(this.example == null){
            this.example = new Requerimiento();
        }
        this.example.area = new Area();
        this.example.area.id = this._area.id;
    }


    onRowSelect(event : any) {        
        if (this.sub){
            this.onRowSelectSub.emit(event.data);
        } else {
            let id =  event.data.id;
            this.router.navigate(['/requerimiento', id]);
        }
        this.showDialogReqPorVencer = false;
    }

    addNew() {
        if (this.sub) {
            this.onAddNewClicked.emit("addNew");
        } else {
            this.router.navigate(['/requerimiento', 'new']);
        }
    }

    showPreView(data){
      this.rowDataTmpView = data;
      if(data.archivoContentType == "application/pdf"){
        this.formato = data.archivoContentType;
      }else if(data.archivoContentType.includes('image')){
        this.formato = "images";
      }else{
        this.formato = "FNS";//Formato no soportado
      }
      this.urlView = "/api/requerimientoArchivos/"+data.id+"/download/archivoBinary";
      this.showDialogPreView = true;
    }

    downloadArchivoBinary(rowData){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(rowData.archivoFileName);
      let http = this.requerimientoArchivoService.downloadArchivoBinary(rowData).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,rowData.archivoFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    downloadFormat(file, name){
      var fileURL = window.URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', name);
      tempLink.click();
    }

    showDeleteDialog(rowData : any, event: any) {
        let requerimientoToDelete : Requerimiento = <Requerimiento> rowData;

        this.confirmationService.confirm({
            target: event.target,
            message: this.translate.instant("lblPreguntaDelete"),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.delete(requerimientoToDelete);
            },
            reject: () => {
                //reject action
            }
        });
    }

    async historico(requerimientoToHistory : Requerimiento){
      let entidad:any = {"entidad":"Requerimiento","id":requerimientoToHistory.id};
      try{
        this.requerimientoHistory = await this.globalAudit.getHistory(entidad);
        this.showDialog = true;
      }catch(error){
        this.messageService.customError("Requerimiento:", this.translate.instant("lblProblemaObtenerHistorico"), error);
      }
    }    
    
    private async delete(requerimientoToDelete : Requerimiento) {
        let id =  requerimientoToDelete.id;
        try {
          await this.requerimientoService.delete(id);
          this.currentPage.remove(requerimientoToDelete);
          this.messageService.info("Requerimiento:", this.translate.instant("lblRegistroEliminado"));
        } catch (error) {
          this.messageService.customError("Requerimiento:", this.translate.instant("lblNoPudoEliminar"), error);
        }
    }
}
