//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";
import {ContratoTipo} from '../contratoTipo/contratoTipo';
import { Comuna } from "../comuna/comuna";
import { Region } from "../region/region";
import { Provincia } from "../provincia/provincia";

export class Contrato {

    menuRuta = 'contrato-list';
    // Raw attributes
     id : number;
     nombre : string;
     codigo : string;
     descripcion : string;
     fechaRegistro : Date;
     fechaInicioContrato : Date;
     fechaTerminoContrato : Date;
     responsableTecnico : string;
     responsableTecnicoCorreo : string;
     responsableTecnicoTelefono : string;
     responsableTecnicoTelefonoAnexo : string;
     responsableAdministrativo : string;
     responsableAdministrativoCorreo : string;
     responsableAdministratioTelefono : string;
     responsableAdministrativoTelefonoAnexo : string;
     finalizado : boolean;
     borrado : boolean;
    // x-to-one
    contratoTipo : ContratoTipo;
    comuna : Comuna;
    region : Region;
    provincia : Provincia;
    contratosEP : Contrato[];

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            this.nombre = json.nombre;
            this.codigo = json.codigo;
            this.descripcion = json.descripcion;
            if (json.fechaRegistro != null) {
                this.fechaRegistro = new Date(json.fechaRegistro);
            }
            if (json.fechaInicioContrato != null) {
                this.fechaInicioContrato = new Date(json.fechaInicioContrato);
            }
            if (json.fechaTerminoContrato != null) {
                this.fechaTerminoContrato = new Date(json.fechaTerminoContrato);
            }
            this.responsableTecnico = json.responsableTecnico;
            this.responsableTecnicoCorreo = json.responsableTecnicoCorreo;
            this.responsableTecnicoTelefono = json.responsableTecnicoTelefono;
            this.responsableTecnicoTelefonoAnexo = json.responsableTecnicoTelefonoAnexo;
            this.responsableAdministrativo = json.responsableAdministrativo;
            this.responsableAdministrativoCorreo = json.responsableAdministrativoCorreo;
            this.responsableAdministratioTelefono = json.responsableAdministratioTelefono;
            this.responsableAdministrativoTelefonoAnexo = json.responsableAdministrativoTelefonoAnexo;
            this.finalizado = json.finalizado;
            this.borrado = json.borrado;

            if (json.contratoTipo != null) {
                this.contratoTipo = new ContratoTipo(json.contratoTipo);
            }
            if (json.comuna != null) {
                this.comuna = new Comuna(json.comuna);
            }

            if (json.region != null) {
                this.region = new Region(json.region);
            }

            if (json.provincia != null) {
                this.provincia = new Provincia(json.provincia);
            }
            if (json.contratosEP != null) {
                this.contratosEP = Contrato.toArray(json.contratosEP);
            }            
        }
    }

    // Utils

    static toArray(jsons : any[]) : Contrato[] {
        let contratos : Contrato[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                contratos.push(new Contrato(json));
            }
        }
        return contratos;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(){
        // let auth = AppInjector.get(AuthService)
        // let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        // let code = auth.globalIdioma.code;
        // let concatString = '';
        // for (let col of allColumnsString) {
        //     if(col.codeLanguage == code || col.codeLanguage == 'all'){
        //         concatString += this[col.field]+' ';
        //     }
        // }
        // if(concatString){
        //     return concatString.replace(/null/g,'').trim();
        // }
        return this.nombre;
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
