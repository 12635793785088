//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { Usuario } from './usuario';
import { UsuarioService } from './usuario.service';
import { TranslateService } from '@ngx-translate/core';
import { Role } from '../role/role';
import { RoleService } from '../role/role.service';
import { Contrato } from '../contrato/contrato';
import { ContratoService } from '../contrato/contrato.service';
import { Cargo } from '../cargo/cargo';
import { CargoService } from '../cargo/cargo.service';

@Component({
	templateUrl: 'usuario-detail.component.html',
	selector: 'usuario-detail',
})
export class UsuarioDetailComponent implements OnInit{

    msgRut:string;
    rutValido:boolean;
    @ViewChild('usuarioForm', {static: false}) usuarioForm:any;
    usuario : Usuario;

    private params_subscription: any;
    guardo: boolean = false;
    loading: boolean = false;
    sourceRole : Role[] = [];
    sourceContrato : Contrato[] = [];
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_usuario';
    rolIdFilter: Role[];
    flagTipoContratoEP: boolean = false;
    esAdmin: boolean = false;
    contrato: Contrato;
    groupedContrato: any = [];
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new Usuario
    set cargo(cargo : Cargo) {
      if(this.usuario == undefined){
        this.usuario = new Usuario();
      }
      this.usuario.cargo = cargo;
    }

    @Input() // used to pass the parent when creating a new Usuario
    set selectUsuario(usuario : Usuario) {
      if(this.sub){
        this.usuario = usuario;
      }
    }

    @Output() onSaveClicked = new EventEmitter<Usuario>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'nombre': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'apellidoPaterno': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'apellidoMaterno': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'correoElectronico': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'username': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'passwd': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 4},
    ],
      'movil': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'rut': [
    ],
      'dv': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'enabled': [
    ],
      'fechaRegistro': [
    ],
      'fechaActualizacion': [
    ],
      'telefono': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'borrado': [
    ],
      'passwdDefAdmin': [
    ],
      'codigoPwd': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'fechaExpiraCodPwd': [
    ],
      'numIntentoCodPwd': [
    ],
      'cargo': [
        { type: 'required', message: 'lblRequerido' }
      ],
    }

    contratosNoEditables:Contrato[] = [];

    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private usuarioService: UsuarioService, private roleService : RoleService, private contratoService : ContratoService, private cargoService : CargoService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
      this.rolIdFilter = this.authService.globalUser.roles;

        if (this.sub) {
          if(this.usuario == undefined && this.permisos.crear){
            this.usuario = new Usuario();
            this.rutValido = false;
            this.usuario.enabled = true;
            this.usuario.fechaRegistro = new Date();
            this.usuario.fechaActualizacion = new Date();
            this.usuario.passwdDefAdmin = true;
            this.usuario.fechaExpiraCodPwd = new Date();
            this.usuario.contratos = this.authService.globalUser.contratos;
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.usuario = new Usuario();
            this.rutValido = false;
            this.usuario.roles = [];
            //this.usuario.contratos = [];
            this.sourceContrato = this.authService.globalUser.contratos;
            this.usuario.enabled = true;
            this.usuario.fechaRegistro = new Date();
            this.usuario.fechaActualizacion = new Date();
            this.usuario.passwdDefAdmin = false;
            this.usuario.fechaExpiraCodPwd = new Date();
            this.editContent = this.permisos.crear;
          } else {
            this.usuario = new Usuario(await this.usuarioService.getUsuario(id));
            this.usuario.contratos = Contrato.toArray(this.usuario.contratos);
            this.rutValido = true;
            this.sourceRole = this.usuario.roles;
            this.sourceContrato = Contrato.toArray(this.authService.globalUser.contratos);
            //this.keepContratosNoEditables();
            this.habilitaContrato(true);
            this.esAdmin = this.usuario.roles.findIndex(r=> r.id == 2) != -1;  //Es usuario con Rol Admin
            
          }
          //console.log(this.usuario);
          //if(!this.authService.getIsAdmin()){
            await this.setGroupedContratos();
          //}

          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("Usuario"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    //preserva los contratos no editables por motivos de permisos
    keepContratosNoEditables(){      
      for(let keepContrato of this.usuario.contratos){
        let exist = this.sourceContrato.find(cc => {cc.id == keepContrato.id});
        if(!exist){// si no existe el contrato en el source, significa que el usuario JA no tiene accesos a todos los contratos del usuario y se preservan para evitar ser editados
          this.contratosNoEditables.push(keepContrato);
        }
      }
    }

    async setGroupedContratos(){
      this.loading=true;
      this.groupedContrato = [];
      let contrato = new Contrato();
      contrato.id = -88;
      let listContrato = await this.contratoService.complete(contrato);
      let indexGroupedContrato = 0;
      for(let contratoTMP of listContrato){
        let existContrato = this.sourceContrato.findIndex(x => x.id == contratoTMP.id);
        if(existContrato != -1){
          if(contratoTMP.contratosEP.length > 0){
            this.groupedContrato.push({label: contratoTMP.nombre, value: contratoTMP, items: []});
            for(let epTMP of contratoTMP.contratosEP){
              this.groupedContrato[indexGroupedContrato].items.push({label: epTMP.nombre, value: epTMP});
            }
            indexGroupedContrato++;
          }
        }
      }
      //console.log(this.groupedContrato);
      this.loading=false;
    }

    habilitaContrato(cargaInicial?){
      //console.log(this.usuario.cargo);
      if(this.usuario.cargo != null){
        let rolId = this.usuario.cargo.role.id;
        if(rolId == 10 || rolId == 11 || rolId == 12){
          this.flagTipoContratoEP = true;
        }else{
          this.flagTipoContratoEP = false;
        }
        if(!cargaInicial){
          this.usuario.contratos = [];
        }
      }

    }
    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'usuarioKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.usuarioForm.onSubmit(null); //cambia el estado del form submited = true
      this.rutValido = this.validaRut();
      if(this.usuarioForm.form.valid && this.rutValido){
        this.messageService.clear();
        this.usuario.fechaActualizacion = new Date();
        this.usuario.roles =[];
        this.usuario.roles.push(this.usuario.cargo.role);
        // if(this.contratosNoEditables.length > 0){
        //   this.usuario.contratos.concat(this.contratosNoEditables);
        // }
        //   console.log("🚀 ~ UsuarioDetailComponent ~ onSave ~ this.usuario.contratos:", this.usuario.contratos)
        
        try{
          this.usuario = new Usuario(await this.usuarioService.update(this.usuario));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("Usuario"), this.translate.instant("lblGuardo"));
            this.volver();
            return this.usuario;
          }else{
            this.onSaveClicked.emit(this.usuario);
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("Usuario"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    async getRoles(){
      this.loading = true;
      this.sourceRole= await this.roleService.complete(null);
      this.loading = false;
    }
    async getContratos(){
      this.loading = true;
      let isAdmin = this.authService.getIsAdmin(); 
      if(isAdmin){
        this.sourceContrato = (await this.contratoService.complete(null)).filter(r => r.contratoTipo.id == 1 || r.contratoTipo.id == 2);
      }else{
        this.sourceContrato = this.authService.globalUser.contratos;
      }      
      this.loading = false;
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
    validaRut() {
      this.msgRut = null;
      if(this.usuario.dv != null){
          this.usuario.dv = this.usuario.dv.toUpperCase();
      }
      let rut = this.usuario.rut+"-"+this.usuario.dv;
      let valor = rut.split('.').join('');
      valor = valor.split('-').join('');
      let cuerpo: String = valor.slice(0,-1);
      let dv = valor.slice(-1).toUpperCase();
      if(cuerpo.length < 5) { this.msgRut=this.translate.instant("lblRutIncompleto"); this.rutValido = false; return false;}
      let suma = 0;
      let multiplo = 2;
      let index: any = null;
      for(let i=1;i<=cuerpo.length;i++) {
          index = multiplo * Number.parseInt(valor.charAt(cuerpo.length - i));
          suma = suma + index;
          if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
      }
      let dvEsperado = 11 - (suma % 11);
      dv = (dv == 'K')?'10':dv;
      dv = (dv == '0')?'11':dv;
      if(dvEsperado.toString() != dv) { this.msgRut=this.translate.instant("lblRutInvalido"); this.rutValido = false; return false; }
      if(dv == '10'){
        dv = "K";
      }else if(dv == '11'){
        dv = '0';
      }
      this.rutValido = true;
      return true;
    }
}
