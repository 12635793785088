// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep td {
  text-align: left !important;
}

:host ::ng-deep .calendar-style {
  height: 550px;
}`, "",{"version":3,"sources":["webpack://./src/app/custom/calendario/calendario-eventos.component.scss"],"names":[],"mappings":"AAAA;EACI,2BAAA;AACJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":[":host ::ng-deep td {\r\n    text-align: left !important;\r\n}\r\n\r\n:host ::ng-deep .calendar-style{\r\n    height: 550px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
