//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { RequerimientoNuevoRegistro } from './requerimientoNuevoRegistro';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class RequerimientoNuevoRegistroService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a RequerimientoNuevoRegistro by id.
     */
    getRequerimientoNuevoRegistro(id : any) {
        return this.http.get<RequerimientoNuevoRegistro>('/api/requerimientoNuevoRegistros/' + id).toPromise();
    }

    /**
     * Update the passed requerimientoNuevoRegistro.
     */
    update(requerimientoNuevoRegistro : RequerimientoNuevoRegistro) {
        let body = JSON.stringify(requerimientoNuevoRegistro);
        return this.http.post<RequerimientoNuevoRegistro>('/api/requerimientoNuevoRegistros/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of RequerimientoNuevoRegistro using the passed
     * requerimientoNuevoRegistro as an example for the search by example facility.
     */
     getPage(requerimientoNuevoRegistro : RequerimientoNuevoRegistro, event : LazyLoadEvent, columns, filterRangeNumber?) {
        let req = new PageRequestByExample(requerimientoNuevoRegistro, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        return this.http.post('/api/requerimientoNuevoRegistros/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by RequerimientoNuevoRegistroListComponent.
      */
    getCSV(requerimientoNuevoRegistro:RequerimientoNuevoRegistro, filterRangeNumber?) {
      let body = JSON.stringify(requerimientoNuevoRegistro);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      return this.http.post('/api/requerimientoNuevoRegistros/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by RequerimientoNuevoRegistroCompleteComponent.
     */
    complete(requerimientoNuevoRegistro : RequerimientoNuevoRegistro) {
        if(requerimientoNuevoRegistro == null){
          requerimientoNuevoRegistro = new RequerimientoNuevoRegistro();
        }
        let body = JSON.stringify(requerimientoNuevoRegistro);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<RequerimientoNuevoRegistro[]>('/api/requerimientoNuevoRegistros/complete', formData).toPromise();
    }

    /**
     * Delete an RequerimientoNuevoRegistro by id.
     */
    delete(id : any) {
        return this.http.delete('/api/requerimientoNuevoRegistros/' + id).toPromise();
    }
}
