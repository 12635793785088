//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { SecuencialNumeroRequerimiento } from './secuencialNumeroRequerimiento';
import { SecuencialNumeroRequerimientoService } from './secuencialNumeroRequerimiento.service';
import { TranslateService } from '@ngx-translate/core';
import { RequerimientoTipo } from '../requerimientoTipo/requerimientoTipo';
import { Contrato } from '../contrato/contrato';

@Component({
	templateUrl: 'secuencialNumeroRequerimiento-detail.component.html',
	selector: 'secuencialNumeroRequerimiento-detail',
})
export class SecuencialNumeroRequerimientoDetailComponent implements OnInit{

    @ViewChild('secuencialNumeroRequerimientoForm', {static: false}) secuencialNumeroRequerimientoForm:any;
    secuencialNumeroRequerimiento : SecuencialNumeroRequerimiento;

    private params_subscription: any;
    guardo: boolean = false;
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_secuencialNumeroRequerimiento';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new SecuencialNumeroRequerimiento
    set requerimientoTipo(requerimientoTipo : RequerimientoTipo) {
      if(this.secuencialNumeroRequerimiento == undefined){
        this.secuencialNumeroRequerimiento = new SecuencialNumeroRequerimiento();
      }
      this.secuencialNumeroRequerimiento.requerimientoTipo = requerimientoTipo;
    }

    @Input() // used to pass the parent when creating a new SecuencialNumeroRequerimiento
    set contrato(contrato : Contrato) {
      if(this.secuencialNumeroRequerimiento == undefined){
        this.secuencialNumeroRequerimiento = new SecuencialNumeroRequerimiento();
      }
      this.secuencialNumeroRequerimiento.contrato = contrato;
    }

    @Input() // used to pass the parent when creating a new SecuencialNumeroRequerimiento
    set selectSecuencialNumeroRequerimiento(secuencialNumeroRequerimiento : SecuencialNumeroRequerimiento) {
      if(this.sub){
        this.secuencialNumeroRequerimiento = secuencialNumeroRequerimiento;
      }
    }

    @Output() onSaveClicked = new EventEmitter<SecuencialNumeroRequerimiento>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'correlativo': [
        { type: 'required', message: 'lblRequerido' },
    ],
      'borrado': [
    ],
      'requerimientoTipo': [
      ],
      'contrato': [
      ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private secuencialNumeroRequerimientoService: SecuencialNumeroRequerimientoService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.secuencialNumeroRequerimiento == undefined && this.permisos.crear){
            this.secuencialNumeroRequerimiento = new SecuencialNumeroRequerimiento();
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.secuencialNumeroRequerimiento = new SecuencialNumeroRequerimiento();
            this.editContent = this.permisos.crear;
          } else {
            this.secuencialNumeroRequerimiento = new SecuencialNumeroRequerimiento(await this.secuencialNumeroRequerimientoService.getSecuencialNumeroRequerimiento(id));
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("SecuencialNumeroRequerimiento"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'secuencialNumeroRequerimientoKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.secuencialNumeroRequerimientoForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.secuencialNumeroRequerimientoForm.form.valid){
        this.messageService.clear();
        try{
          this.secuencialNumeroRequerimiento = new SecuencialNumeroRequerimiento(await this.secuencialNumeroRequerimientoService.update(this.secuencialNumeroRequerimiento));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("SecuencialNumeroRequerimiento"), this.translate.instant("lblGuardo"));
            return this.secuencialNumeroRequerimiento;
          }else{
            this.onSaveClicked.emit(this.secuencialNumeroRequerimiento);
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("SecuencialNumeroRequerimiento"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
