//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-auto-complete.component.ts.e.vm
//
import {Component, Input, Output, EventEmitter, ViewChild, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {AuthService} from 'app/service/auth.service';
import {MsgService} from '../../service/message.service';
import {RequerimientoPrefijo} from './requerimientoPrefijo';
import {RequerimientoPrefijoService} from './requerimientoPrefijo.service';

// Resource: http://almerosteyn.com/2016/04/linkup-custom-control-to-ngcontrol-ngmodel

export const REQUERIMIENTOPREFIJO_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RequerimientoPrefijoCompleteComponent),
    multi: true
};

@Component({
	template: `
    <span class="p-float-label">
        <p-autoComplete #requerimientoPrefijoId showClear="true" [(ngModel)]="value" size="100" inputStyleClass="{{paramInputStyleClass}}" completeOnFocus="true" showEmptyMessage="true"  appendTo="body" inputId="{{inputId}}" (onFocus)="setPlaceholder()" (onBlur)="setPlaceholder()" emptyMessage="Sin Resultados" [disabled]="disabled" [forceSelection]="true" [dropdown]="true" placeholder="{{placeholder | translate}}" [suggestions]="suggestions" (completeMethod)="complete($event)" (onSelect)="select($event)">
            <ng-template let-requerimientoPrefijo pTemplate="item">
                <requerimientoPrefijo-line [requerimientoPrefijo]="requerimientoPrefijo"></requerimientoPrefijo-line>
            </ng-template>
        </p-autoComplete>
        <label *ngIf="label" for="{{inputId}}" [ngClass]="paramStyleClass">{{label | translate}}:</label>
    </span>
	`,
	selector: 'requerimientoPrefijo-auto-complete',
    providers: [REQUERIMIENTOPREFIJO_AUTO_COMPLETE_CONTROL_VALUE_ACCESSOR]
})
export class RequerimientoPrefijoCompleteComponent implements ControlValueAccessor{
    @Input() disabled : boolean = false;
    @Input() id : string;
    @Input() name : string;
    @Input() label : string;
    @Input() inputId : string;
    @Input() paramStyleClass : string;
    @Input() paramInputStyleClass : string = null;
    @Input() requerido : boolean = false;
    @ViewChild('requerimientoPrefijoId', {static: false}) auto:any;
    //The internal data model
    private _value: RequerimientoPrefijo = null;
    placeholder:string = null;
    public suggestions : RequerimientoPrefijo[] = [];
    menuRuta = 'requerimientoPrefijo-list';
    //Placeholders for the callbacks
    private _onTouchedCallback: () => void = () => {};
    private _onChangeCallback: (_:any) => void = () => {};

    constructor(private requerimientoPrefijoService : RequerimientoPrefijoService, private messageService : MsgService, private authService : AuthService) {
    }

    @Input()
    get value(): any { return this.setInput(); };

    //set accessor including call the onchange callback
    set value(v: any) {
        if (this._value != null && (v == null || v == "")) {
            this.select(null);
        }
        // nop, see writeValue and select method
    }

    //Set touched on blur
    onTouched(){
        this._onTouchedCallback();
    }

    //From ControlValueAccessor interface
    writeValue(value: any) {
        this._value = <RequerimientoPrefijo> new RequerimientoPrefijo(value);
    }

    //From ControlValueAccessor interface
    registerOnChange(fn: any) {
        this._onChangeCallback = fn;
    }

    //From ControlValueAccessor interface
    registerOnTouched(fn: any) {
        this._onTouchedCallback = fn;
    }

    //From ControlValueAccessor interface
    setDisabledState(isDisabled: boolean) {
    }

    async complete(event:any) {
      if(!this._value){ this.select(null) }
      let requerimientoPrefijo = new RequerimientoPrefijo();
      if(event.originalEvent instanceof FocusEvent || event.originalEvent instanceof PointerEvent){
        requerimientoPrefijo.prefijo = null;  
      }else if(event.originalEvent instanceof InputEvent){
        requerimientoPrefijo.prefijo = event.query;
      }
      /*       let columnsString = this.authService.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string'); 
      let flagIdioma = false;     
      for (let item of columnsString) {
        if(item.codeLanguage == this.authService.globalIdioma.code){
          requerimientoPrefijo[item.field] = event.query;  
          break;
        }
      }    
      if(!flagIdioma && columnsString.length > 0){
        requerimientoPrefijo[columnsString[0].field] = event.query;  
      }  */  
      try{
        this.suggestions = RequerimientoPrefijo.toArray(await this.requerimientoPrefijoService.complete(requerimientoPrefijo));
      }catch(error){
        this.messageService.customError("RequerimientoPrefijo:","Problema al obtener los registros", error);
      }
    }

    select(v : any) {
      this._value = v;
      this._onChangeCallback(v);
    }

    setPlaceholder(){
      if(this.placeholder == null){
        this.placeholder = "lblPlaceHolderAutoComplete";
      }else{
        this.placeholder = null;
      }
    }
    
    setInput(){
      if(this._value && this._value?.id){
        return this._value.toString();
      }
      return null;
		}
}
