//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-line.component.ts.e.vm
//
import {Component, Input} from '@angular/core';
import {Calendario} from './calendario';

@Component({
	template: `
{{ calendario?.detalle }} 
{{ calendario?.enlaceInterno }} 
{{ calendario?.enlaceExterno }} 
	`,
	selector: 'calendario-line',
})
export class CalendarioLineComponent {
    @Input() calendario : Calendario;
}



