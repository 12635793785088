//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { Config } from './config';
import { ConfigService } from './config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	templateUrl: 'config-detail.component.html',
	selector: 'config-detail',
})
export class ConfigDetailComponent implements OnInit{

    @ViewChild('logoBinary', {static: false}) logoBinary:any;
    @ViewChild('faviconBinary', {static: false}) faviconBinary:any;
    @ViewChild('configForm', {static: false}) configForm:any;
    config : Config;

    private params_subscription: any;
    guardo: boolean = false;
    displayLoading = false;
    emitirSave:boolean = true;
    objectURLLogoBinary: any = null;
    fileSelectedLogoBinary: boolean = false;
    isImageLogoBinary: boolean = false;
    chooseValueLogoBinary: any = null;
    objectURLFaviconBinary: any = null;
    fileSelectedFaviconBinary: boolean = false;
    isImageFaviconBinary: boolean = false;
    chooseValueFaviconBinary: any = null;
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_config';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new Config
    set selectConfig(config : Config) {
      if(this.sub){
        this.config = config;
      }
    }

    @Output() onSaveClicked = new EventEmitter<Config>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'titulo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'nombreEmpresa': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'nombreSistema': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'frase': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'logoBinary': [
    ],
      'logoFileName': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'logoContentType': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'logoSize': [
    ],
      'cssColor1': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'cssColor2': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'cssColor3': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'cssColor4': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'cssColor5': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'direccion': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'telefono': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'footer': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'urlFacebook': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'urlInstagram': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'urlTwitter': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'faviconBinary': [
    ],
      'faviconFileName': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'faviconContentType': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'faviconSize': [
    ],
      'appKey': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'rutaBase': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'usuarioCorreo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'pwdCorreo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'servidorCorreo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'puertoCorreo': [
    ],
      'dominio': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'versionSistema': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private sanitizer: DomSanitizer, private configService: ConfigService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.config == undefined && this.permisos.crear){
            this.config = new Config();
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.config = new Config();
            this.editContent = this.permisos.crear;
          } else {
            this.config = new Config(await this.configService.getConfig(id));
          }
          if(this.config.logoFileName != undefined || this.config.logoFileName != null){
            this.chooseValueLogoBinary = this.config.logoFileName
          }
          if(this.config.faviconFileName != undefined || this.config.faviconFileName != null){
            this.chooseValueFaviconBinary = this.config.faviconFileName
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("Config"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'configKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.configForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.configForm.form.valid){
        this.messageService.clear();
        try{
          this.displayLoading = true;
          this.config = new Config(await this.configService.update(this.config, this.logoBinary.files[0], this.faviconBinary.files[0]));
          this.removeFileLogoBinary();
          this.removeFileFaviconBinary();
          this.guardo = true;

          if(!this.sub){
            this.displayLoading = false;
            this.messageService.success(this.translate.instant("Config"), this.translate.instant("lblGuardo"));
            return this.config;
          }else{
            this.onSaveClicked.emit(this.config);
          }
        }catch(error){
          this.displayLoading = false;
          this.messageService.customError(this.translate.instant("Config"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    downloadFormat(file, name){
      var fileURL = window.URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', name);
      tempLink.click();
    }
    downloadLogoBinary(){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(this.config.logoFileName);
      let http = this.configService.downloadLogoBinary(this.config).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,this.config.logoFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    selectFileLogoBinary(fileTMP){
      this.fileSelectedLogoBinary = true;
      this.chooseValueLogoBinary = this.logoBinary.files[0].name;
      if(this.logoBinary.isImage(this.logoBinary.files[0])){
        this.isImageLogoBinary=true;
        this.objectURLLogoBinary = this.sanitizer.bypassSecurityTrustUrl(fileTMP.currentFiles[0].objectURL.changingThisBreaksApplicationSecurity);
      }
    }

    removeFileLogoBinary(){
      this.logoBinary.clear(); 
      this.fileSelectedLogoBinary = false; 
      this.isImageLogoBinary = false;
      if(this.config.logoFileName != undefined || this.config.logoFileName != null){
        this.chooseValueLogoBinary = this.config.logoFileName;
      }else{
        this.chooseValueLogoBinary = null;
      }
    }  
      
    downloadFaviconBinary(){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(this.config.faviconFileName);
      let http = this.configService.downloadFaviconBinary(this.config).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,this.config.faviconFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    selectFileFaviconBinary(fileTMP){
      this.fileSelectedFaviconBinary = true;
      this.chooseValueFaviconBinary = this.faviconBinary.files[0].name;
      if(this.faviconBinary.isImage(this.faviconBinary.files[0])){
        this.isImageFaviconBinary=true;
        this.objectURLFaviconBinary = this.sanitizer.bypassSecurityTrustUrl(fileTMP.currentFiles[0].objectURL.changingThisBreaksApplicationSecurity);
      }
    }

    removeFileFaviconBinary(){
      this.faviconBinary.clear(); 
      this.fileSelectedFaviconBinary = false; 
      this.isImageFaviconBinary = false;
      if(this.config.faviconFileName != undefined || this.config.faviconFileName != null){
        this.chooseValueFaviconBinary = this.config.faviconFileName;
      }else{
        this.chooseValueFaviconBinary = null;
      }
    }  
      
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
