//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/custom/auditoria-historial.component.ts.p.vm
//

//
// Project home: https://github.com/jaxio/celerio-angular-quickstart
// 
// Source code generated by Celerio, an Open Source code generator by Jaxio.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Source code: https://github.com/jaxio/celerio/
// Follow us on twitter: @jaxiosoft
// This header can be customized in Celerio conf...
// Template pack-angular:web/src/app/custom/auditoria-historial.component.ts.p.vm
//

import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { LazyLoadEvent } from 'primeng/api';
import { PageResponse } from "../../support/paging";
import { MsgService } from '../../service/message.service';
import { AuditoriaSistema } from '../../entities/auditoriaSistema/auditoriaSistema';
import { AuditoriaSistemaDetailComponent } from '../../entities/auditoriaSistema/auditoriaSistema-detail.component';
import { AuditoriaSistemaService } from '../../entities/auditoriaSistema/auditoriaSistema.service';
//import { AmChartsService, AmChart } from "@amcharts/amcharts3-angular";
import { TranslateService } from '@ngx-translate/core';

@Component({
	templateUrl: 'auditoria-historial.component.html',
	selector: 'auditoria-historial'
})
export class AuditoriaHistorialComponent {
    @Input() header = "tltAuditoriaHistorialCustom";
    @Input() sub : boolean;
    @Output() onAddNewClicked = new EventEmitter();

    optionsColumns: any;
    originalColumns:any;
    cols:any [] = [];

    auditoriaSistemaToDelete : AuditoriaSistema;

    // basic search criterias (visible if not in 'sub' mode)
    example : AuditoriaSistema = new AuditoriaSistema();
    activeFilter : boolean = true;
    rangeNumber:any={};
    filterRangeNumber:any={};
    filterRangeDate:any={};
    menuRuta: any = 'auditoria-historial';
    public es: any = {firstDayOfWeek: 1,dayNames: [ "domingo","lunes","martes","miércoles","jueves","viernes","sábado" ],dayNamesShort: [ "dom","lun","mar","mié","jue","vie","sáb" ],dayNamesMin: [ "D","L","MA","MI","J","V","S" ],monthNames: [ "enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre" ],monthNamesShort: [ "ene","feb","mar","abr","may","jun","jul","ago","sep","oct","nov","dic" ]};
    // list is paginated
    currentPage : PageResponse<AuditoriaSistema> = new PageResponse<AuditoriaSistema>(0,0,[]);
    //private chart: AmChart;
    rangeDates: Date[];
    key_sessionStorage = 'stateSessionAuditoriaSistema';
    sortOrder:any;
sortField:any;
loadColums = false;
    
    constructor(private router : Router,
        private translate: TranslateService,
        private auditoriaSistemaService : AuditoriaSistemaService,
        private messageService : MsgService,
        //private AmCharts: AmChartsService,
        private authService : AuthService) {
    }
    //carga inicial de las columnas de la tabla segun usuario conectado o por default
    async ngOnInit(){
      let filters = JSON.parse(sessionStorage.getItem(this.key_sessionStorage));
      if(filters){
        this.example = filters.example;
        this.sortField = filters.sortField;
        this.sortOrder = filters.sortOrder;
      }
      //this.authService.getPermisosPorEntidad(this.keyEntity).then(resp=>{this.permisos = resp});
      if(this.authService.globalColumns == undefined){
        await this.authService.setGlobalColumnsfromDB();
      }
      this.originalColumns = this.authService.globalColumns[this.menuRuta];           
      this.optionsColumns = this.originalColumns.filter(col => col.delete == false);
      for(let cl of this.optionsColumns){
        if(!cl.delete && cl.default){
          this.cols.push(cl);
        }
      }
    }
    /**
     * When used as a 'sub' component (to display one-to-many list), refreshes the table
     * content when the input changes.
     */
    ngOnChanges(changes: SimpleChanges) {
        //this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
    }

    viewFilter(){
      if(this.activeFilter)
        this.activeFilter=false;
      else
        this.activeFilter=true;
    }
    clean(dt : any){
      //dt.reset();
      this.example = new AuditoriaSistema();
      this.filterRangeDate = {};
      this.filterRangeNumber = {}
      this.rangeNumber = {}

      dt.filters = {};
      sessionStorage.removeItem("filterSession");
      dt.reset();
      this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
    }

    setFilterRangeNumber(field, value, posicion){
      if(this.filterRangeNumber[field] == null){
        this.filterRangeNumber[field] = [null,null]
      }
      this.filterRangeNumber[field][posicion] = value;
    }

    //guarda la seleccion de columnas para perdurar en proximas conexiones del usuario
    async saveSelectedColumnsList(){
      this.originalColumns.filter(org => {
        org.default = false;
        for(let col of this.cols){
            if(org.id == col.id){
                org.default = true;
            }
        }
      });
      await this.authService.saveColumns(this.originalColumns,4);
    }
    //guarda los anchos de las columnas por usuario
    async saveWidthColumnsList(e){
      let newWidth = (parseFloat(e.element.attributes.style.value.match(/[\d\.]+/))/16).toString() + "em";
      let idResize = e.element.attributes[1].value;
      this.originalColumns.filter(org => {
        for(let col of this.cols){
            if(org.id == idResize){
              org.width = newWidth;
            }
        }
      });  
      await this.authService.saveColumnsByRuta(this.originalColumns,this.menuRuta);
    }    
    /**
     * Invoked when user presses the search button.
     */
    /*search(dt : any) {
        if (!this.sub) {
          dt.reset();
          this.loadPage({ first: 0, rows: dt.rows, sortField: dt.sortField, sortOrder: dt.sortOrder, filters: null, multiSortMeta: dt.multiSortMeta });
        }
    }*/

    /**
     * Invoked automatically by primeng datatable.
     */
    async loadPage(event : LazyLoadEvent) {
      try{
        let page:any = await this.auditoriaSistemaService.getPage(this.example, event, this.filterRangeDate, this.filterRangeNumber);
        this.currentPage = new PageResponse<AuditoriaSistema>(page.totalPages, page.totalElements, AuditoriaSistema.toArray(page.content));
        let datosGraficos:any;
        datosGraficos = await this.auditoriaSistemaService.getGraficoAudit(this.example, this.filterRangeDate);
        let formatoDato = {"category":new Date().toISOString(),"Agregados":"0","Modificados":"0","Eliminados":"0"};
        let datos = [];
        if(datosGraficos.length >0){
          for(let dato of datosGraficos){
            formatoDato = {"category":"","Agregados":"","Modificados":"","Eliminados":""};
            formatoDato.category = dato[0];
            formatoDato.Agregados = dato[3];
            formatoDato.Modificados = dato[1];
            formatoDato.Eliminados = dato[2];
            datos.push(formatoDato);
          }
        }else{
          datos.push(formatoDato);
        }
        this.graficoBase(datos);
      }catch(error){
        this.messageService.customError(this.translate.instant("Auditoria"),this.translate.instant("lblErrorNgOnInit"), error);
      }

    }

    //Registra los atributos al Session Storage para mantener los filtros en la sesión
    setFilterTable(dt){       
      let filters = {
        'example':this.example,
        'sortOrder':dt.sortOrder,
        'sortField':dt.sortField
      }
      sessionStorage.setItem(this.key_sessionStorage,JSON.stringify(filters))
      this.loadPage({ first: 0, rows: dt.rows, sortField: dt.sortField, sortOrder: dt.sortOrder, filters: null, multiSortMeta: dt.multiSortMeta });      
    }

    addNew() {
        if (this.sub) {
            this.onAddNewClicked.emit("addNew");
        } else {
            this.router.navigate(['/auditoriaSistema', 'new']);
        }
    }

    async getCSV(){
      let csv:any = await this.auditoriaSistemaService.getCSVGlobalAudit(this.example);
      var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
      var csvURL = window.URL.createObjectURL(csvData);
      var tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', this.translate.instant("Auditoria")+'.csv');
      tempLink.click();
    }

    graficoBase(datos) {
      //   this.chart = this.AmCharts.makeChart("chartdiv", {
			// 		"type": "serial",
			// 		"categoryField": "category",
			// 		"dataDateFormat": "YYYY-MM-DD",
			// 		"startDuration": 1,
			// 		"categoryAxis": {
			// 			"gridPosition": "start",
      //       "minPeriod": "MM",
			// 			"parseDates": true
			// 		},
			// 		"chartCursor": {
			// 			"enabled": true
			// 		},
			// 		"chartScrollbar": {
			// 			"enabled": true
			// 		},
			// 		"trendLines": [],
      //     "legend": {
      //   		"enabled": true,
      //   		"useGraphSettings": true
      //   	},
			// 		"graphs": [
			// 			{
			// 				"fillAlphas": 1,
			// 				"fillColors": "#72ED72",
			// 				"id": "AmGraph-1",
			// 				"lineColor": "#72ED72",
			// 				"title": this.translate.instant("lblAgregados"),
			// 				"type": "column",
			// 				"valueField": "Agregados"
			// 			},
			// 			{
			// 				"fillAlphas": 1,
			// 				"fillColors": "#F49D34",
			// 				"fontSize": -4,
			// 				"id": "AmGraph-2",
			// 				"lineColor": "#F49D34",
			// 				"title": this.translate.instant("lblModificados"),
			// 				"type": "column",
			// 				"valueField": "Modificados"
			// 			},
			// 			{
			// 				"fillAlphas": 1,
			// 				"fillColors": "#FF0000",
			// 				"id": "AmGraph-3",
			// 				"lineColor": "#FF0000",
			// 				"title": this.translate.instant("lblEliminados"),
			// 				"type": "column",
			// 				"valueField": "Eliminados"
			// 			}
			// 		],
			// 		"guides": [],
			// 		"valueAxes": [
			// 			{
			// 				"id": "ValueAxis-1",
			// 				"title": this.translate.instant("lblNAcciones"),
			// 			}
			// 		],
			// 		"allLabels": [],
			// 		"balloon": {},
			// 		"titles": [
			// 			{
			// 				"id": "Title-1",
			// 				"size": 15,
			// 				"text": this.translate.instant("lblGraficoAuditoria"),
			// 			}
			// 		],
			// 		"dataProvider": datos
			// 	});
       }
}
