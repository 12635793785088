//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { Area } from './area';
import { AreaService } from './area.service';
import { TranslateService } from '@ngx-translate/core';
import { Contrato } from '../contrato/contrato';

@Component({
	templateUrl: 'area-detail.component.html',
	selector: 'area-detail',
})
export class AreaDetailComponent implements OnInit{

    @ViewChild('areaForm', {static: false}) areaForm:any;
    area : Area;

    private params_subscription: any;
    guardo: boolean = false;
    editContent : boolean = false;
    isAdmin : boolean = false;
    permisos:any;
    userContratos:Contrato[];
    keyEntity = 'key_area';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new Area
    set contrato(contrato : Contrato) {
      if(this.area == undefined){
        this.area = new Area();
      }
      this.area.contrato = contrato;
    }

    @Input() // used to pass the parent when creating a new Area
    set selectArea(area : Area) {
      if(this.sub){
        this.area = area;
      }
    }

    @Output() onSaveClicked = new EventEmitter<Area>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'nombreEs': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'nombreEn': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'codigo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'borrado': [
    ],
      'contrato': [
      ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private areaService: AreaService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
      this.isAdmin = this.authService.getIsAdmin();
      this.userContratos = this.isAdmin ? null : this.authService.globalUser.contratos;
      
        if (this.sub) {
          if(this.area == undefined && this.permisos.crear){
            this.area = new Area();
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.area = new Area();
            this.area.contrato = this.authService.globalContrato ? new Contrato(this.authService.globalContrato) : null;
            this.editContent = this.permisos.crear;
          } else {
            this.area = new Area(await this.areaService.getArea(id));
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("Area"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'areaKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.areaForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.areaForm.form.valid){
        this.messageService.clear();
        try{
          this.area = new Area(await this.areaService.update(this.area));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("Area"), this.translate.instant("lblGuardo"));
            this.volver();
            return this.area;
          }else{
            this.onSaveClicked.emit(this.area);
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("Area"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
