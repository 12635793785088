//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/custom/menu-dnd.component.ts.p.vm
//

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { Menu } from '../../entities/menu/menu';
import { MenuService } from '../../entities/menu/menu.service';
import { TreeNode } from 'primeng/api';
import { AuthService } from 'app/service/auth.service';

@Component({
	templateUrl: 'menu-dnd.component.html',
	selector: 'menu-dnd',
})
export class MenuDNDComponent implements OnInit {
    menu : Menu;
    guardo: boolean = false;
    files:TreeNode[] = [];
    sub:boolean = false;

    constructor(public authService: AuthService,private confirmationService: ConfirmationService, private route: ActivatedRoute, private router: Router, private messageService: MsgService, private menuService: MenuService) {
    }

    ngOnInit() {
      this.cargaMenu();
    }

    async cargaMenu(){
      this.files = await this.menuService.getMenuEditByRol();
      /*for(let item of menu){
        this.files.push(JSON.parse(item));
      }*/
      console.log(this.files);
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: 'No ha guardado, se perderan los cambios al salir de la ventana. Desea salir?',
            header: 'Confirmación',
            icon: 'fa fa-question-circle',
            key: 'menuKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }
    // falta resolver el problema con el ordenamiento en la DB
    async onDrop(event:any){
     /* let menuDrag:Menu = await this.menuService.getMenu(event.dragNode.data.id);
      let menuDrop:Menu = await this.menuService.getMenu(event.dropNode.data.id);
      console.log(event)
      if((!event.dragNode.parent && !event.dropNode.parent) || (event.dragNode.parent && event.dropNode.parent)){ //padre a padre
        menuDrag.orden = event.dropNode.data.orden - 1;
      }else if(event.dragNode.parent && !event.dropNode.parent){ // hijo a padre
        menuDrag.menu2 = new Menu();
        menuDrag.orden = event.dropNode.data.orden - 1;
      }else if(!event.dragNode.parent && event.dropNode.parent){ // padre a hijo
        menuDrag.menu2 = new Menu();
        menuDrag.menu2.id = event.dropNode.data.menu_id;
        menuDrag.orden = event.dropNode.data.orden - 1;
      }
      try{
        console.log(menuDrag)
        console.log(menuDrop)
        await this.menuService.update(menuDrag);
        await this.menuService.update(menuDrop);
        event.accept();
      }catch(error){
        console.log(error);
      }
      */
     event.accept();
    }
    onSave() {
      console.log(this.files)
        // this.menuService.update(this.menu).
        //     subscribe(
        //         menu => {
        //             this.menu = menu;
        //             this.guardo = true;
        //             this.messageService.success('Guardo correctamente', '');
        //             this.volver();
        //         },
        //         error =>  this.messageService.customError("","",error)
        //     );
    }



}
