//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { RequerimientoArchivo } from './requerimientoArchivo';
import { RequerimientoArchivoService } from './requerimientoArchivo.service';
import { TranslateService } from '@ngx-translate/core';
import { Requerimiento } from '../requerimiento/requerimiento';
import { RequerimientoTipo } from '../requerimientoTipo/requerimientoTipo';

import { Usuario } from '../usuario/usuario';
import { ArchivoAnexoDetailComponent } from '../archivoAnexo/archivoAnexo-detail.component';
import { ArchivoAnexo } from '../archivoAnexo/archivoAnexo';
import { ArchivoAnexoService } from '../archivoAnexo/archivoAnexo.service';
//import { UploadEvent } from 'primeng/fileupload';

@Component({
	templateUrl: 'requerimientoArchivo-detail.component.html',
	selector: 'requerimientoArchivo-detail',
  styleUrls: ['requerimientoArchivo.component.scss']
})
export class RequerimientoArchivoDetailComponent implements OnInit{

    @ViewChild('archivoBinary', {static: false}) archivoBinary:any;
    @ViewChild('anexoBinary', {static: false}) anexoBinary:any;
    @ViewChild('requerimientoArchivoForm', {static: false}) requerimientoArchivoForm:any;
    @ViewChild(ArchivoAnexoDetailComponent, {static: false}) archivoAnexoChild:ArchivoAnexoDetailComponent;
    requerimientoArchivo : RequerimientoArchivo;

    private params_subscription: any;
    guardo: boolean = false;
    showArchivoAnexo : boolean = true;
    selectArchivoAnexo :any;
    displayLoading = false;
    emitirSave:boolean = true;
    objectURLArchivoBinary: any = null;
    fileSelectedArchivoBinary: boolean = false;
    isImageArchivoBinary: boolean = false;
    chooseValueArchivoBinary: any = null;
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_requerimientoArchivo';
    @Input() sub : boolean = false;
    @Input() editContentSub : boolean = true;
    @Input() cierreRequerimiento : boolean = false;
    @Input() // used to pass the parent when creating a new RequerimientoArchivo
    set requerimiento(requerimiento : Requerimiento) {
      if(this.requerimientoArchivo == undefined){
        this.requerimientoArchivo = new RequerimientoArchivo();
      }
      this.requerimientoArchivo.requerimiento = requerimiento;
    }

    @Input() // used to pass the parent when creating a new RequerimientoArchivo
    set requerimientoTipo(requerimientoTipo : RequerimientoTipo) {
      if(this.requerimientoArchivo == undefined){
        this.requerimientoArchivo = new RequerimientoArchivo();
      }
      this.requerimientoArchivo.requerimientoTipo = requerimientoTipo;
    }

    @Input() // used to pass the parent when creating a new RequerimientoArchivo
    set usuario(usuario : Usuario) {
      if(this.requerimientoArchivo == undefined){
        this.requerimientoArchivo = new RequerimientoArchivo();
      }
      this.requerimientoArchivo.usuario = usuario;
    }

    @Input() // used to pass the parent when creating a new RequerimientoArchivo
    set selectRequerimientoArchivo(requerimientoArchivo : RequerimientoArchivo) {
      if(this.sub){
        this.requerimientoArchivo = requerimientoArchivo;        
      }
    }

    @Output() onSaveClicked = new EventEmitter<RequerimientoArchivo>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'fechaRegistro': [
    ],
      'archivoFileName': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'archivoContentType': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'archivoSize': [
    ],
      'archivoBinary': [
        { type: 'required', message: 'lblRequerido' },
    ],
      'archivoRuta': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'borrado': [
    ],
      'firmado': [
    ],
      'requerimiento': [
      ],
      'requerimientoTipo': [
        { type: 'required', message: 'lblRequerido' },
      ],
      'usuario': [
      ],
    }

    listadoAnexos = [];
    anexoView;
    urlAnexoView;
    showDialogPreView = false;
    formato = null;
    archivoView;
    urlArchivoView;
    showDialogPreViewArchivo = false;
    isProgrammaticClick: boolean = false; //se usa para validar la confirmación del reemplazo del archivo
    constructor(private archivoAnexoService:ArchivoAnexoService, private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private sanitizer: DomSanitizer, private requerimientoArchivoService: RequerimientoArchivoService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.requerimientoArchivo == undefined && this.permisos.crear){
            this.requerimientoArchivo = new RequerimientoArchivo();
            this.requerimientoArchivo.fechaRegistro = new Date();            
            this.requerimientoArchivo.usuario = this.authService.globalUser;
          }else if(this.requerimientoArchivo.id){
            let anexo = new ArchivoAnexo();
            anexo.archivo =  this.requerimientoArchivo;            
            this.listadoAnexos = await this.archivoAnexoService.complete(anexo);               
          }      
          if(!this.editContentSub){            
            this.permisos.crear = false;
            this.permisos.eliminar = false;
            this.permisos.actualizar = false;
            this.editContent = false;
          }else{
            this.editContent = (this.permisos.crear || this.permisos.actualizar);
          }
          if(this.requerimientoArchivo.archivoFileName){
            this.chooseValueArchivoBinary = this.requerimientoArchivo.archivoFileName
          }                        
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.requerimientoArchivo = new RequerimientoArchivo();
            this.requerimientoArchivo.usuario = this.authService.globalUser;
            this.requerimientoArchivo.fechaRegistro = new Date();
            //this.requerimientoArchivo.firmado = true;
            this.editContent = this.permisos.crear;
          } else {
            this.requerimientoArchivo = new RequerimientoArchivo(await this.requerimientoArchivoService.getRequerimientoArchivo(id));                        
          }          
          if(this.requerimientoArchivo.archivoFileName != undefined || this.requerimientoArchivo.archivoFileName != null){
            this.chooseValueArchivoBinary = this.requerimientoArchivo.archivoFileName
          }
          
          
        }catch(error){
          this.messageService.customError(this.translate.instant("RequerimientoArchivo"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    
    showConfirmReplaceFile(event, archivoBinary){
      if (this.isProgrammaticClick) {
        this.isProgrammaticClick = false; // Restablece el flag
        return;
      }
      if(!this.requerimientoArchivo.id){
        return;
      }
      event.preventDefault();    
      this.confirmationService.confirm({
        target: this.archivoBinary.el.nativeElement,
        message: this.translate.instant("msgRemplazarArchivo",{nameFile:this.chooseValueArchivoBinary}),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          //this.removeFileArchivoBinary();          
          this.isProgrammaticClick = true;
          this.archivoBinary.basicFileInput.nativeElement.click();        
        },        
        reject: () => {
            //reject action
        }
      });

    }
    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'requerimientoArchivoKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    
    async onSave() {
      this.requerimientoArchivoForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.requerimientoArchivoForm.form.valid && (this.requerimientoArchivo.archivoFileName || this.chooseValueArchivoBinary)){
        this.messageService.clear();
        try{
          this.displayLoading = true;
          this.requerimientoArchivo.tieneAnexos = this.anexoBinary.files.length > 0 || this.listadoAnexos.length > 0;
          this.requerimientoArchivo = new RequerimientoArchivo(await this.requerimientoArchivoService.update(this.requerimientoArchivo, this.fileSelected));
          for(let anexoFile of this.anexoBinary.files){
            let archivoAnexo = new ArchivoAnexo();
            archivoAnexo.requerimiento = this.requerimientoArchivo.requerimiento;
            archivoAnexo.fechaRegistro = new Date();
            archivoAnexo.requerimientoTipo = this.requerimientoArchivo.requerimientoTipo;
            archivoAnexo.usuario = this.requerimientoArchivo.usuario;
            archivoAnexo.archivo = this.requerimientoArchivo;
            let anexo = new ArchivoAnexo(await this.archivoAnexoService.update(archivoAnexo, anexoFile));
            this.listadoAnexos.push(anexo);
            
          }
          //console.log("🚀 ~ file: requerimientoArchivo-detail.component.ts:190 ~ RequerimientoArchivoDetailComponent ~ onSave ~ this.listadoAnexos:", this.listadoAnexos)
          this.removeFileArchivoBinary();
          this.anexoBinary.clear();
          this.guardo = true;
          if(!this.showArchivoAnexo){
            this.archivoAnexoChild.archivo = this.requerimientoArchivo;
            this.archivoAnexoChild.onSave();
          }

          if(!this.sub){
            this.displayLoading = false;
            this.messageService.success(this.translate.instant("RequerimientoArchivo"), this.translate.instant("lblGuardo"));
            return this.requerimientoArchivo;
          }else{
            this.displayLoading = false;
            this.onSaveClicked.emit(this.requerimientoArchivo);
            return this.requerimientoArchivo;
          }
        }catch(error){
          this.displayLoading = false;       
             
          this.messageService.customError(this.translate.instant("RequerimientoArchivo"),this.translate.instant("lblProblemaGuardar"), error);
          return false;
        }
      }else{        
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
        return false;
      }
    }
    downloadFormat(file, name){
      var fileURL = window.URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', name);
      tempLink.click();
    }
    downloadArchivoBinary(){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(this.requerimientoArchivo.archivoFileName);
      let http = this.requerimientoArchivoService.downloadArchivoBinary(this.requerimientoArchivo).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,this.requerimientoArchivo.archivoFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    downloadAnexoBinary(anexo){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(anexo.archivoAnexoFileName);
      let http = this.archivoAnexoService.downloadArchivoAnexoBinary(anexo).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,anexo.archivoAnexoFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    
    showAnexoView(anexo){
      this.anexoView = anexo;
      if(anexo.archivoAnexoContentType == "application/pdf"){
        this.formato = anexo.archivoAnexoContentType;
      }else if(anexo.archivoAnexoContentType.includes('image')){
        this.formato = "images";
      }else{
        this.formato = "FNS";//Formato no soportado
      }
      this.urlAnexoView = "/api/archivoAnexos/"+anexo.id+"/download/archivoAnexoBinary";
      this.showDialogPreView = true;
    }

    showPreViewArchivo(data){
      this.archivoView = data;
      if(data.archivoContentType == "application/pdf"){
        this.formato = data.archivoContentType;
      }else if(data.archivoContentType.includes('image')){
        this.formato = "images";
      }else{
        this.formato = "FNS";//Formato no soportado
      }
      this.urlArchivoView = "/api/requerimientoArchivos/"+data.id+"/download/archivoBinary";
      this.showDialogPreViewArchivo = true;
    }

    fileSelected;
    maxSizeFile = 200000000; //aplica para archivo y anexos (200MB)
    showErrorSize = false;
    selectFileArchivoBinary(fileTMP){
      if(this.archivoBinary.files[0].size > this.maxSizeFile){//excede el tamaño maximo permitido
        this.showErrorSize = true;
        this.removeFileArchivoBinary();
        return;
      }

      if(this.archivoBinary.files.length > 0){
        this.fileSelectedArchivoBinary = true;
        this.showErrorSize = false;
        this.chooseValueArchivoBinary = this.archivoBinary.files[0].name;
        this.fileSelected = this.archivoBinary.files[0];      
        this.requerimientoArchivo.firmado = this.cierreRequerimiento;
        if(this.archivoBinary.isImage(this.archivoBinary.files[0])){
          this.isImageArchivoBinary=true;
          this.objectURLArchivoBinary = this.sanitizer.bypassSecurityTrustUrl(fileTMP.currentFiles[0].objectURL.changingThisBreaksApplicationSecurity);
        }
        this.archivoBinary.clear() //el archivo queda almacenado en "fileSelected"
      }else{
        this.removeFileArchivoBinary();
      }      
      
    }

    removeFileArchivoBinary(){
      this.archivoBinary.clear(); 
      this.fileSelectedArchivoBinary = false; 
      this.isImageArchivoBinary = false;      
      this.requerimientoArchivo.firmado = false;
      this.fileSelected = null;
      if(this.requerimientoArchivo.archivoFileName != undefined || this.requerimientoArchivo.archivoFileName != null){
        this.chooseValueArchivoBinary = this.requerimientoArchivo.archivoFileName;
      }else{
        this.chooseValueArchivoBinary = null;
      }
    }  
      
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
