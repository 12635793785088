//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/service/formatNumber.service.ts.p.vm
//
import { Injectable } from '@angular/core'

@Injectable()
export class FormatNumber {
  constructor(){}

  //obtiene numero entero y elimina el separador de miles
  cleanFormat(value){
    let arr = value.split(",");
    value = arr[0].replace(/\./g,"");
    return value;
  }
  
  //separador de miles (.)  sin decimales
  formatInt(element,n?:number):any{
    let value = element.value.replace(/[^0-9]/g,""); //elimina todos los caracteres excepto numeros
    let int = Number(value); //convierte string a numero para la suma/resta del btn (de lo contrario concatena con texto)
    if(n != undefined){
      int = int + n;
    }
    let result = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); // separador de miles
    element.value = result; //cambia valor al input
    return result; // retorna valor para editar la entidad
  }

  //separador de miles (.) y decimales (,) (max 2 decimales)
  formatDecimal(element,n?:number):any{
    let value = element.value.replace(/[^0-9,]/g,""); //elimina todos los caracteres excepto numeros y ","
    let arr = value.replace(/\./g,"").split(","); //separa el numero entero del decimal
    if(arr.length>2){ //evita multiples "," (decimales)
      arr.pop();
    }
    let int = Number(arr[0]); //convierte string a numero para la suma/resta del btn (de lo contrario concatena con texto)
    if(n != undefined){
      int = int + n;
    }
    let intFormat = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); // separador de miles
    let decimal = arr.length > 1 ? arr[1]:''; //valida si existe decimal
    let result;
    if(arr.length > 1){
      result = intFormat+","+(decimal.length > 2 ? decimal.substring(0,2):decimal); //validacion max 2 decimales
    }else{
      result = intFormat;
    }
    element.value = result; //cambia valor al input
    return result; // retorna valor para editar la entidad
  }
}
