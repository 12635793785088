//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { Feriado } from './feriado';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class FeriadoService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a Feriado by id.
     */
    getFeriado(id : any) {
        return this.http.get<Feriado>('/api/feriados/' + id).toPromise();
    }

    /**
     * Update the passed feriado.
     */
    update(feriado : Feriado) {
        let body = JSON.stringify(feriado);
        return this.http.post<Feriado>('/api/feriados/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of Feriado using the passed
     * feriado as an example for the search by example facility.
     */
     getPage(feriado : Feriado, event : LazyLoadEvent, columns, filterRangeDate?) {
        let req = new PageRequestByExample(feriado, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeDate != undefined){
          formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
        }
        return this.http.post('/api/feriados/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by FeriadoListComponent.
      */
    getCSV(feriado:Feriado, filterRangeDate?) {
      let body = JSON.stringify(feriado);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeDate != undefined){
        formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
      }
      return this.http.post('/api/feriados/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by FeriadoCompleteComponent.
     */
    complete(feriado : Feriado) {
        if(feriado == null){
          feriado = new Feriado();
        }
        let body = JSON.stringify(feriado);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<Feriado[]>('/api/feriados/complete', formData).toPromise();
    }
    completeCalendario(feriado : Feriado, rangeDate) {
      if(feriado == null){
        feriado = new Feriado();
      }
      let body = JSON.stringify(feriado);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"})); 
      formData.append('rangeDate',new Blob([JSON.stringify(rangeDate)],{type: "application/json"}));                
      return this.http.post<Feriado[]>('/api/feriados/complete-calendario', formData).toPromise();
  }
    /**
     * Delete an Feriado by id.
     */
    delete(id : any) {
        return this.http.delete('/api/feriados/' + id).toPromise();
    }
}
