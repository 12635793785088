//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService, MenuItem } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { Requerimiento } from './requerimiento';
import { RequerimientoService } from './requerimiento.service';
import { TranslateService } from '@ngx-translate/core';
import { Usuario } from '../usuario/usuario';
import { UsuarioService } from '../usuario/usuario.service';
import { Contrato } from '../contrato/contrato';
import { RequerimientoTipo } from '../requerimientoTipo/requerimientoTipo';
import { Etapa } from '../etapa/etapa';
import { RequerimientoPrefijo } from '../requerimientoPrefijo/requerimientoPrefijo';
import { Empresa } from '../empresa/empresa';
import { Area } from '../area/area';
import { Role } from '../role/role';
import { AntecedenteDetailComponent } from '../antecedente/antecedente-detail.component';
import { RequerimientoArchivoDetailComponent } from '../requerimientoArchivo/requerimientoArchivo-detail.component';
import { RequerimientoComentarioDetailComponent } from '../requerimientoComentario/requerimientoComentario-detail.component';
import { RequerimientoHistoricoDetailComponent } from '../requerimientoHistorico/requerimientoHistorico-detail.component';
import { RequerimientoInstruccionDetailComponent } from '../requerimientoInstruccion/requerimientoInstruccion-detail.component';
import { EtapaService } from '../etapa/etapa.service';
import { PrivilegiosEtapa } from 'app/support/privilegios-etapa.pipe';
import { RequerimientoHistorico } from '../requerimientoHistorico/requerimientoHistorico';
import { AccionEtapa } from '../accionEtapa/accionEtapa';
import { RequerimientoHistoricoService } from '../requerimientoHistorico/requerimientoHistorico.service';
import { tryCatch } from 'rxjs/internal-compatibility';
import { RequerimientoNuevoRegistro } from '../requerimientoNuevoRegistro/requerimientoNuevoRegistro';
import { RequerimientoNuevoRegistroService } from '../requerimientoNuevoRegistro/requerimientoNuevoRegistro.service';
import { Antecedente } from '../antecedente/antecedente';
import { RequerimientoArchivo } from '../requerimientoArchivo/requerimientoArchivo';
import { RequerimientoComentario } from '../requerimientoComentario/requerimientoComentario';
import { RequerimientoInstruccion } from '../requerimientoInstruccion/requerimientoInstruccion';
import { isNumber } from '@amcharts/amcharts4/core';
import { ContratoService } from '../contrato/contrato.service';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RequerimientoComentarioService } from '../requerimientoComentario/requerimientoComentario.service';
import { ContratoTipo } from '../contratoTipo/contratoTipo';

@Component({
	templateUrl: 'requerimiento-detail.component.html',
	selector: 'requerimiento-detail',
  styleUrls: ['requerimiento.component.scss']
})
export class RequerimientoDetailComponent implements OnInit{

    @ViewChild('requerimientoForm', {static: false}) requerimientoForm:any;    
    @ViewChild('antecedenteChild', {static: false}) antecedenteChild:AntecedenteDetailComponent;
    @ViewChild('requerimientoArchivoChild', {static: false}) requerimientoArchivoChild:RequerimientoArchivoDetailComponent;
    @ViewChild('requerimientoComentarioChild', {static: false}) requerimientoComentarioChild:RequerimientoComentarioDetailComponent;    
    @ViewChild('requerimientoInstruccionChild', {static: false}) requerimientoInstruccionChild:RequerimientoInstruccionDetailComponent;
    @ViewChild(RequerimientoHistoricoDetailComponent, {static: false}) requerimientoHistoricoChild:RequerimientoHistoricoDetailComponent;
    requerimiento : Requerimiento;

    private params_subscription: any;
    guardo: boolean = false;
    showAntecedente : boolean = true;
    selectAntecedente :any;
    showRequerimientoArchivo : boolean = true;
    selectRequerimientoArchivo :any;
    showRequerimientoComentario : boolean = true;
    selectRequerimientoComentario :any;
    showRequerimientoHistorico : boolean = true;
    selectRequerimientoHistorico :any;
    showRequerimientoInstruccion : boolean = true;
    selectRequerimientoInstruccion :any;
    showAntecedenteView : boolean = true;
    selectAntecedenteView :any;
    showRequerimientoArchivoView : boolean = true;
    selectRequerimientoArchivoView :any;
    showRequerimientoComentarioView : boolean = true;
    selectRequerimientoComentarioView :any;
    showRequerimientoHistoricoView : boolean = true;
    selectRequerimientoHistoricoView :any;
    showRequerimientoInstruccionView : boolean = true;
    selectRequerimientoInstruccionView :any;
    loading: boolean = false;
    showDialogReasignar = false;
    sourceConCopia : Usuario[] = [];
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_requerimiento';
    idEtapaActual;
    steps:any[];
    contratoTipoReq: number = -1;
    reqPasoAsignacionITE: boolean = false;
    contratoTipoNombreES: String = null;
    listContratosEP: Contrato[];
    sourceUsuario : Usuario[] = [];
    groupedUsuario: any = [];
    coordinadorOriginal:Usuario;
    comentarioReasignacion: string = null;
    @Input() sub : boolean = false;
    @Input() editContentSub : boolean = true;
    @Input() // used to pass the parent when creating a new Requerimiento
    set ingresadoPor(ingresadoPor : Usuario) {
      if(this.requerimiento == undefined){
        this.requerimiento = new Requerimiento();
      }
      this.requerimiento.ingresadoPor = ingresadoPor;
    }

    @Input() // used to pass the parent when creating a new Requerimiento
    set contrato(contrato : Contrato) {
      if(this.requerimiento == undefined){
        this.requerimiento = new Requerimiento();
      }
      this.requerimiento.contrato = contrato;
    }

    // @Input() // used to pass the parent when creating a new Requerimiento
    // set asistente(asistente : Usuario) {
    //   if(this.requerimiento == undefined){
    //     this.requerimiento = new Requerimiento();
    //   }
    //   this.requerimiento.asistente = asistente;
    // }

    @Input() // used to pass the parent when creating a new Requerimiento
    set requerimientoTipo(requerimientoTipo : RequerimientoTipo) {
      if(this.requerimiento == undefined){
        this.requerimiento = new Requerimiento();
      }
      this.requerimiento.requerimientoTipo = requerimientoTipo;
    }

    @Input() // used to pass the parent when creating a new Requerimiento
    set coordinador(coordinador : Usuario) {
      if(this.requerimiento == undefined){
        this.requerimiento = new Requerimiento();
      }
      this.requerimiento.coordinador = coordinador;
    }

    @Input() // used to pass the parent when creating a new Requerimiento
    set etapa(etapa : Etapa) {
      if(this.requerimiento == undefined){
        this.requerimiento = new Requerimiento();
      }
      this.requerimiento.etapa = etapa;
    }

    @Input() // used to pass the parent when creating a new Requerimiento
    set supervisor(supervisor : Usuario) {
      if(this.requerimiento == undefined){
        this.requerimiento = new Requerimiento();
      }
      this.requerimiento.supervisor = supervisor;
    }

    @Input() // used to pass the parent when creating a new Requerimiento
    set requerimientoPrefijo(requerimientoPrefijo : RequerimientoPrefijo) {
      if(this.requerimiento == undefined){
        this.requerimiento = new Requerimiento();
      }
      this.requerimiento.requerimientoPrefijo = requerimientoPrefijo;
    }

    @Input() // used to pass the parent when creating a new Requerimiento
    set empresaDestinataria(empresaDestinataria : Empresa) {
      if(this.requerimiento == undefined){
        this.requerimiento = new Requerimiento();
      }
      this.requerimiento.empresaDestinataria = empresaDestinataria;
    }

    @Input() // used to pass the parent when creating a new Requerimiento
    set area(area : Area) {
      if(this.requerimiento == undefined){
        this.requerimiento = new Requerimiento();
      }
      this.requerimiento.area = area;
    }

    @Input() // used to pass the parent when creating a new Requerimiento
    set selectRequerimiento(requerimiento : Requerimiento) {
      if(this.sub){
        this.requerimiento = requerimiento;
      }
    }

    @Output() onSaveClicked = new EventEmitter<Requerimiento>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'folioEntrada': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'numeroRequerimiento': [
    ],
      'fechaIngreso': [
    ],
      'fechaRequerimiento': [
    ],
      'fechaRecepcion': [
        { type: 'required', message: 'lblRequerido' },
    ],
      'fechaRegistro': [
        { type: 'required', message: 'lblRequerido' },
    ],
      'fechaCierreParcial': [
    ],
      'fechaCierrePropuesta': [
        { type: 'required', message: 'lblRequerido' },
    ],
      'fechaCierre': [
    ],
      'fechaDespacho': [
    ],
      'descripcion': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'asunto': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'revisaAl': [
    ],
    'revisaPr': [
    ],
      'finalizado': [
    ],
      'folioSalida': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'notaCierre': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'borrado': [
    ],
      'ingresadoPor': [
      ],
      'contrato': [
        { type: 'required', message: 'lblRequerido' },
      ],
      // 'asistente': [
      // ],
      'requerimientoTipo': [
        { type: 'required', message: 'lblRequerido' },
      ],
      'coordinador': [
        { type: 'required', message: 'lblRequerido' },
      ],
      'etapa': [
      ],
      'supervisor': [
      ],
      'supervisorResponsableEP': [
        { type: 'required', message: 'lblRequerido' },
      ],     
      'iteResponsableEP': [
        { type: 'required', message: 'lblRequerido' },
      ], 
      'iteCorresponsableEP': [
        { type: 'required', message: 'lblRequerido' },
      ],            
      'requerimientoPrefijo': [
        { type: 'required', message: 'lblRequerido' },
      ],
      'empresaDestinataria': [
      ],
      'area': [
        { type: 'required', message: 'lblRequerido' },
      ],
    }

    //allEtapas = [];
    editarEtapa = null;
    activeIndex;
    loadAllData = false;
    privilegiosEtapa;
    esSecretaria = false;
    esJA = false;
    esSecretariaEP = false;
    esITE = false;
    accionPorEtapa = new AccionEtapa();
    nuevosRegistrosEtapaAnterior;
    listNuevosRegistrosEtapaAnterior;
    // success, info, warning and danger
    severityBadge = "success"
    classRowList = "bg-green-100"
    requerimientoOriginal;
    isOCEP = false;
    constructor(private requerimientoComentarioService: RequerimientoComentarioService, private requerimientoNuevoRegistroService:RequerimientoNuevoRegistroService, private etapaService:EtapaService, private requerimientoHistoricoService:RequerimientoHistoricoService,private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private requerimientoService: RequerimientoService, private usuarioService : UsuarioService, private contratoService: ContratoService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);      
      //await this.authService.getFuncionalidades(); //añadir en el login app.component
      //this.allEtapas = await this.etapaService.complete(null); //obtiene todas las etapas

        if (this.sub) {          
          this.editContent = false;
          this.activeIndex = [0];
          this.loadAllData = true;
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");
          this.setRolesEtapas();          
          if (id === 'new') {
            this.requerimiento = new Requerimiento();
            this.requerimiento.usuarioConCopia = [];                        
            this.editContent = this.permisos.crear;           
            this.requerimiento.fechaRecepcion = new Date();                        
            this.requerimiento.contrato = this.authService.globalContrato; 
            this.contratoTipoReq = this.authService.globalContrato.contratoTipo.id;
            this.contratoTipoNombreES = this.authService.globalContrato.contratoTipo.nombreEs;
            this.requerimiento.etapa = await this.etapaService.getEtapa(1);            
            this.idEtapaActual = 1;

            if(this.contratoTipoReq == 2){
              this.isOCEP = true;
            }
            //this.requerimiento.area = new Area();
            //this.requerimiento.area.id = 1; //1 = No Asignada

            this.requerimiento.ingresadoPor = this.authService.globalUser;
            this.nuevosRegistrosEtapaAnterior = new RequerimientoNuevoRegistro();
            //console.log(this.requerimiento.etapa);
          } else {
            this.requerimiento = new Requerimiento(await this.requerimientoService.getRequerimiento(id));
            //let contratoTipoTMP = await this.contratoService.getContrato(this.requerimiento.contrato.id);
            this.contratoTipoNombreES = this.requerimiento.contrato.contratoTipo.nombreEs;      //contratoTipoTMP.contratoTipo.nombreEs;
            this.contratoTipoReq = this.requerimiento.contrato.contratoTipo.id;                 //contratoTipoTMP.contratoTipo.id;
            if(this.contratoTipoReq == 2){
              this.isOCEP = true;
            }
            
            if(!this.authService.globalContrato && this.router.url.includes("/direct/requerimiento")){
              let contrato = this.authService.globalUser.contratos.find(c => c.id == this.requerimiento.contrato.id);              
              if(contrato){
                this.authService.globalContrato = await this.contratoService.getContrato(contrato.id);
              }else{              
                window.location.href="/"; //ver la forma de agregar un mensaje ad-oc ya que actualemnte no indica que no tiene acceso al contrato solo redirecciona al login
                this.messageService.customError(this.translate.instant("Requerimiento"),"No tiene acceso al contrato del requerimiento N° "+this.requerimiento.numeroRequerimiento, null);
                return;
              }
            }
            let historico = new RequerimientoHistorico();
            historico.requerimiento = this.requerimiento;
            historico.etapa = new Etapa()
            historico.etapa.id = 2;
            //Verificar si funciona cuando el flujo EP regresa a Asignación etapa 2
            this.reqPasoAsignacionITE =  (this.requerimiento.iteResponsableEP != null);// && ((await this.requerimientoHistoricoService.complete(historico)).length > 0); //(await this.requerimientoHistoricoService.complete(historico)).some(v => v.etapa.id == 2); //Ya pasó por la asignación, ya los ITE están asociados
            await this.getNuevosRegistrosEtapaAnterior();
            
            this.sourceConCopia = this.requerimiento.usuarioConCopia;
            this.idEtapaActual = this.requerimiento.etapa.id;
            
            this.setRequerimientoOriginal();
            if(this.idEtapaActual == 7){
              this.showRequerimientoArchivo = false;
            }
          }

          await this.setIteResponsableContrato();
          this.setStepsEtapas();
          this.privilegiosEtapa = new PrivilegiosEtapa(this.authService);
        	this.editarEtapa = this.privilegiosEtapa.transform(this.idEtapaActual, 'formularioEtapas');
          if((this.idEtapaActual == 3 && this.requerimiento.coordinador.id != this.authService.globalUser.id)
            || (this.idEtapaActual == 15 && this.requerimiento.supervisor.id != this.authService.globalUser.id)
            || (this.idEtapaActual == 20 && this.requerimiento.supervisorResponsableEP.id != this.authService.globalUser.id)
            //|| (this.idEtapaActual == 21 && this.requerimiento.iteResponsableEP.id != this.authService.globalUser.id)
            ){
            this.editarEtapa = false;
          }
          this.editContent = this.permisos.actualizar;             
          if(!this.editContentSub) {
            this.editContent = false;
          }
          if(this.editarEtapa){
            this.activeIndex = [1];
          }else{
            this.activeIndex = [0];
          }
          this.loadAllData = true;
        }catch(error){
          //console.log("🚀 ~ RequerimientoDetailComponent ~ ngOnInit ~ error:", error)
          this.router.navigate(["/"]);
          this.messageService.customError(this.translate.instant("Requerimiento"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    async setStepsEtapas(){
      this.steps = await this.etapaService.getStepsEtapas(this.idEtapaActual, this.contratoTipoReq); 
    }
    setRequerimientoOriginal(){
      // se utiliza para hacer roolback en caso de error cuando se avanza una etapa
      this.requerimientoOriginal = new Requerimiento(this.requerimiento); 
    }

    async getNuevosRegistrosEtapaAnterior(){
      this.listNuevosRegistrosEtapaAnterior = [];
      this.nuevosRegistrosEtapaAnterior = new RequerimientoNuevoRegistro();
      this.nuevosRegistrosEtapaAnterior.requerimiento = this.requerimiento;
      let listado = await this.requerimientoNuevoRegistroService.complete(this.nuevosRegistrosEtapaAnterior);            
      if(listado.length > 0){
        this.nuevosRegistrosEtapaAnterior = listado[0];
        this.listNuevosRegistrosEtapaAnterior = JSON.parse(this.nuevosRegistrosEtapaAnterior.nuevosRegistros);
        this.contarNuevosRegistrosEtapaAnterior();              
      }
    }


    contListArchivos = [];
    contListAntecedentes = [];
    contListInstrucciones = [];
    contListComentarios = [];
    contarNuevosRegistrosEtapaAnterior(){ 
      this.contListArchivos = [];
      this.contListAntecedentes = [];
      this.contListInstrucciones = [];
      this.contListComentarios = [];
      for(let nuevos of this.listNuevosRegistrosEtapaAnterior){
        if(nuevos.tipo == 'antecedente') this.contListAntecedentes.push(new Antecedente(nuevos.value));
        if(nuevos.tipo == 'archivo') this.contListArchivos.push(new RequerimientoArchivo(nuevos.value));
        if(nuevos.tipo == 'comentario') this.contListComentarios.push(new RequerimientoComentario(nuevos.value));
        if(nuevos.tipo == 'instruccion') this.contListInstrucciones.push(new RequerimientoInstruccion(nuevos.value));
      }
    }

    async setIteResponsableContrato(){
      if(this.contratoTipoReq==2){
        this.listContratosEP = this.requerimiento.contrato.contratosEP;             //(await this.contratoService.complete(this.requerimiento.contrato))[0]?.contratosEP;
      }
    }
    setRolesEtapas(){
      let indexSecre = this.authService.globalUser.roles.findIndex(r => r.id == 3) // 3 = role secretaria
      let indexJA = this.authService.globalUser.roles.findIndex(r => r.id == 4) // 4 = role JA
      let indexSecreEP = this.authService.globalUser.roles.findIndex(r => r.id == 11) // 4 = role SecretariaEP
      let indexITE = this.authService.globalUser.roles.findIndex(r => r.id == 10) // 4 = role ITE
      if(indexSecre != -1){
        this.esSecretaria = true;
      }else if(indexJA != -1){
        this.esJA = true;
      }else if(indexSecreEP != -1){
        this.esSecretariaEP = true;
      }else if(indexITE != -1){
        this.esITE = true;
      }
    }
    

    confirmacionEtapas(lblMsg) : Promise<boolean> {
      return new Promise(resolve => {
        this.confirmationService.confirm({
          message: this.translate.instant(lblMsg),
          header: this.translate.instant("lblHeaderConfirmacionEtapa"),
          icon: 'fa fa-question-circle',
          key: 'requerimientoKey',
          accept: async () => {
              resolve(true);
          },
          reject: (type) => {
            resolve(false);
          }
        });
      });
    }

    async secreEnviaAJA(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmSecreEnviaAJA")
      if(confirm){
        this.requerimiento.etapa.id = 2; // asignación   
        this.accionPorEtapa.id = 1;   
        this.onSave(true);
      }    
          
    }

    async secreEnviaAITE(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmSecreEnviaAITE")
      if(confirm){
        this.requerimiento.etapa.id = 2; // asignación   
        this.accionPorEtapa.id = 28;   
        this.onSave(true);
      }    
          
    }

    async secreEnviaAIF(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmSecreEnviaAIF")
      if(confirm){
        this.requerimiento.etapa.id = 12;      
        this.accionPorEtapa.id = 2;
        this.onSave(true);
      }
    }
    // basicamente es lo mismo que la creacion de la secretaria pero lo hace el JA y se lo "auto envia"
    async jaCreaRequerimiento(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaCreaRequerimiento")
      if(confirm){
        this.requerimiento.etapa.id = 2; // asignación
        this.accionPorEtapa.id = 3;
        this.onSave(true);
      }
    }
    // es lo mismo que hace la secretaria cuando se lo envia al IF
    async jaEnviaAIFPorInstruccion(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaEnviaAIFPorInstruccion")
      if(confirm){
        this.requerimiento.etapa.id = 12;
        this.accionPorEtapa.id = 4;
        this.onSave(true);
      }
    }
    async jaAnulaReq(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaAnulaReq")
      if(confirm){
        this.requerimiento.etapa.id = 11;
        this.accionPorEtapa.id = 6;
        this.onSave(true);
      }
    }
    async jaCerradoForzoso(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaCerradoForzoso")
      if(confirm){
        this.requerimiento.etapa.id = 17;
        this.accionPorEtapa.id = 7;
        this.onSave(true);
      }
    }
    async jaEnviaAITE(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaEnviaAITE")
      if(confirm){
        this.requerimiento.residenteOC = await this.authService.globalUser;
        this.requerimiento.etapa.id = 26;  
        this.accionPorEtapa.id = 43;
        this.onSave(true);
      }
    }
    async jaEnviaACoordinador(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaEnviaACoordinador")
      if(confirm){
        this.requerimiento.etapa.id = 3;  
        this.accionPorEtapa.id = 8;
        this.onSave(true);
      }
    }
    async coordinadorEnviaAJA(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmCoordinadorEnviaAJA")
      if(confirm){
        this.requerimiento.fechaCierreParcial = new Date();
        this.requerimiento.etapa.id = 4;
        this.accionPorEtapa.id = 13;
        this.onSave(true);
      }      
    }
    async coordinadorEnviaASupervisor(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmCoordinadorEnviaASupervisor")
      if(confirm){
        this.requerimiento.etapa.id = 15;
        this.accionPorEtapa.id = 9;
        this.onSave(true);
      }
    }
    async supervisorEnviaACoordinador(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmSupervisorEnviaACoordinador")
      if(confirm){
        this.requerimiento.etapa.id = 3;
        this.accionPorEtapa.id = 12;
        this.onSave(true);
      }
    }
    // async supervisorEnviaAAsistente(){
    //   this.setRequerimientoOriginal();
    //   let confirm = await this.confirmacionEtapas("lblConfirmSupervisorEnviaAAsistente")
    //   if(confirm){
    //     this.requerimiento.etapa.id = 16;
    //     this.accionPorEtapa.id = 10;
    //     this.onSave(true);
    //   }
    // }
    // async asistenteEnviaASupervisor(){
    //   this.setRequerimientoOriginal();
    //   let confirm = await this.confirmacionEtapas("lblConfirmAsistenteEnviaASupervisor")
    //   if(confirm){
    //     this.requerimiento.etapa.id = 15;
    //     this.accionPorEtapa.id = 11;
    //     this.onSave(true);
    //   }
    // }
    async jaEnviaAIF(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaEnviaAIF")
      if(confirm){
        this.requerimiento.etapa.id = 6;
        this.accionPorEtapa.id = 19;
        this.onSave(true);
      }
    }

    async jaEnviaARotulacion(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaEnviaARotulacion")
      if(confirm){
        this.requerimiento.etapa.id = 28;
        this.accionPorEtapa.id = 47;
        this.onSave(true);
      }
    }

    async jaRechazaACoordinador(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaRechazaAAnalista")
      if(confirm){
        this.requerimiento.etapa.id = 3;
        this.accionPorEtapa.id = 14;
        this.onSave(true);
      }
    }

    
    displayDialogReasignar(){
      this.coordinadorOriginal = new Usuario(JSON.parse(JSON.stringify(this.requerimiento.coordinador)));
      this.requerimiento.coordinador = null;
      this.showDialogReasignar = true;
    }

    cancelarReasignacion(){      
      this.requerimiento.coordinador = new Usuario(this.coordinadorOriginal);
      this.coordinadorOriginal = null;
      this.showDialogReasignar = false;
      this.comentarioReasignacion = null;
    }
    async jaReasignaAAnalista(){      
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaReasignaAAnalista")
      if(confirm){
        // this.requerimiento.etapa.id = 2;
        this.accionPorEtapa.id = 15;
        this.onSave(true);
      }
    }

    async jaApruebaCierre(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaApruebaCierre")
      if(confirm){
        this.requerimiento.etapa.id = 7;
        this.accionPorEtapa.id = 45;
        this.onSave(true);
      }
    }

    async jaEnviaAAL(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaEnviaAAL")
      if(confirm){
        this.requerimiento.etapa.id = 5;
        this.accionPorEtapa.id = 16;
        this.onSave(true);
      }
    }    
    async alEnviaAJA(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmAlEnviaAJA")
      if(confirm){
        this.requerimiento.etapa.id = 8;
        this.accionPorEtapa.id = 17;
        this.onSave(true);
      }
    }
    async jaRechazaAAL(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaRechazaAAL")
      if(confirm){
        this.requerimiento.etapa.id = 5;
        this.accionPorEtapa.id = 18;
        this.onSave(true);
      }
    }
    async jaEnviaAPR(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaEnviaAPR")
      if(confirm){
        this.requerimiento.etapa.id = 18;
        this.accionPorEtapa.id = 25;
        this.onSave(true);
      }
    }
    async prEnviaAJA(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmPrEnviaAJA")
      if(confirm){
        this.requerimiento.etapa.id = 19;
        this.accionPorEtapa.id = 26;
        this.onSave(true);
      }
    }
    async jaRechazaAPR(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmJaRechazaAPR")
      if(confirm){
        this.requerimiento.etapa.id = 18;
        this.accionPorEtapa.id = 27;
        this.onSave(true);
      }
    }
    async ifApruebaJA(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmIfApruebaJA")
      if(confirm){
        this.requerimiento.fechaCierreParcial = new Date();
        this.requerimiento.etapa.id = 7;
        this.accionPorEtapa.id = 21;
        this.onSave(true);
      }      
    }
    async ifRechazaJA(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmIfRechazaJA")
      if(confirm){
        this.requerimiento.etapa.id = 9;
        this.accionPorEtapa.id = 20;
        this.onSave(true);
      }
    }

    async secreEnviaReqRotIF(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmSEEnviaReqRotIF")
      if(confirm){
        this.requerimiento.etapa.id = 6;
        this.accionPorEtapa.id = 48;
        this.onSave(true);
      }
    }

    async secreAdjuntaCierraReq(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmSecreAdjuntaCierraReq")
      if(confirm){
        this.requerimiento.etapa.id = 10; 
        this.requerimiento.fechaCierre = new Date(); 
        this.requerimiento.finalizado = true; 
        if(this.contratoTipoReq != 3){
          this.accionPorEtapa.id = 22;
        }else if(this.contratoTipoReq == 3){
          this.accionPorEtapa.id = 40;
        }     

        this.onSave(true);
      }
    }
    async ifInstruyeJA(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmIfInstruyeJA")
      if(confirm){
        this.requerimiento.etapa.id = 2;
        this.accionPorEtapa.id = 5;
        this.onSave(true);
      }
    }
    async ifDevuelveSecre(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmIfDevuelveSecre")
      if(confirm){
        this.requerimiento.etapa.id = 13;
        this.accionPorEtapa.id = 23;
        this.onSave(true);
      }
    }


    async iteEnviaASupervisor(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmiteEnviaASupervisor")
      if(confirm){
        if(!this.requerimiento.area?.compartido){
          this.requerimiento.iteResponsableEP = await this.authService.globalUser;
        }

        this.requerimiento.etapa.id = 20;  
        this.accionPorEtapa.id = 32;
        this.onSave(true);
      }
    }

    async supervisorEnviaAITE(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmSupervisorEnviaAITE")
      if(confirm){
        this.requerimiento.fechaCierreParcial = new Date();
        this.requerimiento.etapa.id = 21;
        this.accionPorEtapa.id = 33;
        this.onSave(true);
      }      
    }

    async iteEnviaAResidenteOC(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmiteEnviaAResidenteOC")
      if(confirm){
        this.requerimiento.etapa.id = 22;
        this.accionPorEtapa.id = 34;
        this.onSave(true);
      }
    }

    async iteRechazaASupervisor(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmIteRechazaASupervisor")
      if(confirm){
        this.requerimiento.etapa.id = 20;
        this.accionPorEtapa.id = 36;
        this.onSave(true);
      }
    }

    async iteAnulaReq(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmIteAnulaReq")
      if(confirm){
        this.requerimiento.etapa.id = 11;
        this.accionPorEtapa.id = 30;
        this.onSave(true);
      }
    }

    async iteCerradoForzoso(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmIteCerradoForzoso")
      if(confirm){
        this.requerimiento.etapa.id = 25;
        this.accionPorEtapa.id = 31;
        this.onSave(true);
      }
    }

    async iteReasignaASupervisor(){
       this.setRequerimientoOriginal();
       let confirm = await this.confirmacionEtapas("lblConfirmITEReasignaASupervisor")
       if(confirm){
         this.requerimiento.etapa.id = 2;
         this.accionPorEtapa.id = 35;
         this.onSave(true);
       }
     }

     async residenteOCApruebaITE(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmResidenteOCApruebaITE")
      if(confirm){
        this.requerimiento.fechaCierreParcial = new Date();
        this.requerimiento.residenteOC = await this.authService.globalUser;
        this.requerimiento.etapa.id = 7;
        this.accionPorEtapa.id = 37;
        this.onSave(true);
      }      
    }
    async residenteOCRechazaITE(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmResidenteOCRechazaITE")
      if(confirm){
        this.requerimiento.residenteOC = await this.authService.globalUser;
        this.requerimiento.etapa.id = 23;
        this.accionPorEtapa.id = 38;
        this.onSave(true);
      }
    }

    async iteResposableEnviaACorresponsable(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmIteResposableEnviaACorresponsable")
      if(confirm){
        //this.requerimiento.etapa.id = ;
        this.accionPorEtapa.id = 41;
        this.onSave(true);
      }
    }

    async iteCorresponsableEnviaAResponsable(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmIteCorresponsableEnviaAResponsable")
      if(confirm){
        //this.requerimiento.etapa.id = ;
        this.accionPorEtapa.id = 42;
        this.onSave(true);
      }
    }

    async residenteOCEnviaOC(){
      //this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmResidenteOCRemitirOC")
      if(confirm){
        //let requerimientoOC = new Requerimiento(this.requerimiento);
        //requerimientoOC = this.requerimiento;

        this.requerimiento.residenteOC = this.authService.globalUser;
        this.requerimiento.etapa.id = 24;    
        this.accionPorEtapa.id = 39;
        this.requerimiento.folioSalida = this.requerimiento.folioEntrada +"_"+ this.requerimiento.contrato.codigo;
        this.requerimiento.fechaCierre = new Date(); 
        this.requerimiento.finalizado = true; 
        let reqIdEP = this.requerimiento.id; 
        
        await this.onSave(true);
        let requerimientoOC = new Requerimiento(this.requerimiento);

        //this.setRequerimientoOriginal();
        requerimientoOC.id = null;
        requerimientoOC.numeroRequerimiento = null;
        requerimientoOC.ingresadoPor = this.authService.globalUser;
        requerimientoOC.fechaRecepcion = new Date();  
        //this.requerimiento.residenteOC = null;
        requerimientoOC.iteResponsableEP = null;
        requerimientoOC.iteCorresponsableEP = null;
        requerimientoOC.folioEntrada = this.requerimiento.folioSalida;
        requerimientoOC.folioSalida = null;
        requerimientoOC.etapa.id = 2;           //Asignación
        //this.idEtapaActual = 2;
        requerimientoOC.contrato = this.authService.globalContrato; 
        requerimientoOC.finalizado = null;
        requerimientoOC.fechaCierre = null;
        //this.nuevosRegistrosEtapaAnterior = new RequerimientoNuevoRegistro();
        
        try {
          requerimientoOC = new Requerimiento(await this.requerimientoService.update(requerimientoOC)); //await this.onSave(true);
          
          let resp = await this.requerimientoService.copyReqEPOC(requerimientoOC, this.requerimiento.id); 
          //await this.guardaHistoricoEtapas(null, requerimientoOC);
          //this.messageService.success(this.translate.instant("Requerimiento"), this.translate.instant("Remitido a OC con el número: \n"+ requerimientoOC.numeroRequerimiento));  
          this.router.navigate(["/requerimiento/" + requerimientoOC.id]);
          this.loadAllData = false;
          await this.ngOnInit();
        } catch(error) {
          this.messageService.customError(this.translate.instant("Requerimiento"),this.translate.instant("lblProblemaGuardar"), error);
        }
     
      }
    }  

    async iteRespondeAResidenteOC(){
      this.setRequerimientoOriginal();
      let confirm = await this.confirmacionEtapas("lblConfirmiteEnviaAResidenteOC")
      if(confirm){
        this.requerimiento.etapa.id = 27;
        this.accionPorEtapa.id = 44;
        this.onSave(true);
      }      
    }

/*     setAreaITEResponsable(){
      if(this.requerimiento.area.compartido){
        this.requerimiento.iteResponsableEP = this.authService.globalUser;
      }
    } */
    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'requerimientoKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      //window.history.back();
      if(this.authService.globalContrato.finalizado){
        this.router.navigate(["/requerimiento-list/requerimientos-cerrados"]);
      }else{
        this.router.navigate(["/requerimiento-list/mis-pendientes"]);
      }
      
    }

    async guardaHistoricoEtapas(detalle?, reqEPtoOC?){
      let historico = new RequerimientoHistorico();
      historico.ejecutor = this.authService.globalUser;
      historico.etapa = new Etapa();
      historico.fechaRegistro = new Date();
      historico.detalle = detalle;
      historico.accion = this.accionPorEtapa;
      if(reqEPtoOC){
        historico.requerimiento = reqEPtoOC;
        historico.etapa.id = reqEPtoOC.etapa.id;
      }else{
        historico.requerimiento = this.requerimiento;
        historico.etapa.id = this.idEtapaActual;
        if(this.requerimiento.etapa.id == 10){
          await this.requerimientoHistoricoService.update(historico);
          historico.etapa.id = this.requerimiento.etapa.id;
          historico.accion.id = 46;
        }
      }
      
      try{
        return await this.requerimientoHistoricoService.update(historico);
      }catch(error){
        this.messageService.customError(this.translate.instant("RequerimientoHistorico"),this.translate.instant("lblProblemaGuardar"), error);
        return false;
      }      
    }

    listObjCreadosEnEtapa = [];
    async onSave(guardaHistorico?) {
      if(this.requerimientoForm){
        this.requerimientoForm.onSubmit(null); //cambia el estado del form submited = true
      }
      if(!(this.contratoTipoReq == 3  && this.requerimiento.area?.compartido && (this.requerimiento.contrato.id == 1042 || this.requerimiento.contrato.id == 1043))){
        this.requerimiento.iteCorresponsableEP = null;
      }
      if(this.requerimiento.etapa.id==11 || this.requerimiento.etapa.id==17 || this.requerimientoForm.form.valid){
        this.messageService.clear();
        try{
          if(this.requerimiento.fechaRegistro == null){
            this.requerimiento.fechaRegistro = new Date();
          }
          let primerGuardar = false;
          if(this.requerimiento.fechaIngreso == null){
            primerGuardar = true;            
            this.requerimiento.fechaIngreso = new Date();            
          }
          let saveOkChield = [];
          this.requerimiento = new Requerimiento(await this.requerimientoService.update(this.requerimiento));          
          if(primerGuardar){           
            let accionIdTMP;   
            if(this.accionPorEtapa.id){              
              accionIdTMP = Number.parseInt(this.accionPorEtapa.id.toString());                
            }
            this.accionPorEtapa.id = 24                                      
            saveOkChield.push(await this.guardaHistoricoEtapas());
            if(accionIdTMP){
              this.accionPorEtapa.id = accionIdTMP;
            }
          }          
          if(guardaHistorico){  
            if(this.accionPorEtapa.id == 15){
              saveOkChield.push(await this.guardaHistoricoEtapas('Reasignación de Coordinador: '+this.coordinadorOriginal.nombre+' por '+this.requerimiento.coordinador.nombre));
              if(this.comentarioReasignacion != null){
                let reqComentario = new RequerimientoComentario();
                //reqComentario.requerimientoComentario = new RequerimientoComentario();
                reqComentario.requerimiento = this.requerimiento;
                reqComentario.mensaje = this.comentarioReasignacion;
                reqComentario.fechaRegistro = new Date();
                reqComentario.usuario = this.authService.globalUser;
                let result = this.requerimientoComentarioService.update(reqComentario); 
                saveOkChield.push(reqComentario);
                if(reqComentario && this.preExistObjCreadosEtapas('comentario',reqComentario.id) == false){
                  let comentarioBasic1 = new RequerimientoComentario(reqComentario);
                  comentarioBasic1.usuario = new Usuario();
                  comentarioBasic1.usuario.id = reqComentario.usuario.id;
                  comentarioBasic1.requerimiento = new Requerimiento();
                  comentarioBasic1.requerimiento.id = reqComentario.requerimiento.id;                                
                  this.listObjCreadosEnEtapa.push({tipo:'comentario', value:comentarioBasic1});
                }
              }
            }else{
              saveOkChield.push(await this.guardaHistoricoEtapas());
            }
            
          }else{
            this.setRequerimientoOriginal();
          }
          this.guardo = true;          
          if(!this.showAntecedente){
            this.antecedenteChild.requerimiento = this.requerimiento;
            let antecedente:any = await this.antecedenteChild.onSave()
            saveOkChield.push(antecedente);
            if(antecedente && this.preExistObjCreadosEtapas('antecedente',antecedente.id) == false){
              let antecedenteBasic = new Antecedente(antecedente);
              antecedenteBasic.requerimiento = new Requerimiento();
              antecedenteBasic.requerimiento.id = antecedente.requerimiento.id;
              this.listObjCreadosEnEtapa.push({tipo:'antecedente', value:antecedenteBasic});
            }
          }
          if(!this.showRequerimientoArchivo){
            this.requerimientoArchivoChild.requerimiento = this.requerimiento;
            let archivo:any = await this.requerimientoArchivoChild.onSave()
            saveOkChield.push(archivo);
            if(archivo && this.preExistObjCreadosEtapas('archivo',archivo.id) == false){
              let archivoBasic = new RequerimientoArchivo(archivo);
              archivoBasic.usuario = new Usuario();
              archivoBasic.usuario.id = archivo.usuario.id;
              archivoBasic.requerimiento = new Requerimiento();
              archivoBasic.requerimiento.id = archivo.requerimiento.id;
              archivoBasic.requerimientoTipo = new RequerimientoTipo();
              archivoBasic.requerimientoTipo.id = archivo.requerimientoTipo.id;
              this.listObjCreadosEnEtapa.push({tipo:'archivo', value:archivoBasic});
            }              
          }
          if(!this.showRequerimientoComentario){
            this.requerimientoComentarioChild.requerimiento = this.requerimiento;
            let comentario = await this.requerimientoComentarioChild.onSave();            
            saveOkChield.push(comentario);
            if(comentario && this.preExistObjCreadosEtapas('comentario',comentario.id) == false){
              let comentarioBasic2 = new RequerimientoComentario(comentario);
              comentarioBasic2.usuario = new Usuario();
              comentarioBasic2.usuario.id = comentario.usuario.id;
              comentarioBasic2.requerimiento = new Requerimiento();
              comentarioBasic2.requerimiento.id = comentario.requerimiento.id;
              this.listObjCreadosEnEtapa.push({tipo:'comentario', value:comentarioBasic2});
            }
          }          
          if(!this.showRequerimientoInstruccion){
            this.requerimientoInstruccionChild.requerimiento = this.requerimiento;
            let instruccion = await this.requerimientoInstruccionChild.onSave();
            saveOkChield.push(instruccion);
            if(instruccion && this.preExistObjCreadosEtapas('instruccion',instruccion.id) == false){
              let instruccionBasic = new RequerimientoInstruccion(instruccion);
              instruccionBasic.usuario.id = instruccion.usuario.id;
              instruccionBasic.requerimiento = new Requerimiento();
              instruccionBasic.requerimiento.id = instruccion.requerimiento.id;
              this.listObjCreadosEnEtapa.push({tipo:'instruccion', value:instruccionBasic});
            }
          }
          
/*           if(this.requerimiento.etapa.id==2 && this.accionPorEtapa.id==39){

          } */

          if(!this.sub){
            if(!saveOkChield.includes(false)){
              this.messageService.success(this.translate.instant("Requerimiento"), this.translate.instant("lblGuardo"));
              this.idEtapaActual = this.requerimiento.etapa.id;
              this.editarEtapa = this.privilegiosEtapa.transform(this.idEtapaActual, 'formularioEtapas');
              if(guardaHistorico){
                if(this.idEtapaActual > 1){
                  //enviar coordinador original en la reasignacion (ver java y SP)
                  if(this.accionPorEtapa.id == 15){
                    this.requerimientoService.enviarCorreoNotificacionEtapa(this.requerimiento, this.accionPorEtapa.id, this.coordinadorOriginal)
                  }else{
                    this.requerimientoService.enviarCorreoNotificacionEtapa(this.requerimiento, this.accionPorEtapa.id)
                  }
                  
                }       
                await this.saveNuevosRegistrosProxEtapa();
              }
              this.setStepsEtapas();
            }else if(saveOkChield.includes(false) && guardaHistorico){
              this.requerimiento = new Requerimiento(await this.requerimientoService.update(this.requerimientoOriginal));  
              this.idEtapaActual = this.requerimiento.etapa.id;
              this.editarEtapa = this.privilegiosEtapa.transform(this.idEtapaActual, 'formularioEtapas');                       
            }            
            this.showDialogReasignar = false;
            this.comentarioReasignacion = null;
            return this.requerimiento;
          }else{
            this.onSaveClicked.emit(this.requerimiento);
          }
        }catch(error){
          console.log("🚀 ~ file: requerimiento-detail.component.ts:683 ~ RequerimientoDetailComponent ~ onSave ~ error:", error)
          
          this.messageService.customError(this.translate.instant("Requerimiento"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }

    //valida si ya almaceno un objeto en el listado con el mismo ID, este caso se da cuando se edita un registro
    preExistObjCreadosEtapas(tipo,id){      
      for(let obj of this.listObjCreadosEnEtapa){
        if(obj.tipo == tipo && obj.value.id == id){
          return true;
        }
      }
      return false;
    }
    async saveNuevosRegistrosProxEtapa(){
      let reqNuevoRegistro = new RequerimientoNuevoRegistro();
      reqNuevoRegistro.etapa = this.requerimiento.etapa;
      reqNuevoRegistro.requerimiento = this.requerimiento;
      reqNuevoRegistro.nuevosRegistros = JSON.stringify(this.listObjCreadosEnEtapa);
      let result;
      if(reqNuevoRegistro.etapa.id != 10){// cerrado y firmado, no es necesario destacar en la ultima etapa
        result = await this.requerimientoNuevoRegistroService.update(reqNuevoRegistro);
      }      
      if(result && this.nuevosRegistrosEtapaAnterior.id){
        await this.requerimientoNuevoRegistroService.delete(this.nuevosRegistrosEtapaAnterior.id)
      }
      this.listObjCreadosEnEtapa = [];
      await this.getNuevosRegistrosEtapaAnterior();
    }


    async getUsuarioConCopia(){
      this.loading = true;
      this.sourceConCopia= await this.usuarioService.complete(null);
      this.loading = false;
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
