//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { SesionColumnList } from './sesionColumnList';
import { SesionColumnListService } from './sesionColumnList.service';
import { TranslateService } from '@ngx-translate/core';

import { Usuario } from '../usuario/usuario';
import { Menu } from '../menu/menu';

@Component({
	templateUrl: 'sesionColumnList-detail.component.html',
	selector: 'sesionColumnList-detail',
})
export class SesionColumnListDetailComponent implements OnInit{

    @ViewChild('sesionColumnListForm', {static: false}) sesionColumnListForm:any;
    sesionColumnList : SesionColumnList;

    private params_subscription: any;
    guardo: boolean = false;
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_sesionColumnList';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new SesionColumnList
    set usuario(usuario : Usuario) {
      if(this.sesionColumnList == undefined){
        this.sesionColumnList = new SesionColumnList();
      }
      this.sesionColumnList.usuario = usuario;
    }

    @Input() // used to pass the parent when creating a new SesionColumnList
    set menu(menu : Menu) {
      if(this.sesionColumnList == undefined){
        this.sesionColumnList = new SesionColumnList();
      }
      this.sesionColumnList.menu = menu;
    }

    @Input() // used to pass the parent when creating a new SesionColumnList
    set selectSesionColumnList(sesionColumnList : SesionColumnList) {
      if(this.sub){
        this.sesionColumnList = sesionColumnList;
      }
    }

    @Output() onSaveClicked = new EventEmitter<SesionColumnList>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'columnas': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'usuario': [
      ],
      'menu': [
      ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private sesionColumnListService: SesionColumnListService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.sesionColumnList == undefined && this.permisos.crear){
            this.sesionColumnList = new SesionColumnList();
            this.sesionColumnList.usuario = this.authService.globalUser;
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.sesionColumnList = new SesionColumnList();
            this.sesionColumnList.usuario = this.authService.globalUser;
            this.editContent = this.permisos.crear;
          } else {
            this.sesionColumnList = new SesionColumnList(await this.sesionColumnListService.getSesionColumnList(id));
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("SesionColumnList"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'sesionColumnListKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.sesionColumnListForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.sesionColumnListForm.form.valid){
        this.messageService.clear();
        try{
          this.sesionColumnList = new SesionColumnList(await this.sesionColumnListService.update(this.sesionColumnList));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("SesionColumnList"), this.translate.instant("lblGuardo"));
            return this.sesionColumnList;
          }else{
            this.onSaveClicked.emit(this.sesionColumnList);
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("SesionColumnList"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
