//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { Contrato } from './contrato';
import { ContratoService } from './contrato.service';
import { TranslateService } from '@ngx-translate/core';
import { ContratoTipo } from '../contratoTipo/contratoTipo';
import { Comuna } from '../comuna/comuna';
import { Region } from '../region/region';
import { Provincia } from '../provincia/provincia';

@Component({
	templateUrl: 'contrato-detail.component.html',
	selector: 'contrato-detail',
})
export class ContratoDetailComponent implements OnInit{

    @ViewChild('contratoForm', {static: false}) contratoForm:any;
    contrato : Contrato;

    private params_subscription: any;
    sourceContratoEP : Contrato[] = [];
    guardo: boolean = false;
    loading: boolean = false;    
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_contrato';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new Contrato
    set contratoTipo(contratoTipo : ContratoTipo) {
      if(this.contrato == undefined){
        this.contrato = new Contrato();
      }
      this.contrato.contratoTipo = contratoTipo;
    }

    @Input() // used to pass the parent when creating a new Contrato
    set comuna(comuna : Comuna) {
      if(this.contrato == undefined){
        this.contrato = new Contrato();
      }
      this.contrato.comuna = comuna;
    }

    @Input() // used to pass the parent when creating a new Contrato
    set region(region : Region) {
      if(this.contrato == undefined){
        this.contrato = new Contrato();
      }
      this.contrato.region = region;
    }

    @Input() // used to pass the parent when creating a new Contrato
    set provincia(provincia : Provincia) {
      if(this.contrato == undefined){
        this.contrato = new Contrato();
      }
      this.contrato.provincia = provincia;
    }

    @Input() // used to pass the parent when creating a new Contrato
    set selectContrato(contrato : Contrato) {
      if(this.sub){
        this.contrato = contrato;
      }
    }

    @Output() onSaveClicked = new EventEmitter<Contrato>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'nombre': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'codigo': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'descripcion': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'fechaRegistro': [
    ],
      'fechaInicioContrato': [
    ],
      'fechaTerminoContrato': [
    ],
      'responsableTecnico': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableTecnicoCorreo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableTecnicoTelefono': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableTecnicoTelefonoAnexo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableAdministrativo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableAdministrativoCorreo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableAdministratioTelefono': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableAdministrativoTelefonoAnexo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'finalizado': [
    ],
      'borrado': [
    ],
      'contratoTipo': [
        { type: 'required', message: 'lblRequerido' },
      ],
      'comuna': [
        { type: 'required', message: 'lblRequerido' },
      ],
      'region': [
        { type: 'required', message: 'lblRequerido' },
      ],
      'provincia': [
        { type: 'required', message: 'lblRequerido' },
      ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private contratoService: ContratoService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.contrato == undefined && this.permisos.crear){
            this.contrato = new Contrato();
            this.contrato.fechaRegistro = new Date();
            this.contrato.fechaInicioContrato = new Date();
            this.contrato.fechaTerminoContrato = new Date();
            this.contrato.finalizado = true;
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");
          let contratoEP = new Contrato();
          contratoEP.contratoTipo = new ContratoTipo();
          contratoEP.contratoTipo.id = 3;
          this.sourceContratoEP = await this.contratoService.complete(contratoEP);                      
          if (id === 'new') {
            this.contrato = new Contrato();
            this.contrato.fechaRegistro = new Date();
            this.contrato.fechaInicioContrato = new Date();
            this.contrato.fechaTerminoContrato = new Date();
            this.contrato.finalizado = false;
            this.editContent = this.permisos.crear;
          } else {
            this.contrato = new Contrato(await this.contratoService.getContrato(id));
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          console.log(error);
          this.messageService.customError(this.translate.instant("Contrato"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'contratoKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.contratoForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.contratoForm.form.valid){
        this.messageService.clear();
        try{
          if(this.contrato.contratoTipo.id != 2){     //Distinto de OC de Gestión EP
            this.contrato.contratosEP = [];
          }
          this.contrato = new Contrato(await this.contratoService.update(this.contrato));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("Contrato"), this.translate.instant("lblGuardo"));
            this.volver();
            return this.contrato;
          }else{
            this.onSaveClicked.emit(this.contrato);
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("Contrato"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }

    async getContratosEP(){
      this.loading = true;
      let contratoEP = new Contrato();
      contratoEP.contratoTipo = new ContratoTipo();
      contratoEP.contratoTipo.id = 3;
      this.sourceContratoEP = await this.contratoService.complete(contratoEP);
      /* let isAdmin = this.authService.getIsAdmin(); 
      if(isAdmin){
        this.sourceContratoEP = await this.contratoService.complete(null);
      }else{
        this.sourceContratoEP = this.authService.globalUser.contratos;
      } */      
      this.loading = false;
    }

    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }

    cleanProvinciaComuna(complete){      
      if(complete == 'region'){
        this.contrato.provincia = null;
        this.contrato.comuna = null;
      }else if(complete == 'provincia'){
        this.contrato.comuna = null;
      }
    }
}
