//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { Config } from './config';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class ConfigService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a Config by id.
     */
    getConfig(id : any) {
        return this.http.get<Config>('/api/configs/' + id).toPromise();
    }

    /**
     * Update the passed config.
     */
    update(config : Config, logoBinary : File, faviconBinary : File) {
      let formData = new FormData();
      formData.append('body',new Blob([JSON.stringify(config)],{type: "application/json"}));
      formData.append('logoBinary', logoBinary ? logoBinary : new Blob());
      formData.append('faviconBinary', faviconBinary ? faviconBinary : new Blob());
      return this.http.post<Config>('/api/configs/save', formData).toPromise();
    }

    /**
     * Download File logoBinary.
     */
     downloadLogoBinary(config : Config) {
      const req = new HttpRequest('GET', '/api/configs/'+config.id+'/download/logoBinary', {
          reportProgress: true,
          'responseType': 'blob' as 'json'
        });
      return this.http.request(req);
     }
    /**
     * Download File faviconBinary.
     */
     downloadFaviconBinary(config : Config) {
      const req = new HttpRequest('GET', '/api/configs/'+config.id+'/download/faviconBinary', {
          reportProgress: true,
          'responseType': 'blob' as 'json'
        });
      return this.http.request(req);
     }

    /**
     * Load a page (for paginated datatable) of Config using the passed
     * config as an example for the search by example facility.
     */
     getPage(config : Config, event : LazyLoadEvent, columns, filterRangeNumber?) {
        let req = new PageRequestByExample(config, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        return this.http.post('/api/configs/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by ConfigListComponent.
      */
    getCSV(config:Config, filterRangeNumber?) {
      let body = JSON.stringify(config);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      return this.http.post('/api/configs/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by ConfigCompleteComponent.
     */
    complete(config : Config) {
        if(config == null){
          config = new Config();
        }
        let body = JSON.stringify(config);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<Config[]>('/api/configs/complete', formData).toPromise();
    }

    /**
     * Delete an Config by id.
     */
    delete(id : any) {
        return this.http.delete('/api/configs/' + id).toPromise();
    }
}
