//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/support/download-progress.component.ts.p.vm
//
import { Component, Input, Injectable } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'download-progress',
  animations: [
    trigger('openClose', [
      state('open', style({
        display: 'inherit',
        opacity: 1
      })),
      state('closed', style({
        display: 'none',
        opacity: 0.2
      })),
      transition('open => closed', [
        animate('1.5s')
      ]),
      // transition('closed => open', [
      //   animate('1s')
      // ]),
    ]),
  ],
  template: `
    <div [@openClose]="progress != null ? 'open' : 'closed'" class="download-toast">
      <div *ngIf="progress != null && progress>=0 && progress<100">
        <span style="margin: 0 0.1em; font-size: 0.9em;">{{'lblProgressDownload' | translate}} ({{totalDescarga}})</span>
        <p-progressBar [value]="progress" [style]="{'margin': '0.6em 0'}"></p-progressBar>
        <ng-container *ngFor="let pr of descargas">
          <div *ngIf="!pr.cancelada">
            <div class="nombreDescarga">
              {{pr.nombre}}
            </div>
            <div class="porDescarga">
              {{pr.progress}}%
            </div>
            <div class="divCancelarDescarga">
              <button class="btnCancelarDescarga" pTooltip="{{'btnCancelar' | translate}}" pButton (click)="cancelarDescarga(pr)" icon="pi pi-times"></button>
            </div>
          </div>
        </ng-container>
      </div>
      <span style="margin: 1em;" *ngIf="progress == null">{{mensajeFinal}}</span>
    </div>
  `
})

export class DownloadProgressComponent {
  constructor(private translate: TranslateService) {}
  progress:number = null;
  descargas = [];
  lastId = 0;
  totalPor = 0;
  totalDescarga = 0;
  canceladas = 0;
  activas = 0;
  mensajeFinal="Descarga Finalizada";
  public cancelarDescarga(pr){
    pr.reqHttp.unsubscribe();
    pr.progress = 100;
    pr.cancelada = true;
    this.canceladas +=1;
    if(this.descargas.length == this.canceladas){
        this.progress = null;
        this.progress = null;
        this.totalPor = 0;
        this.totalDescarga = 0;
        this.mensajeFinal = this.canceladas>1? this.translate.instant("lblDescargasCanceladas"):this.translate.instant("lblDescargaCancelada");        
    }else{
        this.calculaTotalProgres();
    }
  }
  public nuevaDescarga(fileName){
    this.lastId +=1;
    let descarga = {id:this.lastId, progress:0, nombre:fileName, reqHttp:null, cancelada: false};
    this.descargas.push(descarga);
    return this.descargas.length - 1;
  }
  public calculaTotalProgres(){
    this.mensajeFinal=this.translate.instant("lblDescargaFinalizada");
    this.totalPor = 0;
    this.totalDescarga = 0;
    for(let pr of this.descargas){
      if(!pr.cancelada){
        this.totalDescarga+=1;
        this.totalPor += pr.progress;
      }
    }
    //this.totalDescarga = this.descargas.length - this.totalDescarga;
    this.progress =  Math.round(this.totalPor / (this.totalDescarga <= 0 ? 1 : this.totalDescarga))
    if(this.progress >= 100){
      this.progress = null;
      this.totalPor = 0;
      this.totalDescarga = 0;
      this.descargas = [];
      this.canceladas = 0;
    }
  }
}
