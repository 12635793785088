//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/app.routes.ts.p.vm
//
import { ModuleWithProviders }  from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';

import { HomeComponent } from './home.component';
import { CalendarioEventosComponent } from './custom/calendario/calendario-eventos.component';
import { AuditoriaHistorialComponent } from './custom/auditoria/auditoria-historial.component';
import { KpiComponent } from './custom/kpis/kpis.component';
import { ConfiguracionSistemaComponent } from './custom/configuracionSistema/configuracion-sistema.component';
import { MenuDNDComponent } from './custom/menuDND/menu-dnd.component';
import { ContrasenaComponent } from './custom/contrasena/contrasena.component';
import { PermisosPorEntidadComponent } from './custom/permisos-por-entidad/permisos-por-entidad.component';
import { ColumnListComponent } from './custom/columnList/columnList.component';



import { AccionEtapaListComponent } from './entities/accionEtapa/accionEtapa-list.component';
import { AccionEtapaDetailComponent } from './entities/accionEtapa/accionEtapa-detail.component';

import { AntecedenteListComponent } from './entities/antecedente/antecedente-list.component';
import { AntecedenteDetailComponent } from './entities/antecedente/antecedente-detail.component';

import { AppLabelListComponent } from './entities/appLabel/appLabel-list.component';
import { AppLabelDetailComponent } from './entities/appLabel/appLabel-detail.component';

import { ArchivoAnexoListComponent } from './entities/archivoAnexo/archivoAnexo-list.component';
import { ArchivoAnexoDetailComponent } from './entities/archivoAnexo/archivoAnexo-detail.component';

import { ArchivoNotificacionListComponent } from './entities/archivoNotificacion/archivoNotificacion-list.component';
import { ArchivoNotificacionDetailComponent } from './entities/archivoNotificacion/archivoNotificacion-detail.component';

import { AreaListComponent } from './entities/area/area-list.component';
import { AreaDetailComponent } from './entities/area/area-detail.component';

import { AuditoriaSistemaListComponent } from './entities/auditoriaSistema/auditoriaSistema-list.component';
import { AuditoriaSistemaDetailComponent } from './entities/auditoriaSistema/auditoriaSistema-detail.component';

import { CalendarioListComponent } from './entities/calendario/calendario-list.component';
import { CalendarioDetailComponent } from './entities/calendario/calendario-detail.component';

import { CargoListComponent } from './entities/cargo/cargo-list.component';
import { CargoDetailComponent } from './entities/cargo/cargo-detail.component';

import { ConfigListComponent } from './entities/config/config-list.component';
import { ConfigDetailComponent } from './entities/config/config-detail.component';

import { ContratoListComponent } from './entities/contrato/contrato-list.component';
import { ContratoDetailComponent } from './entities/contrato/contrato-detail.component';

import { ContratoTipoListComponent } from './entities/contratoTipo/contratoTipo-list.component';
import { ContratoTipoDetailComponent } from './entities/contratoTipo/contratoTipo-detail.component';

import { EmpresaListComponent } from './entities/empresa/empresa-list.component';
import { EmpresaDetailComponent } from './entities/empresa/empresa-detail.component';

import { EmpresaTipoListComponent } from './entities/empresaTipo/empresaTipo-list.component';
import { EmpresaTipoDetailComponent } from './entities/empresaTipo/empresaTipo-detail.component';

import { EntidadListComponent } from './entities/entidad/entidad-list.component';
import { EntidadDetailComponent } from './entities/entidad/entidad-detail.component';

import { EtapaListComponent } from './entities/etapa/etapa-list.component';
import { EtapaDetailComponent } from './entities/etapa/etapa-detail.component';

import { FeriadoListComponent } from './entities/feriado/feriado-list.component';
import { FeriadoDetailComponent } from './entities/feriado/feriado-detail.component';

import { FuncionalidadListComponent } from './entities/funcionalidad/funcionalidad-list.component';
import { FuncionalidadDetailComponent } from './entities/funcionalidad/funcionalidad-detail.component';

import { ManualesListComponent } from './entities/manuales/manuales-list.component';
import { ManualesDetailComponent } from './entities/manuales/manuales-detail.component';

import { MenuListComponent } from './entities/menu/menu-list.component';
import { MenuDetailComponent } from './entities/menu/menu-detail.component';

import { NotificacionListComponent } from './entities/notificacion/notificacion-list.component';
import { NotificacionDetailComponent } from './entities/notificacion/notificacion-detail.component';

import { RequerimientoListComponent } from './entities/requerimiento/requerimiento-list.component';
import { RequerimientoResumenListComponent } from './entities/requerimiento/requerimiento-resumen-list.component';
import { RequerimientoDetailComponent } from './entities/requerimiento/requerimiento-detail.component';

import { RequerimientoArchivoListComponent } from './entities/requerimientoArchivo/requerimientoArchivo-list.component';
import { RequerimientoArchivoDetailComponent } from './entities/requerimientoArchivo/requerimientoArchivo-detail.component';

import { RequerimientoComentarioListComponent } from './entities/requerimientoComentario/requerimientoComentario-list.component';
import { RequerimientoComentarioDetailComponent } from './entities/requerimientoComentario/requerimientoComentario-detail.component';

import { RequerimientoHistoricoListComponent } from './entities/requerimientoHistorico/requerimientoHistorico-list.component';
import { RequerimientoHistoricoDetailComponent } from './entities/requerimientoHistorico/requerimientoHistorico-detail.component';

import { RequerimientoInstruccionListComponent } from './entities/requerimientoInstruccion/requerimientoInstruccion-list.component';
import { RequerimientoInstruccionDetailComponent } from './entities/requerimientoInstruccion/requerimientoInstruccion-detail.component';

import { RequerimientoPrefijoListComponent } from './entities/requerimientoPrefijo/requerimientoPrefijo-list.component';
import { RequerimientoPrefijoDetailComponent } from './entities/requerimientoPrefijo/requerimientoPrefijo-detail.component';

import { RequerimientoTipoListComponent } from './entities/requerimientoTipo/requerimientoTipo-list.component';
import { RequerimientoTipoDetailComponent } from './entities/requerimientoTipo/requerimientoTipo-detail.component';

import { RoleListComponent } from './entities/role/role-list.component';
import { RoleDetailComponent } from './entities/role/role-detail.component';

import { RolePermisoEntidadListComponent } from './entities/rolePermisoEntidad/rolePermisoEntidad-list.component';
import { RolePermisoEntidadDetailComponent } from './entities/rolePermisoEntidad/rolePermisoEntidad-detail.component';

import { SecuencialNumeroRequerimientoListComponent } from './entities/secuencialNumeroRequerimiento/secuencialNumeroRequerimiento-list.component';
import { SecuencialNumeroRequerimientoDetailComponent } from './entities/secuencialNumeroRequerimiento/secuencialNumeroRequerimiento-detail.component';

import { SesionColumnListListComponent } from './entities/sesionColumnList/sesionColumnList-list.component';
import { SesionColumnListDetailComponent } from './entities/sesionColumnList/sesionColumnList-detail.component';

import { SesionJwtListComponent } from './entities/sesionJwt/sesionJwt-list.component';
import { SesionJwtDetailComponent } from './entities/sesionJwt/sesionJwt-detail.component';

import { UsuarioListComponent } from './entities/usuario/usuario-list.component';
import { UsuarioDetailComponent } from './entities/usuario/usuario-detail.component';

import { VideoListComponent } from './entities/video/video-list.component';
import { VideoDetailComponent } from './entities/video/video-detail.component';




export const routes: Routes = [
    { path : '',  component: HomeComponent },
    { path : 'calendario-eventos',  component: CalendarioEventosComponent },
    { path : 'auditoria-historial',  component: AuditoriaHistorialComponent },
    { path : 'kpis',  component: KpiComponent },
    { path : 'configuracion-sistema',  component: ConfiguracionSistemaComponent },
    { path : 'menu-dnd', component: MenuDNDComponent },
    { path : 'contrasena', component: ContrasenaComponent },
    { path : 'permisos-por-entidad', component: PermisosPorEntidadComponent },
    { path : 'columnList', component: ColumnListComponent }    

    ,
    {path: 'accionEtapa-list', component: AccionEtapaListComponent },
    {path: 'accionEtapa/:id', component: AccionEtapaDetailComponent }
    
    



    ,
    {path: 'antecedente-list', component: AntecedenteListComponent },
    {path: 'antecedente/:id', component: AntecedenteDetailComponent }
    
    



    ,
    {path: 'appLabel-list', component: AppLabelListComponent },
    {path: 'appLabel/:id', component: AppLabelDetailComponent }
    
    



    ,
    {path: 'archivoAnexo-list', component: ArchivoAnexoListComponent },
    {path: 'archivoAnexo/:id', component: ArchivoAnexoDetailComponent }
    
    



    ,
    {path: 'archivoNotificacion-list', component: ArchivoNotificacionListComponent },
    {path: 'archivoNotificacion/:id', component: ArchivoNotificacionDetailComponent }
    
    



    ,
    {path: 'area-list', component: AreaListComponent },
    {path: 'area/:id', component: AreaDetailComponent }
    
    



    ,
    {path: 'auditoriaSistema-list', component: AuditoriaSistemaListComponent },
    {path: 'auditoriaSistema/:id', component: AuditoriaSistemaDetailComponent }
    
    



    ,
    {path: 'calendario-list', component: CalendarioListComponent },
    {path: 'calendario/:id', component: CalendarioDetailComponent }
    
    



    ,
    {path: 'cargo-list', component: CargoListComponent },
    {path: 'cargo/:id', component: CargoDetailComponent }
    
    



    ,
    {path: 'contrato-list', component: ContratoListComponent },
    {path: 'contrato/:id', component: ContratoDetailComponent }
    
    



    ,
    {path: 'contratoTipo-list', component: ContratoTipoListComponent },
    {path: 'contratoTipo/:id', component: ContratoTipoDetailComponent }
    
    



    ,
    {path: 'empresa-list', component: EmpresaListComponent },
    {path: 'empresa/:id', component: EmpresaDetailComponent }
    
    



    ,
    {path: 'empresaTipo-list', component: EmpresaTipoListComponent },
    {path: 'empresaTipo/:id', component: EmpresaTipoDetailComponent }
    
    



    ,
    {path: 'entidad-list', component: EntidadListComponent },
    {path: 'entidad/:id', component: EntidadDetailComponent }
    
    



    ,
    {path: 'etapa-list', component: EtapaListComponent },
    {path: 'etapa/:id', component: EtapaDetailComponent }
    
    



    ,
    {path: 'feriado-list', component: FeriadoListComponent },
    {path: 'feriado/:id', component: FeriadoDetailComponent }
    
    



    ,
    {path: 'funcionalidad-list', component: FuncionalidadListComponent },
    {path: 'funcionalidad/:id', component: FuncionalidadDetailComponent }
    
    



    ,
    {path: 'manuales-list', component: ManualesListComponent },
    {path: 'manuales/:id', component: ManualesDetailComponent }
    
    



    ,
    {path: 'menu-list', component: MenuListComponent },
    {path: 'menu/:id', component: MenuDetailComponent }
    
    



    ,
    {path: 'notificacion-list', component: NotificacionListComponent },
    {path: 'notificacion/:id', component: NotificacionDetailComponent },
    {path: 'ingreso/notificacion/:id', component: NotificacionDetailComponent }
    
    



    ,
    {path: 'requerimiento-list', component: RequerimientoListComponent },
    {path: 'requerimiento-resumen-list', component: RequerimientoResumenListComponent },
    {path: 'requerimiento-list/mis-pendientes', component: RequerimientoListComponent , data: {viewId: 1}},
    {path: 'requerimiento-list/mis-requerimientos', component: RequerimientoListComponent , data: {viewId: 2}},
    {path: 'requerimiento-list/requerimientos-cerrados', component: RequerimientoListComponent , data: {viewId: 3}},
    



    {path: 'requerimiento/:id', component: RequerimientoDetailComponent }
    ,{path: 'direct/requerimiento/:id', component: RequerimientoDetailComponent }
    ,{path: 'ingreso/requerimiento/:id', component: RequerimientoDetailComponent }
    
    



    ,
    {path: 'requerimientoArchivo-list', component: RequerimientoArchivoListComponent },
    {path: 'requerimientoArchivo/:id', component: RequerimientoArchivoDetailComponent }
    
    



    ,
    {path: 'requerimientoComentario-list', component: RequerimientoComentarioListComponent },
    {path: 'requerimientoComentario/:id', component: RequerimientoComentarioDetailComponent }
    
    



    ,
    {path: 'requerimientoHistorico-list', component: RequerimientoHistoricoListComponent },
    {path: 'requerimientoHistorico/:id', component: RequerimientoHistoricoDetailComponent }
    
    



    ,
    {path: 'requerimientoInstruccion-list', component: RequerimientoInstruccionListComponent },
    {path: 'requerimientoInstruccion/:id', component: RequerimientoInstruccionDetailComponent }
    
    



    ,
    {path: 'requerimientoPrefijo-list', component: RequerimientoPrefijoListComponent },
    {path: 'requerimientoPrefijo/:id', component: RequerimientoPrefijoDetailComponent }
    
    



    ,
    {path: 'requerimientoTipo-list', component: RequerimientoTipoListComponent },
    {path: 'requerimientoTipo/:id', component: RequerimientoTipoDetailComponent }
    
    



    ,
    {path: 'role-list', component: RoleListComponent },
    {path: 'role/:id', component: RoleDetailComponent }
    
    



    ,
    {path: 'rolePermisoEntidad-list', component: RolePermisoEntidadListComponent },
    {path: 'rolePermisoEntidad/:id', component: RolePermisoEntidadDetailComponent }
    
    



    ,
    {path: 'secuencialNumeroRequerimiento-list', component: SecuencialNumeroRequerimientoListComponent },
    {path: 'secuencialNumeroRequerimiento/:id', component: SecuencialNumeroRequerimientoDetailComponent }
    
    



    ,
    {path: 'sesionColumnList-list', component: SesionColumnListListComponent },
    {path: 'sesionColumnList/:id', component: SesionColumnListDetailComponent }
    
    



    ,
    {path: 'sesionJwt-list', component: SesionJwtListComponent },
    {path: 'sesionJwt/:id', component: SesionJwtDetailComponent }
    
    



    ,
    {path: 'usuario-list', component: UsuarioListComponent },
    {path: 'usuario/:id', component: UsuarioDetailComponent },
    {path: 'usuario/miperfil/:id', component: UsuarioDetailComponent }
    
    



    ,
    {path: 'video-list', component: VideoListComponent },
    {path: 'video/:id', component: VideoDetailComponent }
    
    



];

export const routing: ModuleWithProviders<Route> = RouterModule.forRoot(routes);
