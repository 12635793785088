//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { SesionColumnList } from './sesionColumnList';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class SesionColumnListService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a SesionColumnList by id.
     */
    getSesionColumnList(id : any) {
        return this.http.get<SesionColumnList>('/api/sesionColumnLists/' + id).toPromise();
    }

    /**
     * Update the passed sesionColumnList.
     */
    update(sesionColumnList : SesionColumnList) {
        let body = JSON.stringify(sesionColumnList);
        return this.http.post<SesionColumnList>('/api/sesionColumnLists/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of SesionColumnList using the passed
     * sesionColumnList as an example for the search by example facility.
     */
     getPage(sesionColumnList : SesionColumnList, event : LazyLoadEvent, columns, filterRangeNumber?) {
        let req = new PageRequestByExample(sesionColumnList, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        return this.http.post('/api/sesionColumnLists/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by SesionColumnListListComponent.
      */
    getCSV(sesionColumnList:SesionColumnList, filterRangeNumber?) {
      let body = JSON.stringify(sesionColumnList);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      return this.http.post('/api/sesionColumnLists/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by SesionColumnListCompleteComponent.
     */
    complete(sesionColumnList : SesionColumnList) {
        if(sesionColumnList == null){
          sesionColumnList = new SesionColumnList();
        }
        let body = JSON.stringify(sesionColumnList);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<SesionColumnList[]>('/api/sesionColumnLists/complete', formData).toPromise();
    }

    /**
     * Delete an SesionColumnList by id.
     */
    delete(id : any) {
        return this.http.delete('/api/sesionColumnLists/' + id).toPromise();
    }
    /*
    * Get Menu with ColumnList where id usuario is null
    */
    getMenuWithSesionColumnList(){
      return this.http.get('/api/session-column-list/all/menuWithSesionColumns').toPromise();
    }

    saveSesionColumnList(sesionColumnList:SesionColumnList[]){
      let body = JSON.stringify(sesionColumnList);
      return this.http.post('/api/session-column-list/save/sesionColumnsList', body, this.options).toPromise();
    }
}
