//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-line.component.ts.e.vm
//
import {Component, Input} from '@angular/core';
import {Config} from './config';

@Component({
	template: `
{{ config?.titulo }} 
{{ config?.nombreEmpresa }} 
{{ config?.nombreSistema }} 
{{ config?.frase }} 
{{ config?.cssColor1 }} 
{{ config?.cssColor2 }} 
{{ config?.cssColor3 }} 
{{ config?.cssColor4 }} 
{{ config?.cssColor5 }} 
{{ config?.direccion }} 
{{ config?.telefono }} 
{{ config?.footer }} 
{{ config?.urlFacebook }} 
{{ config?.urlInstagram }} 
{{ config?.urlTwitter }} 
{{ config?.appKey }} 
{{ config?.rutaBase }} 
{{ config?.usuarioCorreo }} 
{{ config?.pwdCorreo }} 
{{ config?.servidorCorreo }} 
{{ config?.dominio }} 
{{ config?.versionSistema }} 
	`,
	selector: 'config-line',
})
export class ConfigLineComponent {
    @Input() config : Config;
}



