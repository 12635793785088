//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-line.component.ts.e.vm
//
import {Component, Input} from '@angular/core';
import {Menu} from './menu';

@Component({
	template: `
{{ menu?.nombre }} 
{{ menu?.icono }} 
{{ menu?.ruta }} 
{{ menu?.comando }} 
{{ menu?.tltVariable }} 
{{ menu?.linkExterno }} 
{{ menu?.destinoLinkExterno }} 
	`,
	selector: 'menu-line',
})
export class MenuLineComponent {
    @Input() menu : Menu;
}



