//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";
import {Usuario} from '../usuario/usuario';

export class SesionJwt {

    menuRuta = 'sesionJwt-list';
    // Raw attributes
     id : number;
     inicioSesion : Date;
     tiempoExpiracion : number;
     fechaExpiracion : Date;
     activo : boolean;
     direccionIp : string;
     token : string;
    // x-to-one
    usuario : Usuario;

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            if (json.inicioSesion != null) {
                this.inicioSesion = new Date(json.inicioSesion);
            }
            this.tiempoExpiracion = json.tiempoExpiracion;
            if (json.fechaExpiracion != null) {
                this.fechaExpiracion = new Date(json.fechaExpiracion);
            }
            this.activo = json.activo;
            this.direccionIp = json.direccionIp;
            this.token = json.token;

            if (json.usuario != null) {
                this.usuario = new Usuario(json.usuario);
            }
        }
    }

    // Utils

    static toArray(jsons : any[]) : SesionJwt[] {
        let sesionJwts : SesionJwt[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                sesionJwts.push(new SesionJwt(json));
            }
        }
        return sesionJwts;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(){
        let auth = AppInjector.get(AuthService)
        let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        let code = auth.globalIdioma.code;
        let concatString = '';
        for (let col of allColumnsString) {
            if(col.codeLanguage == code || col.codeLanguage == 'all'){
                concatString += this[col.field]+' ';
            }
        }
        if(concatString){
            return concatString.replace(/null/g,'').trim();
        }
        return '';
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
