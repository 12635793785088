//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-list.component.ts.e.vm
//
import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges, OnInit } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { LazyLoadEvent, ConfirmationService } from 'primeng/api';
import { PageResponse } from '../../support/paging';
import { MsgService } from '../../service/message.service';
import { GlobalAuditService } from '../../service/globalAudit.service';
import { Config } from './config';
import { ConfigDetailComponent } from './config-detail.component';
import { ConfigService } from './config.service';
import { AuthService } from '../../service/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { RolePermisoEntidad } from '../rolePermisoEntidad/rolePermisoEntidad';
import { PdfViewerModule } from 'ng2-pdf-viewer';
@Component({
	templateUrl: 'config-list.component.html',
	selector: 'config-list'
})
export class ConfigListComponent implements OnInit{
    @Input() header = "tltConfig";
    @Input() padre = ""; //nombre del padre que se pasa por parametro para el header de la tabla y poder aplicar el translate

    // When 'sub' is true, it means this list is used as a one-to-many list.
    // It belongs to a parent entity, as a result the addNew operation
    // must prefill the parent entity. The prefill is not done here, instead we
    // emit an event.
    // When 'sub' is false, we display basic search criterias
    @Input() sub : boolean;
    @Output() onAddNewClicked = new EventEmitter();
    @Output() onRowSelectSub = new EventEmitter();
    permisos:any = new RolePermisoEntidad();
    keyEntity = 'key_config';
    key_sessionStorage = 'stateSessionConfig-list';
    optionsColumns: any;
    originalColumns:any;
    cols:any [] = [];
    configToDelete : Config;
    configHistory : any;
    showDialog: boolean = false;
    loading: boolean = true;
    activeFilter : boolean = true;
    editColumnConfig:boolean = false;
    // basic search criterias (visible if not in 'sub' mode)
    example : Config = new Config();
    rowDataTmpView:any = null;
    urlView:string = null;
    formato:string = null;
    showDialogPreView:boolean = false;
    rangeNumber:any={};
    filterRangeNumber:any={};
    sortOrder:any;
sortField:any;
loadColums = false;
    // list is paginated
    currentPage : PageResponse<Config> = new PageResponse<Config>(0,0,[]);


    constructor(private router : Router,
        private configService : ConfigService,
        private messageService : MsgService,
        private globalAudit : GlobalAuditService,
        private authService : AuthService,
        private translate: TranslateService,
        private confirmationService: ConfirmationService) {
    }
    ngOnInit(){  
    }
    /**
     * When used as a 'sub' component (to display one-to-many list), refreshes the table
     * content when the input changes.
     */
    ngOnChanges(changes: SimpleChanges) {
        //this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
    }
    viewFilter(){
      if(this.activeFilter)
        this.activeFilter=false;
      else
        this.activeFilter=true;
    }
    clean(dt : any){
      //dt.reset();
      this.example = new Config();
      this.filterRangeNumber = {}
      this.rangeNumber = {}
      dt.filters = {};
      sessionStorage.removeItem(this.key_sessionStorage);
      dt.reset();
      this.loadPage({ first: 0, rows: 10, sortField: null, sortOrder: null, filters: null, multiSortMeta: null });
    }
    
    //Actualiza la tabla manteniendo orden, filtro y página
    async reload(){
      let tmp: any = JSON.parse(sessionStorage.getItem(this.key_sessionStorage));
      let sortField:any = tmp?.sortField?tmp.sortField:null;
      let sortOrder:any = tmp?.sortOrder?tmp.sortOrder:null;
      let filters:any = tmp?.filters?tmp.filters:null;      
      await this.ngOnInit();
      this.loadPage({ first: 0, rows: 10, sortField: sortField, sortOrder: sortOrder, filters: filters, multiSortMeta: null });
    }

    /**
     * Invoked when user presses the search button.
     */
    /*search(dt : any) {
      dt.reset();
      this.loadPage({ first: 0, rows: dt.rows, sortField: dt.sortField, sortOrder: dt.sortOrder, filters: null, multiSortMeta: dt.multiSortMeta });
    }*/

    /**
     * Invoked automatically by primeng datatable.
     */
    async loadPage(event : LazyLoadEvent) {
      this.loading = true;
      try {
        //this.currentPage = new PageResponse<Config>(0,0,[]);
        let page:any = await this.configService.getPage(this.example, event, this.cols, this.filterRangeNumber);
        this.currentPage = new PageResponse<Config>(page.totalPages, page.totalElements, Config.toArray(page.content));
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.messageService.customError(this.translate.instant("Config"),this.translate.instant("lblErrorNgOnInit"), error);
      }
    }

    //Registra los atributos al Session Storage para mantener los filtros en la sesión
    setFilterTable(dt){       
      let filters = {
        'example':this.example,
        'filterRangeNumber':this.filterRangeNumber,
        'sortOrder':dt.sortOrder,
        'sortField':dt.sortField
      }
      sessionStorage.setItem(this.key_sessionStorage,JSON.stringify(filters))
      this.loadPage({ first: 0, rows: dt.rows, sortField: dt.sortField, sortOrder: dt.sortOrder, filters: null, multiSortMeta: dt.multiSortMeta });      
    }

    // evita que los auto complete recargen toda la tabla al hacer click en el despliegue
    controlFiltros(attr, table){
      if(attr != null){
        this.setFilterTable(table);
      }
    }

    setFormatNumberRange(inputElement, sort) {
      const value = this.rangeNumber[sort]; 
      let spl = value.split("-");            
      
      if (spl.length < 2) {
        this.rangeNumber[sort] = value + "-";   
        inputElement.srcElement.value = this.rangeNumber[sort];
        inputElement.srcElement.setSelectionRange(this.rangeNumber[sort].length-1, this.rangeNumber[sort].length-1);
      }else if(spl.length >=3){
        this.rangeNumber[sort] = spl[0] +'-'+spl[1];   
        inputElement.srcElement.value = this.rangeNumber[sort];
        inputElement.srcElement.setSelectionRange(this.rangeNumber[sort].length, this.rangeNumber[sort].length);
      }            
    }

    setFilterRangeNumber(sort){
      if(!this.rangeNumber[sort]){
        return;
      }
      let spl = this.rangeNumber[sort].split("-");      
      if(spl.length == 2){
        let desde = spl[0].trim() != '' ? spl[0]:null;
        let hasta = spl[1].trim() != '' ? spl[1]:null;
        if(this.filterRangeNumber[sort] == null){
          this.filterRangeNumber[sort] = [null,null]
        }       
        this.filterRangeNumber[sort][0] = desde;
        this.filterRangeNumber[sort][1] = hasta;        
      }else{        
        this.filterRangeNumber[sort] = null;
      }      
    }
    saveSelectedColumnsList(){ 
    }
    async getCSV(){
      try {
          let csv : any = await this.configService.getCSV(this.example, this.filterRangeNumber);
          var csvData = new Blob(["\ufeff"+csv], {type: 'text/csv;charset=utf-8;'});
          var csvURL = window.URL.createObjectURL(csvData);
          var tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', this.translate.instant("Config")+'.csv');
          tempLink.click();
      } catch (error) {
          this.messageService.customError(this.translate.instant("Config"),this.translate.instant("lblErrorCSV"), error)
      }
    }


    onRowSelect(event : any) {
        if (this.sub){
            this.onRowSelectSub.emit(event.data);
        } else {
            let id =  event.data.id;
            this.router.navigate(['/config', id]);
        }
    }

    addNew() {
        if (this.sub) {
            this.onAddNewClicked.emit("addNew");
        } else {
            this.router.navigate(['/config', 'new']);
        }
    }

    showDeleteDialog(rowData : any, event: any) {
        let configToDelete : Config = <Config> rowData;

        this.confirmationService.confirm({
            target: event.target,
            message: this.translate.instant("lblPreguntaDelete"),
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.delete(configToDelete);
            },
            reject: () => {
                //reject action
            }
        });
    }

    showPreView(data){
      this.rowDataTmpView = data;
      if(data.logoContentType == "application/pdf"){
        this.formato = data.logoContentType;
      }else if(data.logoContentType.includes('image')){
        this.formato = "images";
      }else{
        this.formato = "FNS";//Formato no soportado
      }
      this.urlView = "/api/configs/"+data.id+"/download/logoBinary";
      this.showDialogPreView = true;
    }

    downloadLogoBinary(rowData){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(rowData.logoFileName);
      let http = this.configService.downloadLogoBinary(rowData).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,rowData.logoFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    downloadFormat(file, name){
      var fileURL = window.URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', name);
      tempLink.click();
    }
    async historico(configToHistory : Config){
      let entidad:any = {"entidad":"Config","id":configToHistory.id};
      try{
        this.configHistory = await this.globalAudit.getHistory(entidad);
        this.showDialog = true;
      }catch(error){
        this.messageService.customError("Config:", this.translate.instant("lblProblemaObtenerHistorico"), error);
      }
    }    
    
    private async delete(configToDelete : Config) {
        let id =  configToDelete.id;
        try {
          await this.configService.delete(id);
          this.currentPage.remove(configToDelete);
          this.messageService.info("Config:", this.translate.instant("lblRegistroEliminado"));
        } catch (error) {
          this.messageService.customError("Config:", this.translate.instant("lblNoPudoEliminar"), error);
        }
    }
}
