//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";
import {Requerimiento} from '../requerimiento/requerimiento';
import {RequerimientoTipo} from '../requerimientoTipo/requerimientoTipo';
import {Usuario} from '../usuario/usuario';

export class RequerimientoArchivo {

    menuRuta = 'requerimientoArchivo-list';
    // Raw attributes
     id : number;
     fechaRegistro : Date;
     archivoFileName : string;
     archivoContentType : string;
     archivoSize : number;
     archivoBinary : any;
     archivoRuta : string;
     borrado : boolean;
     firmado : boolean;
     tieneAnexos : boolean;
    // x-to-one
    requerimiento : Requerimiento;
    requerimientoTipo : RequerimientoTipo;
    usuario : Usuario;

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            if (json.fechaRegistro != null) {
                this.fechaRegistro = new Date(json.fechaRegistro);
            }
            this.archivoFileName = json.archivoFileName;
            this.archivoContentType = json.archivoContentType;
            this.archivoSize = json.archivoSize;
            this.archivoRuta = json.archivoRuta;
            this.borrado = json.borrado;
            this.firmado = json.firmado;
            this.tieneAnexos = json.tieneAnexos;

            if (json.requerimiento != null) {
                this.requerimiento = new Requerimiento(json.requerimiento);
            }

            if (json.requerimientoTipo != null) {
                this.requerimientoTipo = new RequerimientoTipo(json.requerimientoTipo);
            }

            if (json.usuario != null) {
                this.usuario = new Usuario(json.usuario);
            }
        }
    }

    // Utils

    static toArray(jsons : any[]) : RequerimientoArchivo[] {
        let requerimientoArchivos : RequerimientoArchivo[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                requerimientoArchivos.push(new RequerimientoArchivo(json));
            }
        }
        return requerimientoArchivos;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(detalleReq?){
        // let auth = AppInjector.get(AuthService)
        // let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        // let code = auth.globalIdioma.code;
        // let concatString = '';
        // for (let col of allColumnsString) {
        //     if(col.codeLanguage == code || col.codeLanguage == 'all'){
        //         concatString += this[col.field]+' ';
        //     }
        // }
        // if(concatString){
        //     return concatString.replace(/null/g,'').trim();
        // }        
        let str = this.archivoFileName;
        if(detalleReq){
            str = this.formatStringRequerimiento(this.requerimiento?.contrato.nombre) 
            + "N° Req. " + this.formatStringRequerimiento(this.requerimiento?.numeroRequerimiento) 
            + this.formatStringRequerimiento(this.requerimiento?.asunto) 
            + this.formatStringRequerimiento(this.archivoFileName)
            + this.requerimiento?.fechaCierre.toLocaleDateString();
        }
        return str;
    }

    formatStringRequerimiento(txt){
        if(txt){
            txt += ' / ';
        }else{
            txt='';
        }        
        return txt;
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
