//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { ArchivoNotificacion } from './archivoNotificacion';
import { ArchivoNotificacionService } from './archivoNotificacion.service';
import { TranslateService } from '@ngx-translate/core';
import { Notificacion } from '../notificacion/notificacion';
import { RequerimientoTipo } from '../requerimientoTipo/requerimientoTipo';

@Component({
	templateUrl: 'archivoNotificacion-detail.component.html',
	selector: 'archivoNotificacion-detail',
})
export class ArchivoNotificacionDetailComponent implements OnInit{

    @ViewChild('archivoBinary', {static: false}) archivoBinary:any;
    @ViewChild('archivoNotificacionForm', {static: false}) archivoNotificacionForm:any;
    archivoNotificacion : ArchivoNotificacion;

    private params_subscription: any;
    guardo: boolean = false;
    displayLoading = false;
    emitirSave:boolean = true;
    objectURLArchivoBinary: any = null;
    fileSelectedArchivoBinary: boolean = false;
    isImageArchivoBinary: boolean = false;
    chooseValueArchivoBinary: any = null;
    @Input() editContent : boolean = false;
    @Input() editContentSub : boolean;
    permisos:any;
    keyEntity = 'key_archivoNotificacion';
    @Input() sub : boolean = false;    
    @Input() // used to pass the parent when creating a new ArchivoNotificacion
    set notificacion(notificacion : Notificacion) {
      if(this.archivoNotificacion == undefined){
        this.archivoNotificacion = new ArchivoNotificacion();
      }
      this.archivoNotificacion.notificacion = notificacion;
      //console.log(this.archivoNotificacion.notificacion)
    }

    @Input() // used to pass the parent when creating a new ArchivoNotificacion
    set requerimientoTipo(requerimientoTipo : RequerimientoTipo) {
      if(this.archivoNotificacion == undefined){
        this.archivoNotificacion = new ArchivoNotificacion();
      }
      this.archivoNotificacion.requerimientoTipo = requerimientoTipo;
    }

    @Input() // used to pass the parent when creating a new ArchivoNotificacion
    set selectArchivoNotificacion(archivoNotificacion : ArchivoNotificacion) {
      if(this.sub){
        this.archivoNotificacion = archivoNotificacion;
      }
    }

    @Output() onSaveClicked = new EventEmitter<ArchivoNotificacion>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'fechaRegistro': [
    ],
      'archivoFileName': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'archivoContentType': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'archivoSize': [
    ],
      'archivoBinary': [
    ],
      'archivoRuta': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'borrado': [
    ],
      'notificacion': [
      ],
      'requerimientoTipo': [
        { type: 'required', message: 'lblRequerido' }
      ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private sanitizer: DomSanitizer, private archivoNotificacionService: ArchivoNotificacionService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);    
        if (this.sub) {
          if(this.archivoNotificacion == undefined && this.permisos.crear){
            this.archivoNotificacion = new ArchivoNotificacion();
            this.archivoNotificacion.fechaRegistro = new Date();
          }
          console.log("🚀 ~ ArchivoNotificacionDetailComponent ~ ngOnInit ~ this.editContentSub:", this.editContentSub)
          if(!this.editContentSub){            
            this.permisos.crear = false;
            this.permisos.actualizar = false;        
            this.permisos.eliminar = false;
            this.permisos.descargar = true;
            this.editContent = false;
          }else{
            this.editContent = (this.permisos.crear || this.permisos.actualizar);
          }          
          //this.editContent = (this.permisos.crear || this.permisos.actualizar);
          if(this.archivoNotificacion.archivoFileName){
            this.chooseValueArchivoBinary = this.archivoNotificacion.archivoFileName
          } 
          return;
/*           if(this.authService.globalContrato.finalizado || this.editContent == false){
            this.permisos.crear = false;
            this.permisos.actualizar = false;        
            this.permisos.eliminar = false;
            this.editContent = false;
          }else{
            this.editContent = this.permisos.actualizar;
          }           
          return; */
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.archivoNotificacion = new ArchivoNotificacion();
            this.archivoNotificacion.fechaRegistro = new Date();
            this.editContent = this.permisos.crear;
          } else {
            this.archivoNotificacion = new ArchivoNotificacion(await this.archivoNotificacionService.getArchivoNotificacion(id));
          }
          if(this.archivoNotificacion.archivoFileName != undefined || this.archivoNotificacion.archivoFileName != null){
            this.chooseValueArchivoBinary = this.archivoNotificacion.archivoFileName
          }                   
        }catch(error){
          this.messageService.customError(this.translate.instant("ArchivoNotificacion"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'archivoNotificacionKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.archivoNotificacionForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.archivoNotificacionForm.form.valid){
        this.messageService.clear();
        try{
          this.displayLoading = true;
          this.archivoNotificacion = new ArchivoNotificacion(await this.archivoNotificacionService.update(this.archivoNotificacion, this.fileSelected));
          this.removeFileArchivoBinary();
          this.guardo = true;

          if(!this.sub){
            this.displayLoading = false;
            this.messageService.success(this.translate.instant("ArchivoNotificacion"), this.translate.instant("lblGuardo"));
            return this.archivoNotificacion;
          }else{
            this.onSaveClicked.emit(this.archivoNotificacion);
          }
        }catch(error){
          this.displayLoading = false;
          this.messageService.customError(this.translate.instant("ArchivoNotificacion"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    downloadFormat(file, name){
      var fileURL = window.URL.createObjectURL(file);
      var tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', name);
      tempLink.click();
    }
    downloadArchivoBinary(){
      let descargaIndex = this.messageService.globalDownProgress.nuevaDescarga(this.archivoNotificacion.archivoFileName);
      let http = this.archivoNotificacionService.downloadArchivoBinary(this.archivoNotificacion).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.DownloadProgress:
          this.messageService.globalDownProgress.descargas[descargaIndex].progress = Math.round(100 * event.loaded / event.total)
          this.messageService.globalDownProgress.calculaTotalProgres();
          break;
        case HttpEventType.Response:
          this.downloadFormat(event.body,this.archivoNotificacion.archivoFileName)
        }
      },error=>{this.messageService.customError(this.translate.instant("lblProblemaDescarga"), this.translate.instant("lblIntentarMasTarde"), error)});
      this.messageService.globalDownProgress.descargas[descargaIndex].reqHttp = http;
    }

    fileSelected;
    maxSizeFile = 200000000; //aplica para archivo y anexos (200MB)
    showErrorSize = false;
    selectFileArchivoBinary(fileTMP){
      if(this.archivoBinary.files[0].size > this.maxSizeFile){//excede el tamaño maximo permitido
        this.showErrorSize = true;
        this.removeFileArchivoBinary();
        return;
      }
      if(this.archivoBinary.files.length > 0){
        this.fileSelectedArchivoBinary = true;
        this.showErrorSize = false;
        this.chooseValueArchivoBinary = this.archivoBinary.files[0].name;
        this.fileSelected = this.archivoBinary.files[0];
        if(this.archivoBinary.isImage(this.archivoBinary.files[0])){
          this.isImageArchivoBinary=true;
          this.objectURLArchivoBinary = this.sanitizer.bypassSecurityTrustUrl(fileTMP.currentFiles[0].objectURL.changingThisBreaksApplicationSecurity);
        }
        this.archivoBinary.clear() //el archivo queda almacenado en "fileSelected"
      }else{
        this.removeFileArchivoBinary();
      } 
    }

     removeFileArchivoBinary(){
      this.archivoBinary.clear(); 
      this.fileSelectedArchivoBinary = false; 
      this.isImageArchivoBinary = false;
      this.fileSelected = null;
      if(this.archivoNotificacion.archivoFileName != undefined || this.archivoNotificacion.archivoFileName != null){
        this.chooseValueArchivoBinary = this.archivoNotificacion.archivoFileName;
      }else{
        this.chooseValueArchivoBinary = null;
      }
    }  
      
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
