//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { Provincia } from './provincia';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class ProvinciaService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a Provincia by id.
     */
    getProvincia(id : any) {
        return this.http.get<Provincia>('/api/provincias/' + id).toPromise();
    }

    /**
     * Update the passed provincia.
     */
    update(provincia : Provincia) {
        let body = JSON.stringify(provincia);
        return this.http.post<Provincia>('/api/provincias/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of Provincia using the passed
     * provincia as an example for the search by example facility.
     */
     getPage(provincia : Provincia, event : LazyLoadEvent, columns, filterRangeNumber?) {
        let req = new PageRequestByExample(provincia, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        return this.http.post('/api/provincias/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by ProvinciaListComponent.
      */
    getCSV(provincia:Provincia, filterRangeNumber?) {
      let body = JSON.stringify(provincia);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      return this.http.post('/api/provincias/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by ProvinciaCompleteComponent.
     */
    complete(provincia : Provincia) {
        if(provincia == null){
          provincia = new Provincia();
        }
        let body = JSON.stringify(provincia);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<Provincia[]>('/api/provincias/complete', formData).toPromise();
    }

    /**
     * Delete an Provincia by id.
     */
    delete(id : any) {
        return this.http.delete('/api/provincias/' + id).toPromise();
    }
}
