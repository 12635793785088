//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.ts.e.vm
//
import { AppInjector } from "app/app.module";
import { AuthService } from "app/service/auth.service";

export class ContratoTipo {

    menuRuta = 'contratoTipo-list';
    // Raw attributes
     id : number;
     nombreEs : string;
     nombreEn : string;
     borrado : boolean;

    constructor(json? : any) {
        if (json != null) {
            this.id = json.id;
            this.nombreEs = json.nombreEs;
            this.nombreEn = json.nombreEn;
            this.borrado = json.borrado;
        }
    }

    // Utils

    static toArray(jsons : any[]) : ContratoTipo[] {
        let contratoTipos : ContratoTipo[] = [];
        if (jsons != null) {
            for (let json of jsons) {
                contratoTipos.push(new ContratoTipo(json));
            }
        }
        return contratoTipos;
    }

//se auto llama en las columnas del list (reemplaza al entity-line)
    toString(){
        let auth = AppInjector.get(AuthService)
        let allColumnsString = auth.globalColumns[this.menuRuta].filter(f=> f.dataType == 'string');
        let code = auth.globalIdioma.code;
        let concatString = '';
        for (let col of allColumnsString) {
            if(col.codeLanguage == code || col.codeLanguage == 'all'){
                concatString += this[col.field]+' ';
            }
        }
        if(concatString){
            return concatString.replace(/null/g,'').trim();
        }
        return '';
    }

    /*
    *'Parche' empleado para que el autocomplete funcione cuando se modifica el texto y se pierde el focus, 
    *debe retornar null para que actue el forceSelection
    *(si se utiliza field no se requiere)
    */
    trim(){
        return null;
    }
}
