//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/service/message.service.ts.p.vm
//
import { Injectable } from '@angular/core'
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { DownloadProgressComponent } from '../support/download-progress.component';

@Injectable()
export class MsgService {

    globalDownProgress:DownloadProgressComponent;
    constructor(private messageService:MessageService, private router : Router){}

    success(titulo:any,detalle:any) {
        this.messageService.add({severity:'success', summary: titulo, detail:detalle});
    }

    info(titulo:any,detalle:any) {
        this.messageService.add({severity:'info', summary: titulo, detail:detalle});
    }

    warn(titulo:any,detalle:any, sticky?) {
        this.messageService.add({severity:'warn', summary: titulo, detail:detalle, sticky:sticky});
    }

    customError(titulo:any, detalle:any, error:any) {
      if(error == null){
        this.messageService.add({severity:'error', summary: titulo, detail:detalle});
        return;
      }
      if(error.status == 401 || error.status == 403){                
        localStorage.clear();
        this.router.navigate(['/'])
      }else if(error.status == 409){
        //let aux:[] = error.error.split('is still referenced from table "');
        //let txt:string = String(aux[aux.length-1]).replace('".','').replace("_"," ");
        this.messageService.add({severity:'error', summary: titulo, detail:detalle});
      }else if(error.status == 422){
        this.messageService.add({severity:'error', summary: "Nombre de usuario ya existe", detail:""});
      }else if(error.status == 504){
        localStorage.clear();
        this.router.navigate(['/'])
      }else{
        this.messageService.add({severity:'error', summary: titulo, detail:detalle});
      }
    }

    clear() {
        this.messageService.clear();
    }
}
