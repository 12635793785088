//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { Entidad } from './entidad';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class EntidadService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a Entidad by id.
     */
    getEntidad(id : any) {
        return this.http.get<Entidad>('/api/entidads/' + id).toPromise();
    }

    /**
     * Update the passed entidad.
     */
    update(entidad : Entidad) {
        let body = JSON.stringify(entidad);
        return this.http.post<Entidad>('/api/entidads/save', body, this.options).toPromise();
    }


    /**
     * Load a page (for paginated datatable) of Entidad using the passed
     * entidad as an example for the search by example facility.
     */
     getPage(entidad : Entidad, event : LazyLoadEvent, columns) {
        let req = new PageRequestByExample(entidad, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        return this.http.post('/api/entidads/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by EntidadListComponent.
      */
    getCSV(entidad:Entidad) {
      let body = JSON.stringify(entidad);
      return this.http.post<any>('/api/entidads/csv', body, this.options).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by EntidadCompleteComponent.
     */
    complete(entidad : Entidad) {
        if(entidad == null){
          entidad = new Entidad();
        }
        let body = JSON.stringify(entidad);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<Entidad[]>('/api/entidads/complete', formData).toPromise();
    }

    /**
     * Delete an Entidad by id.
     */
    delete(id : any) {
        return this.http.delete('/api/entidads/' + id).toPromise();
    }
}
