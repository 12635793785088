//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { Feriado } from './feriado';
import { FeriadoService } from './feriado.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	templateUrl: 'feriado-detail.component.html',
	selector: 'feriado-detail',
})
export class FeriadoDetailComponent implements OnInit{

    @ViewChild('feriadoForm', {static: false}) feriadoForm:any;
    feriado : Feriado;

    private params_subscription: any;
    guardo: boolean = false;
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_feriado';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new Feriado
    set selectFeriado(feriado : Feriado) {
      if(this.sub){
        this.feriado = feriado;
      }
    }

    @Output() onSaveClicked = new EventEmitter<Feriado>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'descripcion': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'fechaFeriado': [
        { type: 'required', message: 'lblRequerido' }
    ],
      'activo': [
    ],
      'borrado': [
    ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private feriadoService: FeriadoService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.feriado == undefined && this.permisos.crear){
            this.feriado = new Feriado();
            this.feriado.fechaFeriado = new Date();
            this.feriado.activo = true;
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.feriado = new Feriado();
            this.feriado.fechaFeriado = new Date();
            this.feriado.activo = true;
            this.editContent = this.permisos.crear;
          } else {
            this.feriado = new Feriado(await this.feriadoService.getFeriado(id));
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("Feriado"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'feriadoKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.feriadoForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.feriadoForm.form.valid){
        this.messageService.clear();
        try{
          this.feriado = new Feriado(await this.feriadoService.update(this.feriado));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("Feriado"), this.translate.instant("lblGuardo"));
            return this.feriado;
          }else{
            this.onSaveClicked.emit(this.feriado);
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("Feriado"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
