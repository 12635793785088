//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { Empresa } from './empresa';
import { EmpresaService } from './empresa.service';
import { TranslateService } from '@ngx-translate/core';
import { EmpresaTipo } from '../empresaTipo/empresaTipo';
import { Contrato } from '../contrato/contrato';

@Component({
	templateUrl: 'empresa-detail.component.html',
	selector: 'empresa-detail',
})
export class EmpresaDetailComponent implements OnInit{

    msgRut:string;
    rutValido:boolean;
    @ViewChild('empresaForm', {static: false}) empresaForm:any;
    empresa : Empresa;
    userContratos:Contrato[];

    private params_subscription: any;
    guardo: boolean = false;
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_empresa';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new Empresa
    set tipoEmpresa(tipoEmpresa : EmpresaTipo) {
      if(this.empresa == undefined){
        this.empresa = new Empresa();
      }
      this.empresa.tipoEmpresa = tipoEmpresa;
    }

    @Input() // used to pass the parent when creating a new Empresa
    set contrato(contrato : Contrato) {
      if(this.empresa == undefined){
        this.empresa = new Empresa();
      }
      this.empresa.contrato = contrato;
    }

    @Input() // used to pass the parent when creating a new Empresa
    set selectEmpresa(empresa : Empresa) {
      if(this.sub){
        this.empresa = empresa;
      }
    }

    @Output() onSaveClicked = new EventEmitter<Empresa>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'nombre': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'rut': [
        { type: 'required', message: 'lblRequerido' },        
    ],
      'dv': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'correoElectronico': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'direccion': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'telefono': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'telefonoAnexo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'codigo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
    'giroPrincipal': [
      { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
  ],
      'responsableTecnico': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableTecnicoCorreo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableTecnicoTelefono': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableTecnicoTelefonoAnexo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableAdministrativo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableAdministrativoCorreo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableAdministratioTelefono': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'responsableAdministrativoTelefonoAnexo': [
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'interna': [
        { type: 'required', message: 'lblRequerido' },
    ],
      'borrado': [
    ],
      'tipoEmpresa': [
      ],
      'contrato': [
      ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private empresaService: EmpresaService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
      this.userContratos = this.authService.getIsAdmin() ? null : this.authService.globalUser.contratos;
        if (this.sub) {
          if(this.empresa == undefined && this.permisos.crear){
            this.empresa = new Empresa();
            this.rutValido = false;
            this.empresa.interna = false;
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.empresa = new Empresa();
            this.empresa.contrato = this.authService.globalContrato ? this.authService.globalContrato : null;
            this.rutValido = false;
            this.empresa.interna = false;
            this.editContent = this.permisos.crear;
          } else {
            this.empresa = new Empresa(await this.empresaService.getEmpresa(id));
            this.rutValido = true;
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("Empresa"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'empresaKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.empresaForm.onSubmit(null); //cambia el estado del form submited = true
      this.rutValido = this.validaRut();
      
      //console.log("🚀 ~ file: empresa-detail.component.ts:181 ~ EmpresaDetailComponent ~ onSave ~ this.empresa:", this.empresa)
      if(this.empresaForm.form.valid && this.rutValido){
        this.messageService.clear();
        try{
          this.empresa = new Empresa(await this.empresaService.update(this.empresa));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("Empresa"), this.translate.instant("lblGuardo"));
            this.volver();
            return this.empresa;
          }else{
            this.onSaveClicked.emit(this.empresa);
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("Empresa"),this.translate.instant("lblProblemaGuardar"), error);
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
      }
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
    validaRut() {
      this.msgRut = null;
      if(this.empresa.dv != null){
          this.empresa.dv = this.empresa.dv.toUpperCase();
      }
      let rut = this.empresa.rut+"-"+this.empresa.dv;
      let valor = rut.split('.').join('');
      valor = valor.split('-').join('');
      let cuerpo: String = valor.slice(0,-1);
      let dv = valor.slice(-1).toUpperCase();
      if(cuerpo.length < 5) { this.msgRut=this.translate.instant("lblRutIncompleto"); this.rutValido = false; return false;}
      let suma = 0;
      let multiplo = 2;
      let index: any = null;
      for(let i=1;i<=cuerpo.length;i++) {
          index = multiplo * Number.parseInt(valor.charAt(cuerpo.length - i));
          suma = suma + index;
          if(multiplo < 7) { multiplo = multiplo + 1; } else { multiplo = 2; }
      }
      let dvEsperado = 11 - (suma % 11);
      dv = (dv == 'K')?'10':dv;
      dv = (dv == '0')?'11':dv;
      if(dvEsperado.toString() != dv) { this.msgRut=this.translate.instant("lblRutInvalido"); this.rutValido = false; return false; }
      if(dv == '10'){
        dv = "K";
      }else if(dv == '11'){
        dv = '0';
      }
      this.rutValido = true;
      return true;
    }
}
