//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity.service.ts.e.vm
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEventType, HttpEvent } from '@angular/common/http';
import { LazyLoadEvent } from 'primeng/api';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/observable/throw';
import { map } from 'rxjs/operators';
import { PageResponse, PageRequestByExample } from '../../support/paging';
import { RequerimientoArchivo } from './requerimientoArchivo';
import { MsgService} from '../../service/message.service';
import { Router } from '@angular/router';
@Injectable()
export class RequerimientoArchivoService {

    private options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' })};

    constructor(private http: HttpClient, private messageService: MsgService, private router : Router) {}

    /**
     * Get a RequerimientoArchivo by id.
     */
    getRequerimientoArchivo(id : any) {
        return this.http.get<RequerimientoArchivo>('/api/requerimientoArchivos/' + id).toPromise();
    }

    /**
     * Update the passed requerimientoArchivo.
     */
    update(requerimientoArchivo : RequerimientoArchivo, archivoBinary : File) {
      let formData = new FormData();
      formData.append('body',new Blob([JSON.stringify(requerimientoArchivo)],{type: "application/json"}));
      formData.append('archivoBinary', archivoBinary ? archivoBinary : new Blob());
      return this.http.post<RequerimientoArchivo>('/api/requerimientoArchivos/save', formData).toPromise();
    }

    /**
     * Download File archivoBinary.
     */
     downloadArchivoBinary(requerimientoArchivo : RequerimientoArchivo) {
      const req = new HttpRequest('GET', '/api/requerimientoArchivos/'+requerimientoArchivo.id+'/download/archivoBinary', {
          reportProgress: true,
          'responseType': 'blob' as 'json'
        });
      return this.http.request(req);
     }

    /**
     * Load a page (for paginated datatable) of RequerimientoArchivo using the passed
     * requerimientoArchivo as an example for the search by example facility.
     */
     getPage(requerimientoArchivo : RequerimientoArchivo, event : LazyLoadEvent, columns, filterRangeDate?, filterRangeNumber?) {
        let req = new PageRequestByExample(requerimientoArchivo, event);
        let body = JSON.stringify(req);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('columns',new Blob([JSON.stringify(columns)],{type: "application/json"}));
        if(filterRangeNumber != undefined){
          formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
        }
        if(filterRangeDate != undefined){
          formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
        }
        return this.http.post('/api/requerimientoArchivos/page', formData).toPromise();        
     }

     /**
      * get CSV fom database
      * Used by RequerimientoArchivoListComponent.
      */
    getCSV(requerimientoArchivo:RequerimientoArchivo, filterRangeDate?, filterRangeNumber?) {
      let body = JSON.stringify(requerimientoArchivo);
      let formData = new FormData();
      formData.append('body',new Blob([body],{type: "application/json"}));
      if(filterRangeNumber != undefined){
        formData.append('filterRangeNumber', new Blob([JSON.stringify(filterRangeNumber)],{type: "application/json"}));
      }
      if(filterRangeDate != undefined){
        formData.append('filterRangeDate', new Blob([JSON.stringify(filterRangeDate)],{type: "application/json"}));
      }
      return this.http.post('/api/requerimientoArchivos/csv', formData).toPromise();
    }


    /**
     * Performs a search by example on 1 attribute (defined on server side) and returns at most 10 results.
     * Used by RequerimientoArchivoCompleteComponent.
     */
    complete(requerimientoArchivo : RequerimientoArchivo) {
        if(requerimientoArchivo == null){
          requerimientoArchivo = new RequerimientoArchivo();
        }
        let body = JSON.stringify(requerimientoArchivo);
        let formData = new FormData();
        formData.append('body',new Blob([body],{type: "application/json"}));
        formData.append('maxResults',new Blob([JSON.stringify(-1)],{type: "application/json"}));                
        return this.http.post<RequerimientoArchivo[]>('/api/requerimientoArchivos/complete', formData).toPromise();
    }

    /**
     * Delete an RequerimientoArchivo by id.
     */
    delete(id : any) {
        return this.http.delete('/api/requerimientoArchivos/' + id).toPromise();
    }
}
