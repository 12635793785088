//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-detail.component.ts.e.vm
//
import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MsgService } from '../../service/message.service';
import { FormatNumber } from '../../service/formatNumber.service';
import { Antecedente } from './antecedente';
import { AntecedenteService } from './antecedente.service';
import { TranslateService } from '@ngx-translate/core';
import { RequerimientoArchivo } from '../requerimientoArchivo/requerimientoArchivo';
import { Requerimiento } from '../requerimiento/requerimiento';

@Component({
	templateUrl: 'antecedente-detail.component.html',
	selector: 'antecedente-detail',
})
export class AntecedenteDetailComponent implements OnInit{

    @ViewChild('antecedenteForm', {static: false}) antecedenteForm:any;
    antecedente : Antecedente;

    private params_subscription: any;
    guardo: boolean = false;
    editContent : boolean = false;
    permisos:any;
    keyEntity = 'key_antecedente';
    @Input() sub : boolean = false;
    @Input() // used to pass the parent when creating a new Antecedente
    set archivo(archivo : RequerimientoArchivo) {
      if(this.antecedente == undefined){
        this.antecedente = new Antecedente();
      }
      this.antecedente.archivo = archivo;
    }

    @Input() // used to pass the parent when creating a new Antecedente
    set requerimiento(requerimiento : Requerimiento) {
      if(this.antecedente == undefined){
        this.antecedente = new Antecedente();
      }
      this.antecedente.requerimiento = requerimiento;
    }

    @Input() // used to pass the parent when creating a new Antecedente
    set selectAntecedente(antecedente : Antecedente) {
      if(this.sub){
        this.antecedente = antecedente;
      }
    }

    @Output() onSaveClicked = new EventEmitter<Antecedente>();
    @Output() onCancelClicked = new EventEmitter();

    //validaciones del formulario
    validations = {
      'asunto': [
        { type: 'required', message: 'lblRequerido' },
        { type: 'minlength', message: 'lblMinimoCaracteres' , cantidad: 2 },
    ],
      'fechaRegistro': [
    ],
      'borrado': [
    ],
      'archivo': [
        { type: 'required', message: 'lblRequerido' },
      ],
      'requerimiento': [
        { type: 'required', message: 'lblRequerido' },
      ],
    }


    constructor(private translate: TranslateService, private confirmationService: ConfirmationService, private route: ActivatedRoute, private formatNumber: FormatNumber, private router: Router, private messageService: MsgService, private authService: AuthService, private antecedenteService: AntecedenteService) {
    }

    async ngOnInit() {
      this.permisos = await this.authService.getPermisosPorEntidad(this.keyEntity);
        if (this.sub) {
          if(this.antecedente == undefined && this.permisos.crear){
            this.antecedente = new Antecedente();
            this.antecedente.fechaRegistro = new Date();
          }
          this.editContent = (this.permisos.crear || this.permisos.actualizar);
          return;
        }
        try{
          let params = (await this.route.paramMap.pipe(first()).toPromise());
          let id = params.get("id");

          if (id === 'new') {
            this.antecedente = new Antecedente();
            this.antecedente.fechaRegistro = new Date();
            this.editContent = this.permisos.crear;
          } else {
            this.antecedente = new Antecedente(await this.antecedenteService.getAntecedente(id));
          }
          this.editContent = this.permisos.actualizar;    
        }catch(error){
          this.messageService.customError(this.translate.instant("Antecedente"),this.translate.instant("lblErrorNgOnInit"), error);
        }
    }

    confirmacionVolver() {
        this.confirmationService.confirm({
            message: this.translate.instant("lblMensajeConfirmacion"),
            header: this.translate.instant("lblHeaderConfirmacion"),
            icon: 'fa fa-question-circle',
            key: 'antecedenteKey',
            accept: () => {
                this.volver();
            }
        });
    }

    volver() {
      window.history.back();
    }

    async onSave() {
      this.antecedenteForm.onSubmit(null); //cambia el estado del form submited = true
      if(this.antecedenteForm.form.valid){
        this.messageService.clear();
        try{
          this.antecedente = new Antecedente(await this.antecedenteService.update(this.antecedente));
          this.guardo = true;

          if(!this.sub){
            this.messageService.success(this.translate.instant("Antecedente"), this.translate.instant("lblGuardo"));
            return this.antecedente;
          }else{
            this.onSaveClicked.emit(this.antecedente);
            return this.antecedente;
          }
        }catch(error){
          this.messageService.customError(this.translate.instant("Antecedente"),this.translate.instant("lblProblemaGuardar"), error);
          return false;
        }
      }else{
        this.messageService.warn(this.translate.instant("lblAtencionCampos"),this.translate.instant("lblCamposRequeridos"));
        return false;
      }
    }
    onCancel() {
        if (this.sub) {
          this.messageService.clear();
          this.onCancelClicked.emit("cancel");
          this.messageService.info(this.translate.instant("lblCancelado"), '')
        }
    }
}
