//
// Source code generated by Celerio, a Jaxio product.
// Documentation: http://www.jaxio.com/documentation/celerio/
// Follow us on twitter: @jaxiosoft
// Need commercial support ? Contact us: info@jaxio.com
// Template pack-angular:web/src/app/entities/entity-line.component.ts.e.vm
//
import {Component, Input} from '@angular/core';
import {Usuario} from './usuario';

@Component({
	template: `
	<span *ngIf="!showContrato">
	{{ usuario?.nombre }}
	</span>
	<span class="flex" *ngIf="showContrato" tooltipPosition="left" pTooltip="{{ ('ContratosEP' | translate) + ': \n' + usuario?.contratos.join(' \n ') }}">
			{{ usuario?.nombre }}
	</span>
	`,
	selector: 'usuario-line',
})
export class UsuarioLineComponent {
    @Input() usuario : Usuario;
	@Input() showContrato : Boolean = false;
}



